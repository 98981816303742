///*------------------------------------*\
//   #BUNDLE CONTENT
//\*------------------------------------*/
.gray-white {
    background-color: $gray-white;
}
.content {
    background-color: white;
    border-radius: 5px;
    margin: 15px 0 0 0;
    padding: 15px;
}
.content-col {
    // @include span(1 of 2);
	width: calc((1 / 2) * 100%);
    @include media-query(palm) {
        // @include span(full);
        width: calc((1 / 1) * 100%);
    }
}


%frame-element {
  background-color: rgba(255,255,255,.04);
  border: 0 solid transparent;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
  //border: 1px solid $color-frame-border;
}
.content-con{
    width: 33%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }


    background-color: rgba(255,255,255,.04);
    border: 0 solid transparent;
    border-radius: 5px;

}
.medios-pago1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(palm) {
    display: block;
    text-align: center;
    // -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
    }
}


.box1 {
    margin: 20px;
    //display: flex;
    justify-content: space-between; /* Espacio entre columnas */
    width: 75%; /* Ancho completo del contenedor principal */
    margin-bottom: 0px;
    max-width: 65%;
    text-align: center
}
.box2 {
    // display: inline-block;
    // width: 50px;
    // height: 50px;
    // background-color: #3498db;
    // margin: 5px;
    display: flex;
    justify-content: space-between; /* Espacio entre columnas */
    width: 100%; /* Ancho completo del contenedor principal */
    margin-bottom: 0px;
}
.payment_means_container{
    width: 1212px;
    height: 156.11px;
    flex-shrink: 0;
}
.medios-pago {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(palm) {
        display: block;
        text-align: center;
    }
    .medios-texto {
        width: 20%;
        text-align: center;
        @include media-query(palm) {
            width: 100%;
        }
        a {
            text-transform: uppercase;
            color: #2063cc;
            margin-right: 10px;
        }
    }

    .titulo01 {
        font-weight: 400;
        font-size: 16px;
        margin: 0;
        line-height: 16px;
    }

    .titulo02 {
        font-weight: 700;
        font-size: 19px;
        margin: 0;
        line-height: 18px;
    }

    .medios-imagenes {
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0;
        @include media-query(palm) {
            flex-wrap: wrap;
        }
    }

    a.medios-img {
        text-decoration: none;
        text-align: center;
        margin: 0;
        padding: 5px 3px;
        border-radius: 4px;
        @include media-query(lap-and-up){
            display: flex;
            flex-flow: wrap;
        }
        @include media-query(palm) {
            padding: 5px 8px;
            margin-bottom: 15px;
        } 

        &:hover {
            background: #f5f5f5;
        }

        img {
            height: 30px;
            max-height: 30px;
            margin: 0 auto;
            margin-bottom: 10px;
        }

        span {
            font-size: 11px;
            line-height: 12px;
            color: #666;
            margin-left: auto;
            margin-right: auto;
            max-width: 60px;
            @include media-query(palm) {
                display: block;       
            }
        }
    }
}
.medios-pago-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #ccc;
    border-radius: 4px;
    @include media-query(palm) {
        display: block;
        text-align: center;
        padding: 10px;
    }
}
.medios-pago-cont .medios-texto{
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 18px;
    @include media-query(lap-and-up){
        text-align: right;
    }

}
.medios-pago-cont .medios-imagenes {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    padding: 10px 0;
}
.medios-pago-cont .medios-img {
    text-align: center;
    margin: 0 5px;
    padding: 5px;
    border-radius: 4px;
}
// .img_medios_pago{
//     object-fit: cover;
//     min-width: 163px;
//     max-width: 163px;
//     min-height: 92px;
//     max-height: 92px;
// }
.tamaño-especifico {
 display: block;
  width: 184px !important;
  height: 86px !important;
}
@media only screen and (max-width: 540px) {
    .tamaño-especifico {
        display: block;
         width: 138px !important;
         height: 61px !important;
       }
}

.nombres_medios_pago {
    color: var(--Black-text, #2F2F2F);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 540px) {
    /* Aquí no es necesario repetir todas las propiedades,
       solo las que deseas cambiar en dispositivos móviles */
    .nombres_medios_pago {
        color: var(--Black-text, #2F2F2F);
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        /* Puedes ajustar otras propiedades según sea necesario */
    }
}




////columnas
.container {
    display: flex;
    flex-direction: column; /* Alineación vertical */
    align-items: center; /* Centrar horizontalmente */
    margin-top: 40px;
}

/* Estilos para las filas */
.row {
    display: flex;
    justify-content: space-between; /* Espacio entre columnas */
    width: 100%; /* Ancho completo del contenedor principal */
    margin-bottom: 20px;
}

.row-insurance {
    @include media-query(palm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
    }
    @include media-query(lap) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
    }       
}

///////////

.medios-pago-cont .medios-img img{
    height: 40px;
    max-height: 40px;
    margin: 0 auto;
}
.medios-pago-cont .medios-img span {
    font-size: 12px;
    color: #666;
    display: block;
    margin: 10px auto 0;
}
 p.parrafos_content-promo_index_box{
    color: var(--Black-text, #2F2F2F);
text-align: center;
font-family: Ubuntu;
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: 23px; /* 153.333% */
}
.content-promo_index_box {
    width: 250px!important;
    height: 260px;
    float: left;
    text-align: center;
    line-height: 1.25;
    flex-shrink: 0;
    font-size: 18px;
    padding: 20px;
    color: black;
    margin: 10px 0px;
    border: 0 solid transparent;
    border-radius: 10px;
    background-color: $color-highlight;
    box-shadow: 0px 4px 10.5px 0px #00000026;
    
    @include media-query(lap-and-up){
        margin: 30px;
    }
    h4{
        color:#2F2F2F;
    }
}
.tamaño-letra{
    color: var(--Black-text, #2F2F2F);
    text-align: center;
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}
@media only screen and (max-width: 1015px){
    .tamaño-letra {
        color: var(--Black-text, #2F2F2F);
        text-align: center;
        font-family: Ubuntu;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 25px; /* 147.059% */
        }
}


.content-promo_index{
    width: 31%;
    float: left;
    font-size: 18px;
    width: 28%;
    float: left;
    font-size: 18px;
    padding: 20px;
    color: black;
    margin: 5px;
    //border: 1px solid #d0d0d0;
    //border-radius: 5px;
    background-color: rgba(255,255,255,.04);
    border: 0 solid transparent;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    @include media-query(palm) {
        width: 95%;
        margin-left: 9px;
        margin-right: 9px;
    }
}
.content-promo_index h4{
    text-align: center;
    line-height: 1.25;
}

.content-infocont{
    width: 100%;
    padding: 20px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
}

.content-footer {
    margin: 0;
}

.conteng-img {
    float: left;
    background-color: white;
    margin: 0 5px 5px 5px ;
}
.content-menu-li-a {
    display: inline-block !important;
}
.content-nav {
    background-color: $color-main;
    position: relative;
}

.content-navbar:hover {
    background-color: $gray-accent-light;
}

.content-navbar,
.content-pagination > li > a,
.content-pagination > li > span {
    background-color: $gray-accent-light;
}

.pagination > li > a:focus,
.content-pagination > li > a:hover,
.content-pagination > li > span:hover,
.content-pagination > li > span:focus {
    color: $gray-accent-light;
    background-color: $gray-accent-light;
    border-color: $gray-accent-light;
}

.revista-horizontes{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    float: left;
    text-align: center;
    margin-top: 20px;
}
.revista-horizontes select{
    width: 26%;
    float: left;
    padding-right: 5%;
    padding-left: 1%;
    margin: 0 1%;
    font-size: .99rem;
    border: 0;
    box-shadow: 0 0 2px rgba(0,0,0,.08), 0 2px 4px rgba(0,0,0,.16);
    @include media-query(palm) {
        width: 100%;
    }
}

.horizontes-img {
    margin: 2%;
    float: left;
    width: 21%;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #fff;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .54);
}
.horizontes-img .mascara,.horizontes-img .contenido {
    position: absolute;
    margin: 0 auto;
    width:100%;
    top: 0;
    left: 0;
}
.horizontes-img img {
    width: 100%;
}
.horizontes-img h2 {
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, .9);
    margin: 20px 0 0 0;
}
.horizontes-img p {
    font-size: 12px;
    color: #fff;
    padding: 10px 20px 10px;
    text-align: center;
    margin: 0;

}
.horizontes-img a.link {
    text-decoration: none;
    padding: 7px 14px;
    background: $color-btn-submit;
    border-radius: 5px;
    color: #fff;
    box-shadow: 0 0 1px #000;
}
.horizontes-img a.link:hover {
    box-shadow: 0 0 5px #000;
    background: #990d0d;
}


.horizontes-2 img {
    transition: all .2s ease-in;
}
.horizontes-2 .mascara {
    background-color: rgba(0, 0, 0, .60);
    width: 500px;
    height: 500px;
    padding: 20px;
    opacity: 1;
    transform: translate(300px, 200px) rotate(45deg);
    transition: all .5s ease-in-out;
}
.horizontes-2 h1 {
    font-size: 12px;
    color: $color-btn-submit;
}
.horizontes-2 h2 {
    border-bottom: 1px solid #fff;
    background: transparent;
    margin:40px;
    transform: translate(200px, -200px);
    transition: all .3s ease-in-out;
}
.horizontes-2 p {
    transform: translate(-200px, 200px);
    transition: all .4s ease-in-out;
}
.horizontes-2:hover .mascara {
    opacity:1;
    transform: translate(-96px, -120px) rotate(45deg);
}
.horizontes-2:hover h2 {
    transform: translate(0px,0px);
    transition-delay: .3s;
}
.horizontes-2:hover p {
    transform: translate(0px,0px);
    transition-delay: .4s;
}
@media screen and (min-width: 701px) and (max-width: 900px) {
    .horizontes-img{
        width:29%;
    }}
@media screen and (min-width: 300px) and (max-width: 700px) {
    .horizontes-img{
        width:45%;
    }
    .horizontes-img .contenido {
        margin: 0 auto;
        width: 100%;
    }
    .horizontes-2 h2{
        margin: 15px;
    }
}
@media screen and (min-width: 901px) and (max-width: 1200px) {
    .horizontes-img{
        width:20%;
    }}
@media screen and (max-width: 600px){
    .contenidos {
        width: 100%;
        display: inline-block;
        text-align:justify;
        padding:10px;
    }

    .contenidos img{
        width: 100%!important;
        margin:0!important;
    }
}
.info-aviatur {
    border-top: solid 1px #ccc;
    padding: 20px 24px 0px;
}
.aerolineas {
    width: 48%;
    margin: 1%;
    float: left;
    border-style: double;
    border-width: 4px;
    border-color: #ccc;
    box-shadow: 4px 4px 8px #ccc;
    border-radius: 30px 0 20px 0;
}
.aero-info {
    background: #f3f3f3;
    width: 70%;
    float: left;
    padding: 0 0 11px 0;
    border-radius: 0 0 20px 0;
}

.aero-info ul {
    padding: 0 4%;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
}
.aero-info li {
    list-style: none;
}

.aero-info h3 {
    background: #dcdcdc;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    padding: 10px;
}
.aerolineas img {
    width: 100%;
    max-width: 200px;
}
.logo-aero {
    width: 30%;
    float: left;
    margin: 12% 0;
}
.aero-web {
    background: url(/source/aerolineas/web.svg);
}
.aero-checkin{
    background: url(/source/aerolineas/check-in.svg);
}
.aero-contrato{
    background: url(/source/aerolineas/contrato.svg);
}
.aero-web, .aero-checkin, .aero-contrato {
    background-size: 16px;
    background-repeat: no-repeat;
    padding: 0 0 0  28px;
    background-position: 0 2px;
}
.aero-web a:hover, .aero-checkin a:hover, .aero-contrato a:hover {
    color: #8c8c8c;
}
.link-aero {
    text-decoration: none;
    padding: 4px 10px;
    background: $color-btn-submit;
    border-radius: 10px 0 10px 0;
    color: #fff!important;
    box-shadow: 0 0 1px #000;
    float: right;
    margin-top: 5%;
    font-weight: 600!important;
}

@media (min-width: 200px) and (max-width: 600px){
    .aerolineas {
        width: 100%;
        margin: 1% 0;
    }
    .aerolineas img {
        width: 100%;
        max-width: 200px;
    }
    .aero-info {
        width: 100%;
        padding: 11px 0;
    }
    .aero-info h3{
        display: none;
    }
    .logo-aero {
        width: 100%;
        text-align: center;
        margin: 5% 0;
    }
}
@media (min-width: 601px) and (max-width: 1000px){
    .aero-info {
        width: 100%;
        padding: 11px 0;
    }
    .logo-aero {
        width: 100%;
        text-align: center;
        margin: 6% 0;
    }
    .aero-info h3{
        display: none;
    }
}

/********css casa cholon*****/

.contenedor-cn {width: 100%;padding: 2% 4%;margin: 0 auto;max-width: 1100px;overflow:auto;background: url(/source/casa-cholon/skater.jpg);}
.contenido-cn {width:100%;background: rgba(255, 255, 255, .9);padding:20px;position:relative;}
.contenido-cn h2 {
    font-family: 'Dosis', Helvetica, Arial, sans-serif;
    color: #009BF8;
    text-align: center;
    border-bottom: double 2px #009BF8;
    font-size: 20px;
}
.contenido-cn img {max-width: 100%;display: block;margin: 0 auto;padding: 20px 0;}
.notas {font-size:10px; color:#333;}


.box{
    position: relative;
    width: 50%;		/* desired width */
    -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%;
    border:#FFF 6px double;
    float:left;
}
.box:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
}
.content-cn{
    color: #FFF;
    font-size: 12px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 85%;
}
.francois {font-family: 'Dosis', Helvetica, Arial, sans-serif; font-size:18px; text-align:center;}
.descript { width:48%; float:right; color:#fff; font-size:13px; text-align:right;}
.descript h3 {font-size:21px;color:#fff;}
.descript h4 {font-size:13px;color:#fff;border: dotted 2px #fff;width:100%;font-size:16px;padding:5px;text-align:center;}
.descript a {color:#0CF; font-weight:bold;}
.precio {background-color: #CC3300;color: #FFF;font-size: 16px;line-height: 17px;padding: 9px;position: absolute;right: 0;text-align: center;top: -52px;width: 165px;}
.precio p {margin:0;}
.precio span {font-size: 17px; font-weight: 600;}
.destacado_cir {width:100%;padding:15px 10px;background-color:rgba(0,0,0,.8);overflow:auto;}
.cita_1 {font-size:26px; font-weight:bold;}
.footer-cn { width:100%; overflow:auto; background-color: rgba(0,0,0,.5); color:#fff;}
.footer-cn .moduletable {
    margin: auto;
    max-width: 1200px;
}
.footer-cn a {color:#fff; text-decoration:none;}
.footer-cn ul li {
    height: auto;
}
.footer-cn .parent {
    float: left;
    font-size: 12px;
    list-style: none outside none;
    margin: 20px 0;
    width: 33%;
}
.footer-cn p {font-size:12px; }
.separator {
    color: #666666;
    font-size: 13px;
    font-weight: bold;
    list-style: none outside none;
    text-transform: uppercase;
}
@media screen and (max-width: 768px) {
    .box, .descript{
        width: 50%;
    }
}
@media screen and (max-width: 700px) {
    .precio {
        position: inherit;
        width: 100%;
        padding: 2px;
    }
}
@media screen and (max-width: 450px) {
    .box, .descript{
        width: 100%;
    }
}
/********fin css casa cholon*****/


.cont-whatsapp {
    background: url(/assets/aviatur_assets/img/icon/whatsapp-aviatur.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0;
    height: 86px;
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 1090px;
    width: 100%;
}
.tel-whatsapp{
    display:none;
}
.icon-whatsapp {
    background: url(/assets/aviatur_assets/img/custom/icon-whatsapp.png)no-repeat;
    background-size: contain;
    padding-left: 27px;
    background-position: 0;
}
@media screen and (max-width: 700px) {
    .cont-whatsapp {
        display:none;
    }
    .tel-whatsapp{
        display: block;
        text-align: center;
        font-size: 34px;
    }
    .tel-whatsapp a{
        font-weight: 400!important;
        color: #000!important;
    }
}
/***Medios de Pago*****/

.mdp{
    width: 100%;
    float: left;
    text-align: center;
    margin: 10px 0;
}
.mdp-1{
    float: left;
    padding: 10px;
    width: 33%;
}
.mdp-2{
    float: left;
    padding: 10px;
    width: 60%;
}
.mdp-3{
    float: left;
    padding: 10px;
    width: 40%;
}
.mdp-4{
    float: left;
    padding: 10px;
    width: 49%;
}
.mdp-5{
    float: left;
    padding: 10px;
    // @include span(1 of 2);
	width: calc((1 / 2) * 100%);
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.mdp-2 img, .mdp-3 img, .mdp-4 img, .mdp-5 img {
    border:1px solid #009bf8;
    padding: 0 5px;
    width: 100%;
}
.horas{
    background: url(/source/medios-de-pago/24h.png);
    background-repeat: no-repeat;
}
.reloj{
    padding: 16px 76px;
    background-position: -50px -1px;
}
.tarjeta{
    padding: 0 25px;
}
@media screen and (max-width: 700px) {
    .reloj{
        padding: 16px 76px;
        background-position: -57px -1px;
        float: right;
        width: 100%;
        max-width: 67%;
    }
}
@media screen and (max-width: 930px) {
    .mdp p {
        line-height: 30px;
    }
}

// .pokerutas {
//     background: url(/source/vuelos-aviatur/pokemon-mundo.jpg) no-repeat;
//     background-size: contain;
// }
// @media screen and (max-width: 550px) {
//     .pokerutas {
//         background: url(/source/vuelos-aviatur/pokemon-mundo-2.jpg) no-repeat;
//     }
// }

/**promociones Aviatur**/
.offer__content {
    width: 380px;
    padding: 0;
    display: inline-block;
    cursor: pointer;
}
.content-promo {
    height: 134px;
    background: #fff;
    border: 1px solid $color-main;
    border-radius: 0 0 $base-round $base-round;
}
.content-promo::before {
    border-right: 130px solid transparent;
    border-left: 130px solid transparent;
    border-top: 20px solid $color-main;
    content: '';
    margin-bottom: 3px;
    display: inline-block;
}
.content-promo p{
    margin:0;
    font-size: 12px;
}
.content-promo em{
    font-size: 33px;
    color: $color-main;
    font-style: normal;
}
.content-promo-img {
    height: 130px;
    border-radius: $base-round $base-round 0 0;
}
.content-promo-img em{
    float: right;
    margin: 10px 16px 0 0;
    text-shadow: -2px 2px 2px #000;
    color: #fff;
    font-size: 30px;
    font-style: normal;
}
.content-promo__price {
    width: 100%;
    float:left;
    font-size: 13px;
    margin-top: 7px;
    padding: 11px 0;
    transition: all 100ms linear;
}
.content-promo__price em {
    font-size: 13px;
    background: $color-main;
    color: #fff;
    border-radius: 100%;
    padding: 1px 6px;
    font-style: normal;
}
.content-promo__descripcion{
    width: 100%;
}

// .grid {
//     position: relative;
//     clear: both;
//     margin: 0 auto;
//     padding: 1em 0 4em;
//     list-style: none;
//     color: black;
//     text-align: center;
// }
.grid figure {
    position: relative;
    overflow: hidden;
    max-height: 360px;
    height: 250px;
    cursor: pointer;
}
.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}
.grid figure figcaption {
    padding: 2em;
    color: #fff;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    height: 100%;
}
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}
.grid figure h3,
.grid figure p {
    margin: 0;
    color:white;
    letter-spacing: 1px;
}
figure.promo-effect {
    background: #474747;
    width: 100%;
    margin-bottom: 0;
    margin: 0;
}
figure.promo-effect img {
    margin: -5px 0 0 -10px;
    max-width: none;
    width: -webkit-calc(100% + 10px);
    width: calc(100% + 10px);
    opacity: 0.7;
    height: 250px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05,1.05,1);
    transform: scale3d(1.05,1.05,1);
}
figure.promo-effect figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
}
figure.promo-effect p {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 2em;
    text-shadow: -2px 2px 2px #000;
    padding-right: 10px;
    max-width: 260px;
    border-right: 3px solid #fff;
    text-align: right;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}
figure.promo-effect h3 {
    text-align: left;
    font-size: 1.875rem;
    text-shadow: -2px 2px 2px #000;
}
figure.promo-effect:hover img {
    opacity: 0.5;
    -webkit-transform: scale3d(1.3,1.3,1.3);
    transform: scale3d(1.3,1.3,1.3);
}
figure.promo-effect:hover figcaption::before {
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
}
figure.promo-effect:hover p {
    opacity: 1;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

/*******Css Destinos******/
.destinos h1,.destinos h2{
    color:#009bf8;
    text-shadow: 1px 1px 3px #fff, 2px 1px 5px #009bf8;
}
.destinos-promo, .destinos-air, .destinos-air_promo, .destinos-promo-cont{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    float: left;
    padding: 0 24px;
}
.destinos-promo-weather {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 0px 24px;
    float: left;
    margin-bottom: 30px;
}
.promo-box_airline{
    width: 270px;
    float: none;
    padding-left: 9px;
    padding-right: 9px;
}
.promo-box-air h2{
    text-align: center;
    margin: 1em;
    line-height: 1.3;
    font-size: 1.6rem;
    color: white !important;
    text-shadow: -2px 2px 2px #000000 !important;
}
.promo-box-air_banner h3{
    text-align: center;
    margin: 10px 30px;
    line-height: 1.3;
    color: white !important;
    text-shadow: -2px 2px 2px #000001 !important;
}
.promo-box-air{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    position: relative;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.35), 0 1px 5px 0 rgba(0, 0, 0, 0.32), 0 3px 1px -2px rgba(0,0,0,.2);
}
.promo-box-air_banner{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100%;
    position: relative;
    text-align: center;
}
.content-destinos {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}
.clear{
    display:table;
    background:#005eb8;
    width:100%;
}
.link-list li {
    border-top: 1px solid #ddd;
    display: table;
    min-height: 42px;
    width: 100%;
}
.floatl {
    float: left;
    margin: 0;
}
.floatr {
    float: right;
    margin: 0;
    line-height: 42px;
}
.floatl a {
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    display: table-row;
    width: 100%;
    position: relative;
}
.floatr spam {
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    display: table-row;
    width: 100%;
    position: relative;
}
.floatr span {
    font-weight: 600;
    line-height: 42px;
    display: table-row;
}
.sitios-turisticos, .inf-gallery-cont{
    width: 100%;
}
.sitios-tur-izq, .inf-gallery-izq_dest  {
    width: 100%;
}
.sitios-tur-der, .inf-gallery-der_dest  {
    width: 100%;
    margin: 0 auto 30px;
}
.link-list {
    margin: 0;
    padding: 0 10px;
}
.clear h3 {
    float: left;
    margin: 0;
    color: #fff;
    padding: 10px 10px;
    font-size: 20px;
}
.clear p {
    float: right;
    margin: 0;
    font-size: 17px;
    padding: 7px 10px;
    bottom: 0;
    line-height: 36px;
    color: #fff;
}
.stripe-line {
    display: block;
    margin: 10px 0;
    height: 4px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    flex: 1;
    background-color: #009bf8;
}
.widget-title h2 {
    display: block;
    margin: 0 10px 0 0;
    font-size: 24px;
    text-transform: uppercase;
}
/**widget clima, destinos*/

.widget-weather{
    width: 100%;
    color: white;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
}
.weather{
    width: auto;
    color: white;
    padding: 10px 15px 0px;
    background-color: rgba(30, 68, 141, 0.38);
    h4{
        margin-bottom: 10px;
        color: white;
        font-size: 16px;
        line-height: inherit;
    }
}
.info-weather{
    width: 100%;
    display: inline-block;
    padding-bottom: 10px;
}
.content-7col{
    width: 60%;
    float: left;
}
.content-3col{
    width: 40%;
    float: left;
}
.temperature{
    font-size: 3.3em;
    line-height: 0.75;
}
.icon-weather{
    display: block;
    margin: 0 auto;
}
.forecast{
    display: table;
    width: 100%;
    padding: 5px;
    background-color: rgba(26, 59, 108, 0.8);
    border: solid 0.6px rgba(255, 255, 255, 0.5);
    border-right: none;
    margin: 0;
    li{
        display: table-cell;
        text-align: center;
        padding: 5px;
        border-right: none;
        img{
            width: 50px;
            margin-bottom: 10px;
        }
        @include media-query(palm) {
            padding: 0px;
        }
    }
}
.icon-forecast{
    margin: 0;
    height: 44px;
}
.day-forecast{
    font-size: 15px;
    color: white;
    padding: 5px;
    margin-bottom: 10px;
}
.temperature-forecast{
    font-size: 13px;
    color: white;
    padding: 4px;
    margin: 0px;
}
/*vertical*/
.forecast-vertical{
    width: 100%;
    padding: 5px;
    background-color: rgba(26, 59, 108, 0.8);
    border: solid 0.6px rgba(255, 255, 255, 0.5);
    border-right: none;
    margin: 0;
    justify-content: center;
    li{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 50px;
        }
    }
}




.img-area {
    position: relative;
    height: 170px;
    width: 460px;
    color: white;
    margin: 0 auto;
}
.img-area img {
    width: 100%;
    height: 170px;
    z-index: 1;
}
.img-area .img-mask {
    position: absolute;
    width: 100%;
    height: 170px;
    background: rgba(10, 10, 10, 0.83);
    z-index: 2;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
}
.img-area .img-area-front {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 170px;
    top: 0;
    left: 0;
}
.week-forecast {
    list-style: none;
    margin: 0px;
    background-image: url(/assets/aviatur_assets/img/weather/fondo.jpg);
    padding: 0;
    overflow: hidden;
    width: 460px;
    height: 120px;
    margin-bottom: 10px;
    margin: 0 auto;
}
.week-forecast > li {
    float: left;
    width: 25%;
    height: 120px;
}

.week-forecast .li-inner {
    float: left;
    width: 20%;
    height: 120px;
}

.week-forecast > li > .inner {
    border: 1px solid rgba(92, 92, 92, 0.77);
    width: 100%;
    height: 100%;
    padding-top: 15px;
    text-align: center;
}
.week-forecast > li > .inner  .climacon{
    text-shadow: 0 3px 1px rgba(0,0,0,0.1);
    font-size: 40px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid rgb(141, 141, 141);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.49);
    margin-bottom: 5px;
}
.week-forecast > li > .inner .climacon.rain.sun {
    padding-top: 2px;
    padding-left: 3px;
}
.week-forecast > li > .inner .climacon.cloud.sun {
    padding-top: 4px;
    padding-left: 2px;
}
.week-day {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 300;
    font-size: 13px;
    color: black;
}
.week-day-temperature {
    color: black;
    position: relative;
    font-size: 13px;
    margin: 0px;
}
.img-icon{
    width: 65%;
    margin-top: -5px;
    margin-bottom: 5px;
}
.location{
    margin-top: 10px;
}
.location h4, .location h5{
    margin: 0;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    line-height: inherit;
    color: white;
}
#weatherInformation{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}
#weather{
    width: 50%;
    float: left;
    margin-left: 15px;
}
.today {
    font-size: 33px;
    text-align: center;
    float: left;
    margin-left: 19px;
    margin-top: 20px;
}
.iconWeather{
    float: right;
    margin-top: 15px;
    width: 42% !important;
}

.iconWeather img{
    height: 100% !important;
    margin-left: 15px;
}
.weather-desc{
    width: 35%;
    height: 100%;
    float: right;
    margin-right: 10px;
}
.weather-desc > span {
    display: -webkit-box;
    padding: 10px 5px;
    background: rgba(0, 0, 0, 0.54);
    -webkit-border-radius: 5px;
    font-size: 13px;
    margin-top: 10px;
    text-align: -webkit-center;
}
.inner > span{
    font-size: 11px;
    color: black;
    text-align: center;
}
.content-info{
    width:100%;
    display:flex;
    margin-bottom: 50px;
    flex-flow: row wrap;
}
#dataProvided{
    float:right;
    font-size: 11px;
    color: white;
}

/**widget clima, destinos*/
.widget-weather{
    width: 100%;
    color: white;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
}
.weather{
    width: auto;
    color: white;
    padding: 10px 15px 0px;
    background-color: rgba(30, 68, 141, 0.38);
    h4{
        margin-bottom: 10px;
        color: white;
        font-size: 16px;
        line-height: inherit;
    }
}
.info-weather{
    width: 100%;
    display: inline-block;
    padding-bottom: 10px;
}
.content-7col{
    width: 60%;
    float: left;
}
.content-3col{
    width: 40%;
    float: left;
}
.temperature{
    font-size: 3.3em;
    line-height: 0.75;
}
.icon-weather{
    display: block;
    margin: 0 auto;
}
.forecast{
    display: table;
    width: 100%;
    padding: 5px;
    background-color: rgba(26, 59, 108, 0.8);
    border: solid 0.6px rgba(255, 255, 255, 0.5);
    border-right: none;
    margin: 0;
    li{
        display: table-cell;
        text-align: center;
        padding: 5px;
        border-right: none;
        img{
            width: 50px;
            margin-bottom: 10px;
        }
        @include media-query(palm) {
            padding: 0px;
        }
    }
}
.icon-forecast{
    margin: 0;
    height: 44px;
}
.day-forecast{
    font-size: 15px;
    color: white;
    padding: 5px;
    margin-bottom: 10px;
}
.temperature-forecast{
    font-size: 13px;
    color: white;
    padding: 5px;
    margin: 0px;
}
/*vertical*/
.forecast-vertical{
    width: 100%;
    padding: 5px;
    background-color: rgba(26, 59, 108, 0.8);
    border: solid 0.6px rgba(255, 255, 255, 0.5);
    border-right: none;
    margin: 0;
    justify-content: center;
    li{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 50px;
        }
    }
}

/**/





.today-blog {
    font-size: 34px;
    text-align: center;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
}
/**otros destinos*/
.otros_destinos {
    text-align: center;
}
.otros_destinos h2{
    background:#009bf8;
    color:#fff;
    text-shadow: none;
    padding: 5px;
}
.title-destinos{
    color: rgb(0, 155, 248);
    background: none;
    font-size: 25px;
    border-bottom: 3px solid rgb(0, 155, 248);
}
.enlace-destinos{
    width: 212px;
    position: relative;
    float: left;
    overflow: hidden;
    cursor: pointer;
}
.ofer__destinos {
    max-width: 212px;
    width: 100%;
    margin: 10px 10px;
    display: inline-block;
}
.ofer__destinos a {
    position: relative;
    margin: 5px;
    overflow: hidden;
    width: 214px;
    position: relative;
    float: left;
    margin: 5px;
    overflow: hidden;
}
.contenido-destinos:hover {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    -o-transform: scale(1.07);
    transform: scale(1.07);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.contenido-destinos {
    position: relative;
    box-sizing: border-box;
    min-height: 120px;
    background-size: cover;
    background-position: 50% 50%;
    padding: 5px;
    box-shadow: 0px 6px 15px #ccc;
    transition: all .5s ease;
}
.ofer__destinos h3 {
    left: 0;
    top: 40%;
    width: 100%;
    font-size: 22px;
    line-height: 20px;
    color: #fff;
    position: absolute;
    text-shadow: 2px 2px 2px #000;}
/*slide*/
.wrap {
    min-height: 290px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #120103;
    color: #fff;
    text-align: center;
    box-shadow: 0px 5px 13px #000;
}
.botones {
    background: rgba(255, 255, 255, 0.41);
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 14px #000;
    margin-left: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 900;
}
.botones label {
    border-radius: 100%;
    width: 17px;
    background: #ccc;
    float: left;
    z-index: 100;
    border: 4px solid #fff;
    box-shadow: 0 0 3px #888;
    margin: 5px 8px;
    font-size: 6px;
    bottom: 0;
}
.botones label:hover {
    background: #009bf8;
    border: 4px solid #fff;
    opacity: 0.7;
}
.slide {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 10;
    padding:  8em 1em 0;
    background-color: #120103;
    background-position: 50% 50%;
    background-size: cover;
    transition: left 0s .75s;
}
.slide h2,.img-article-blog h2{
    color: #fff;
    text-shadow: 3px 2px 3px #000;
    position: absolute;
    bottom: -14px;
    right: 7px;
    font-size: 16px;
    max-width: 70%;
}
.titleimg-slider{
    color: #fff;
    text-shadow: 3px 2px 3px #000;
    position: absolute;
    bottom: -14px;
    right: 7px;
    font-size: 16px;
}

[id^="slide"]:checked + .slide {
    left: 0;
    z-index: 100;
    transition: left .65s ease-out;
}
/*/fin slide/*/
@media screen and (min-width: 50em) {
    .destinos-promo {
        float: left;
        padding: 0 24px;
        flex: 0 0 50%;
    }
    .destinos-promo-cont {
        float: left;
        padding: 0 23px;
        flex: 0 0 33%;
    }
    .destinos-promo-weather {
        float: left;
        margin-right: 5px;
        flex: 0 0 33%;
        margin-bottom: 30px;
    }
    .destinos-air{
        float: left;
        padding: 0;
        flex: 0 0 72%;
    }
    .destinos-air_promo{
        float: right;
        padding: 0;
        flex: 0 0 27%;
    }
    .promo-box_airline{
        float: right;
    }
    .destinos-promo-blog {
        float: left;
        margin-bottom: 20px;
        padding: 0px 20px 0px 0px;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    }
    .sitios-tur-izq {
        float: left;
        width: 45%;
        padding-left: 9px;
        padding-right: 9px;
    }
    .sitios-tur-der {
        float: left;
        width: 55%;
        padding-left: 9px;
        padding-right: 9px;
    }
    .inf-gallery-izq_dest {
        float: left;
        width: 50%;
        padding: 0 24px;
    }
    .inf-gallery-der_dest {
        float: left;
        width: 50%;
        padding: 0 24px;
    }

    .img-area {
        position: relative;
        width: auto !important;
        height: 170px;
        color: white;
    }
    .week-forecast {
        list-style: none;
        margin: 0px;
        background-image: url(/assets/aviatur_assets/img/weather/fondo.jpg);
        padding: 0;
        overflow: hidden;
        width: auto !important;
        height: 116px !important;
    }
    .week-forecast > li {
        float: left;
        height: 116px !important;
    }
    .info-a-viajar{
        padding: 0 24px;
    }

}
/* Fin CSS Slide Document */
@media screen and (min-width: 967px) and (max-width: 1051px){
    .today{
        font-size: 30px !important;
        margin-left: 10px;
    }
    .today-blog {
        font-size: 40px !important;
    }
    .iconWeather img {
        margin-left: 10px;
    }
}

@media screen and (min-width: 882px) and (max-width: 966px){
    .today, .today-blog {
        font-size: 35px !important;
        margin-top:15px;
    }
    .iconWeather img {
        margin-left: 12px;
        margin-top: 0px !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 881px){
    .today, .today-blog {
        font-size: 30px !important;
        margin-top: 10px;
    }
    .iconWeather img {
        margin-left: 10px;
        margin-top: 0px !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 450px) and (max-width: 516px){
    .today, .today-blog{
        font-size: 35px !important;
    }
    .iconWeather img {
        margin-left: 15px;
        margin-top: 0px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
}

@media screen and (min-width: 407px) and (max-width: 449px){
    .today, .today-blog{
        font-size: 30px !important;
    }
    .iconWeather img {
        margin-left: 15px;
        margin-top: 0px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 406px){
    .today, .today-blog{
        font-size: 29px !important;
        margin-left: 9px !important;
    }
    .iconWeather img {
        margin-left: 10px;
        margin-top: 0px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
    .week-forecast > li {
        width:25% !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 350px){
    .today, .today-blog{
        font-size: 27px !important;
        margin-left: 0px !important;
    }
    .iconWeather img {
        margin-left: 5px;
        margin-top: 10px !important;
    }
    .img-area,.week-forecast {
        width: auto !important;
    }
    .week-forecast > li {
        width:33.3% !important;
    }
}

@media screen and (max-width: 63.9375em) {
    .promo-box_airline{
        margin: 0 auto 30px !important;
    }
}
/*Fin Css Destinos*/

/***Vuelos**/

.promo-box-vuelos {
    width: 100%;
    float: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    margin-bottom: 18px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
}
.promo-box-vuelos p{
    text-shadow: 1px 1px 2px #000;
}
.promo-box__grid-destinos {
    padding: 0px 10px;
}
.cortinilla-vuelos {
    background: linear-gradient(rgba(0, 0, 0, 0.86) 46%, transparent 100%);
    width: 40%;
    height: 100%;
    float:left;
}
.cortinilla-vuelos-2 {
    background: linear-gradient(rgba(0, 0, 0, 0.86) 46%, transparent 100%);
    width: 40%;
    height: 100%;
    float: right;
}
.promo-box__conditions-vuelos {
    color: #fff;
    font-weight: 100;
    text-align: justify;
    padding: 18px;
    height:100%;}

.promo-box__conditions-vuelos span {
    width: 100%;
    float: left;
    border-bottom: solid 1px;
    font-size: 30px;
    margin-bottom: 18px;
    padding-bottom: 5px;
    text-shadow: 1px 2px 3px #000;
}
.ver-mas-vuelos {
    float: left;
    width: 100%;
    background: #005eb8;
    border: solid 1px #013b73;
    color: #fff;
    padding: 10px;
}
.ver-mas-vuelos p, .ver-mas-vuelos h5{
    margin: 0;
    color: #fff;
    float: right;
}
.ver-mas-vuelos-content a{
    color:#fff;
    font-weight:100;
}

@media screen and (max-width: 44.9375em){
    .cortinilla-vuelos, .cortinilla-vuelos-2{
        width: 100%;
        background: linear-gradient(transparent , rgba(0, 0, 0, 0.86) 60%);
    }
}
/**Fin Vuelos**/

/**Ferias y fiestas**/
.ferias-fiestas{
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 20px;
}
.month {
    max-width: 347px;
    width: 100%;
    margin: 8px;
    display: inline-block;
    border: 1px solid #ccc;
    vertical-align: top;
    border-radius: 5px;
    box-shadow: 0px 0px 9px #ccc;
}
.month h2{
    text-align: center;
    font-size: 25px;
    padding: 8px 0;
    border-bottom: 3px solid #009bf8;
    margin-bottom: 10px;
}
.month ul{
    font-size: 15px;
    list-style: none;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 0px;
}
#feria {
    width: 100%;
    height: 180px;
    &.islink {
        cursor: pointer;
        -webkit-transition: all 0.7s ease;
        transition: all 0.5s ease;
        &:hover {
            -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0, 0, 0, 0.42), 0 2px 4px -1px rgba(0, 0, 0, 0.35);
            box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0, 0, 0, 0.42), 0 2px 4px -1px rgba(0, 0, 0, 0.35);
        }
    }
}

.content-feria {
    border-bottom: 1px solid #a8a7a7;
    color: #009bf8;
    padding: 10px 0;
    min-height: 180px;
    max-height: 200px;
}

.image-feria {
    float: left;
    width: 48%;
    background-size: cover;
    height: 100%;
}

.info-feria {
    width: 52%;
    float: right;
    text-align: center;
    height: 100%;
    background-color: #f3f3f3;
}
.info-feria h3{
    margin: 0;
    line-height: inherit;
    font-size: 15px;
}
.info-feria em{
    font-style: normal;
}
.info-feria h3 em{
    font-weight: bold;
    display: inline-block;
    padding: 20px 10px 5px;
}
.info-place{
    color: #000;
    display: inline-block;
}
.info-feria_dates{
    display: flex;
    justify-content: center;
}
.feria-days{
    font-size: 25px;
    font-weight: 900;
}
.days-separator{
    margin-right: 5px;
    margin-left: 5px;
}
.otras-ferias {
    color: #009bf8;
    padding: 10px 0;
    min-height: 120px;
    max-height: 200px;
}
.link-ferias{
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 13px;
}

@media screen and (min-width: 300px) and (max-width: 370px) {
    #feria {
        width: 100%;
        height: 150px !important;
    }
    .content-feria {
        min-height: 290px !important;
        max-height: 300px !important;
    }
    .image-feria {
        float: none !important;
        width: 100% !important;
        height: 120px !important;
    }
    .info-feria {
        width: 100% !important;
        float: none !important;
    }
    .otras-ferias {
        min-height: 130px;
    }
    .month {
        width: 100%;
        margin: 10px 0px 8px 0px;
    }
}
/**Fin ferias y fiestas**/
.articulo h1 {
    font-size:38px;
    letter-spacing:0.03em;
    line-height:1em;
    margin:50px 15px 20px;
    color:0b0b0b;
}
.articulo p{
    margin:0 15px 10px;
    line-height:1.5em;
}
.precios {
    width:100%;
}
.precios table{
    border-collapse: collapse;
    width:100%;
}
.precios tr:hover td{
    background-color:#f4f4f4;
}
.precios td{
    background-color:#fff;
    text-align:center;
    padding:5px 7px;
    font-size:15px;
    color:#a6a6a6;
}
.precios tr:first-child td{
    border-bottom: 2px solid #4EB7FB;
    background-color:#F6FBFF;
    text-align:center;
    font-size:16px;
    color:#666;
}
.top1 {
    text-align:left;
    padding-left:15px;
}
.tab_head {
    color:#0076c1;
    font-size:20px;
}
.tab_contenido {
    font-size:15px;
}
.cotizar_tab {
    background-color:#0076c1;
    width:175px;
    padding:10px;
    float:right;
    color:#fff;
    font-size:19px;
    margin-top:30px;
    text-align:center;
}
a:hover .cotizar_tab { background-color:#666;}
.clear{ clear:both;}


@media screen and (max-width: 1076px) {
    .mi-menu li a {font-size:16px;}
    .call_banner{margin-top:-110%;}
    .call_banner h4{font-size:15px;}
    .tx_banner h2 { font-size:50px;}
}
@media screen and (max-width: 1011px) {
    .logo{ width:70%;}
    .logo img { max-height:60px;}
    .telefono{ width:26%;}
    .cabezonte_campus{height:109px;}
    .mi-menu ul {top: 40px;}
}
@media screen and (max-width: 976px) {
    .promo_texto{background:none;}
    .promo_campus2, .promo_campus2 a:hover span.plus{height:400px;}
    .promo_texto2 {height:240px;}
    .promocionales{padding-bottom:30px;}
}
@media screen and (max-width: 900px) {
    .contenido-campus{ background-color:f4f4f4;}
    .promocional_campus{ padding: 15px 0; margin:0; height:175px;}
    .promo_texto h2{ font-size:16px;}
    .convenios_campus{padding:15px 1.5%}
    .footer_campus h2{font-size:12px;}
    .footer_campus a, .footer_campus p {font-size:10px;}
}
@media screen and (max-width: 820px) {
    .call_banner{margin-top:-110%;}
    .call_banner h4{font-size:13px;}
}
@media screen and (max-width: 744px) {
    #navigation{display:inherit;}
    .menu_campus{display:none;}
    .call_banner h3{font-size:23px;}
    .call_banner h4{font-size:11px;}
    .call_banner h5{font-size:15px;}
}
@media screen and (max-width: 718px) {
    .slider{display:none;}
    .promo_campus{ width:44%; margin:10px 3%;}
    .promo_texto h2{font-size:20px;}
    .promocional_campus{height:375px;}
    .menu_campus { padding:0 0 0 7%; margin:-30px auto 0;}
    .convenios_campus{display:none;}
}
@media screen and (max-width: 700px) {
    .promo_campus2, .link_promo{ width:47%; margin-bottom:10px;}
    .promo_campus2, .promo_campus2 a:hover span.plus{height:350px;}
    .promo_texto2 {height:190px;}
}
@media screen and (max-width: 650px) {
    .social_campus h4{ font-size:12px;}
    .social_campus {width:365px;}
}
@media screen and (max-width: 672px) {
    .banner_paquete, .eurocentres{width:96%; margin:2%;}
    .banner_contactenos { max-width:371px; margin:auto; float:none;}
    .call_banner h3{font-size:33px;}
    .call_banner h4{font-size:16px;}
    .call_banner h5{font-size:22px;}
    .call_banner{margin-top:-104%;}
    .promocionales{ margin-bottom:0;}
}
@media screen and (max-width: 615px) {
    .logo{ width:94%;}
    .telefono{ width:160px; margin:auto;}
}
@media screen and (max-width: 580px) {
    .social_campus h4{ font-size:0;}
    .social_campus{ width:46%;}
    .fifty{ width:46%; height:auto; max-height:176px;}
    .column_footer1, .column_footer2 { width:45%;}
    .footer_campus{ margin-bottom:333px;}
}
@media screen and (max-width: 561px) {
    .social_campus h4{ font-size:0;}
    .social_campus{ width:46%;}
    .fifty{ width:46%; height:auto; max-height:176px;}
}
@media screen and (max-width: 480px) {
    .promo_campus2, .link_promo{ width:282px; margin:auto; float: none; margin-bottom:10px;}
    .promo_campus2, .promo_campus2 a:hover span.plus{height:320px;}
    .promo_texto2 {height:160px;}
}
@media screen and (max-width: 470px) {
    .footer_campus h2{font-size:14px; margin-top:20px;}
    .footer_campus a, .footer_campus p {font-size:12px;}
    .column_footer1, .column_footer2 { width:94%; margin-left:4%;}
    .footer_campus{ margin-bottom:590px;}
}
@media screen and (max-width: 450px) {
    .social_campus img{ width:16%; height:auto; margin:5px 3px;max-height:176px;}
}
@media screen and (max-width:390px) {
    .promo_campus{ width:282px; margin:10px auto; float:none;}
    .promocional_campus{ height:706px;}
    .call_banner{margin-top:-104%;}
}

/**Css contenidos column**/

.wrapper_payment_methods{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @include media-query(palm) {
        width: 100%;
    }
}
.carouseles{
  height: 200px !important;  
}
.owl-carousel .nav-btn.nav-btn-slideh{
    top : -168px ;
    height: 35px;
    position: absolute;
    width: 35px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    color: #9a9c9e;
    font-weight: bold;
    text-align: center;
    line-height: 34px;

}
.preguntas-frecuentes-center{
    display: flex;
    text-align: center;
    justify-content: center;
}

@media only screen and (max-width: 721px){
    .preguntas-frecuentes-center{
        flex-direction: column;
    }
}
.owl-carousel .nav-btn {
            height: 35px;
            position: absolute;
            width: 35px;
            cursor: pointer;
            background: #fff;
            border-radius: 50%;
            color: #9a9c9e;
            font-weight: bold;
            text-align: center;
            line-height: 34px;
            &.nav-btn-slideh1{
                top: -158px !important;
                margin-left: 39px;
            }
        }
        
        @media only screen and (max-width: 721px){
            .owl-carousel .nav-btn { 
                line-height: 21px;
                height: 25px;
                width: 25px;
                &.nav-btn-slideh1{
                top: 58px !important;
                margin-left: 51px;
                margin-right: 51px;
            }
        }}

        .icon1{
            font-size: 12px !important;
         }


// .owl-carousel .nav-btn.nav-btn-slideh1{
//     top : -168px !important;
//     height: 35px;
//     position: absolute;
//     width: 35px;
//     cursor: pointer;
    
//     border-radius: 50%;
  
//     // font-weight: bold;
//     // text-align: center;
//     // line-height: 34px;
//     // color: #e7f52c;
//     // background: #dd3232;
// }

// .owl-carousel .nav-btn1 {
//     height: 35px;
//     position: absolute;
//     width: 35px;
//     cursor: pointer;    
//     background: #088fe9;
//     border-radius: 50%;
//     color: #fff;
//     font-weight: bold;
//     text-align: center;
//     line-height: 34px;
//     &.nav-btn-slide{
//         top: -230px !important;
//     }
//     &.nav-btn-slideh{
//         top: -355px !important;
//     }
//     &.nav-btn-slideg{
//         top: -130px !important;
//     }
// }


// .medios-pago1-principal {
    // background-color: blanchedalmond ;

// @media only screen and (max-width: 1015px){
//     .medios-pago1-principal{
//         background-color: blanchedalmond ;
//         box-sizing: border-box;
//         width: 95%!important;
//         margin-left: 1%;
//         margin-right: 50%;

//     }
// }


.wrapper1 {
    width: 74%;
    min-height: 950px;
    float: left;
    margin-right: 20px;
}

.wrapper2 {
    width: 22%;
    float: left;
    position: absolute;
    right: 0;
    height: 100%;
}

.cont-destacados {
    padding: 10px;
    font-size: 15px;
    a {
        font-weight: 100;
    }
    ol {
        margin-left: 27px;
        margin-bottom: 0;
    }
    li {
        padding: 10px 7px;
        font-size: 14px;
        border-bottom: solid 1px #e4e4e4;
    }
}

.destacados {
    background: #f7f7f7;
    &.horizontes-img {
        width: 100%;
    }
}

.destacados-title {
    padding: 10px;
    background: #009bf8;
    color: #fff;
    font-weight: bold;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 44.9375em) {
    .wrapper1, .wrapper1-blog {
        width: 100%; }
    .wrapper2, .wrapper2-blog {
        display: none; }
    .text-content{
        font-size:26px;
    }
}
/* ----------- iPad----------- */
/* Portrait and Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1.5) {
    .wrapper2, .wrapper2-blog {
        display: none; }
    .wrapper1, .wrapper1-blog{
        width: auto;
    }
    .img-icon{
        width: 60%;
        margin-top: -10px;
    }
    .titleimg-slider{
        right: 50px;
    }
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 1.5) {
    .wrapper2, .wrapper2-blog {
        display: none; }
    .wrapper1, .wrapper1-blog{
        width: auto;
    }
}

/**Css separación tiquetes-baratos-a**/
figure.promo-tickets {
    height: 300px;
    background: #043e52;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin: 0;
    img {
        width: -webkit-calc(100% + 50px);
        width: calc(100% + 50px);
        opacity: 0.7;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        height: 100%;
        border-radius: 0px;
    }
    figcaption {
        text-align: left;
        > div {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1.6em;
            width: 100%;
            height: 45%;
        }
    }
    h2 {
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        color: white;
        margin: 0;
        font-weight: 300;
        font-size: 1.775rem;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
    }
    &:hover {
        img {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        h2 {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
}

/**Css separacion contenidos**/

figure {
    &.promo-content {
        background-color: rgba(35, 36, 39, 0.88);
        height: 325px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        margin: 0;
        img {
            -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
            transition: opacity 0.35s, transform 0.35s;
            -webkit-transform: scale(1.12);
            transform: scale(1.12);
            min-height: 325px;
            opacity: 0.7;
        }
        &:hover img {
            opacity: 0.4;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        figcaption {
            padding: 2em;
            &::before {
                position: absolute;
                top: 30px;
                right: 30px;
                bottom: 30px;
                left: 30px;
                border: 1px solid #fff;
                content: '';
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
                opacity: 0;
                -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
                transition: opacity 0.35s, transform 0.35s;
            }
        }
        p {
            opacity: 0;
            -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
            transition: opacity 0.35s, transform 0.35s;
        }
        h2 {
            padding: 7% 0 20px 0;
            color: white;
            margin: 0;
            font-weight: 300;
            text-transform: uppercase;
        }
        p {
            font-size: 68%;
        }
        span {
            font-weight: 800;
        }
    }
    &.promo-tickets span {
        font-weight: 800;
    }
    &.promo-content:hover {
        figcaption::before, p {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
}

/**Css preguntas frecuentes**/

ul.switch-tabs-nav {
    position: relative;
    margin: 0;
    list-style: none;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    li {
        float: left;
        line-height: 1em;
        margin-bottom: -1px;
        background-color: #eee;
        border-right: 1px solid #ddd;
        border-top: 1px solid #ddd;
        a {
            display: block;
            padding: 18px 30px;
            font-size: 13px;
            text-decoration: none;
            text-transform: uppercase;
            color: #444;
        }
        &:first-child {
            border-left: 1px solid #ddd;
        }
        &:hover, &:focus {
            background-color: #fff;
        }
        &.active {
            background-color: #fff;
            border-top: 3px solid #009bf8;
        }
    }
    &.responsive li {
        float: none;
        display: block;
        border-left: 1px solid #ddd;
    }
}

.switch-tabs-body {
    position: relative;
    padding: 30px 40px 20px 40px;
    border: 1px solid #ddd;
    border-top: 1px solid transparent;
    text-align: justify;
    &.responsive {
        padding: 20px;
    }
    .switch-content {
        position: relative;
    }
}

.buttons-content {
    padding: 10px 0px;
    text-align: center;
    a {
        margin-right: 17px;
        color: #009bf8;
        border: 1px solid #009bf8;
    }
}

.btn-effect {
    display: inline-block;
    position: relative;
    padding: 20px 10px;
    margin: 5px;
    min-width: 250px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
}

.btn-effect_blog {
    display: inline-block;
    position: relative;
    padding: 20px 10px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    border: 1px solid #009bf8
}

.btn-vuelos:hover {
    background-image: url('/source/contenidos/boton-vuelos.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-hotel:hover {
    background-image: url('/source/contenidos/boton-hotel.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-experiencia:hover {
    background-image: url('/source/revista-horizontes/turquia.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-experiencia_dos:hover {
    background-image: url('/source/contenidos/boton-experiencias.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-cruceros:hover {
    background-image: url("/source/contenidos/cubierta-del-barco.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-autos:hover {
    background-image: url("/source/contenidos/boton-autos.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-buses:hover {
    background-image: url("/source/contenidos/boton-buses.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-trenes:hover {
    background-image: url("/source/contenidos/boton-trenes.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-casa-navegante:hover {
    background-image: url("http://www.aviaturecoturismo.com/multisites/aviaturecoturismo/images/slides/colombia/cholon/cholon-11.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-avia-isleta:hover {
    background-image: url("http://www.aviaturecoturismo.com/multisites/aviaturecoturismo/images/slides/colombia/aviaisleta/aviaisleta_12.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-hotel-islas:hover {
    background-image: url("/source/contenidos/boton-islas.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px #000;
}

.btn-tayrona {
    width: 360px;
    height: 60px;
    background-image: url("/source/contenidos/boton-tayrona.png");
    background-size: cover;
    background-position: center;
}

// .btn-bono-regalo {
//     height: 60px;
//     background-image: url("/source/contenidos/boton-bono-regalo.jpg");
//     background-size: cover;
//     background-position: center;
// }
.btn-hoteles {
    height: 60px;
    background-image: url("/source/contenidos/icon.svg");
    background-size: cover;
    background-position: center;
}

.btn-tarjeta-asistencia {
    height: 60px;
    background-image: url("/source/contenidos/boton-tarjeta-asistencia.jpg");
    background-size: cover;
    background-position: center;
    border: 1px solid #ff0010 !important;
}

.btn-check-in {
    padding: 10px;
    &:hover {
        box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.28), 0 1px 5px 0 rgba(0, 0, 0, 0.18), 0 3px 10px 1px rgba(0, 0, 0, 0.18);
    }
    .icon_bundle_flight {
        font-size: 30px;
    }
}
/*Estilos Error 404 */

.text-error--404 {
    position: absolute;
    left: 5%;
    bottom: 5%;
    color: white;
    @include media-query(lap-and-up) {
        width: 32%;
    }
}

.error-404 {
    background-image: url(/source/aviatur-general/error-404-aviatur.jpg);
    min-height: 700px;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
}

.text-error--404 {
    h1 {
        color: white;
        text-shadow: 2px 2px 3px #000;
    }
    .error-sbt {
        text-shadow: 1px 1px 1px #000;
    }
}

.content-con.tag-seo {
    ul{
        list-style: none; margin: 0;
    }
    li > a {
        display: block;
        font-size: 13px;
        line-height: normal;
        color: rgba(0, 0, 0, 0.87);
    }
    h3 {
        font-size: 16px;
    }
}

.content-con.tag-seo-list {
    ul{
        list-style: none; margin: 0;
    }
    li > a {
        display: block;
        font-size: 13px;
        border-bottom: 1px solid #009bf8;
        line-height: normal;
        color: rgba(0, 0, 0, 0.87);
    }
    h3 {
        font-size: 16px;
    }
}


.promof{
    margin: 10px;
    width: 48%;
    float: left;
    display: block;
    padding: 3em 2em;
    @include media-query(palm) {
        width: 100%;
        padding: 0;
        margin: 0px 0px 10px;
    }

}
.title-promof{
    width: 100%;
    float: left;
    border-bottom: solid 1px;
    color: #fff;
    padding: 5px;
}
.title-promof h4{
    color: #fff;
    margin: 0;
    font-size: 26px;
}
.content-promof{
    font-size: 17px;
    padding: 3em 1.5em;
    color: #fff;
    background: rgba(0, 0, 0, 0.62);
    @include media-query(palm) {
        padding: 3em 5px;
    }
}
.img-logocar{
    text-align: center;
    float: right;
    width: 100%;
    max-width: 290px;
    @include media-query(palm) {
        float: none;
        max-width: none;
    }
}
.text-car_rental{
    text-align: center;
    text-shadow: none !important;
    margin: 3% 0;
    width: 100%;
    max-width: 864px;
    float: right;
}
/* ----------- iPad----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .promof {
        margin: auto;
        margin-bottom: 10px;
        width: 70%;
        float: none;
    }
    .img-logocar{
        float: none;
        max-width: none;
    }
    .text-car_rental{
        float: none;
    }
    .ofertas-av h2{
        padding: 12px !important;
    }


}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .promof {
        margin: auto;
        margin-bottom: 10px;
        width: 60%;
        float: none;
    }
    .ofertas-av h2
    {
        padding: 23px !important;
    }

}
.content-hoteles{
    width: 25%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.content-hoteles-destino {
    width: 50%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.content-hoteles_text {
    left: 0;
    top: 40%;
    width: 100%;
    font-size: 22px;
    text-align: center;
    line-height: 20px;
    background-color: rgba(0, 123, 191, 0.6);
    padding: 8px;
    position: absolute;
    text-shadow: 2px 2px 2px #000;
    h3 {
        margin: 0;
        color: white;
    }
    p {
        color: white;
        font-size: 16px;
        font-weight: 100;
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

#flights-ticketsb{
    text-align: center;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.15), 0 4px 5px 0 rgba(0, 0, 0, 0.23), 0 1px 10px 0 rgba(0, 0, 0, 0.09);
}
#flights-routes{
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.head-ticketsb{
    width: 100%;
    text-align: center;
    color: white
}
.promo-ticketsb{
    width: 100%;
    display:inline-block;
    border-top: 1px solid #ddd;
}
.ticketsb-origin, .ticketsb-destination, .ticketsb-origin-ar, .ticketsb-destination-ar, .fligth-route, .fligth-time{
    float: left;
    padding: 10px;
    font-size: 14px;
}

.ticketsb-price, .tickets-link, .ticketsb-price-ar, .tickets-link-ar, .tickets-type{
    float: left;
    padding: 10px;
    font-size: 14px;
}
.fligth-route,.fligth-time{
    width: 50%;
}
.airline-logo_cont{
    height: 50px;
}
.conditions-flight{
    padding: 20px 0px;
    font-size: 12px;
    color: #a7a7a7;
}

.conditions-flight a{
    text-decoration: underline;
    color: #909090;
}

.breadcumb{
    font-size: 14px;
    height: 40px;
    list-style: none;
    margin: 0;
    font-weight: 100;
}

.breadcumb li{
    float:left;
}
.breadcumb .icon{
    font-size: 9px;
    margin-right: 4px;
}

.breadcumb li:last-child a, .breadcumb li:last-child span{
    font-weight: 600;
    color: #004d7b;
}

.detail-content__carousel__image {
    width: 100%;
    background: no-repeat center center;
    border-radius: $base-round;
    background-size: cover;
    @include media-query(palm) {
        width: 100%;
    }
}

.list-airlines{
    list-style: none;
    margin: 0 auto;
}
@media screen and (max-width: 44.9375em){
    .ticketsb-origin, .ticketsb-destination, .ticketsb-origin-ar, .ticketsb-destination-ar{
        min-width: 50%;
    }
    .ticketsb-price, .tickets-type, .ticketsb-price-ar, .tickets-link-ar{
        min-width: 50%;
    }
    .tickets-link{
        min-width: 100%;
        text-align:right;
    }
    .head-ticketsb .ticketsb-price, .head-ticketsb .ticketsb-price-ar, .head-ticketsb .tickets-type{
        display:none;
    }
    .sitios-turisticos, .inf-gallery-cont{
        padding: 0 24px;
    }
    .breadcumb{
        font-size:11px;
        margin-left: 10px;
    }
    .breadcrumb li:first-child, .breadcumb li:first-child a, .breadcumb li:first-child i {
        display:none;
    }
    .promo-box__title-banner{
        width: 100%;
    }
}

@media screen and (min-width: 45em){
    .ticketsb-origin, .ticketsb-destination{
        min-width: 23%;
    }
    .ticketsb-price, .tickets-link, .tickets-type{
        min-width: 18%;
    }
    .ticketsb-origin-ar, .ticketsb-destination-ar{
        width: 27%;
    }
    .ticketsb-price-ar, .tickets-link-ar{
        width: 23%;
    }

}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .tickets-link{
        float:right;
    }

}

.info-a-viajar{
    margin: 30px 0px;
    display: inline-block;
}

.info-a-viajar img{
    padding: 10px 10px 20px;
}


#pagination{
    height: 60px;
    padding: 10px;
    text-align: center;
}
#enlaceajax{
    color: #009bf8;
    border: 1px solid #009bf8;
    border-radius: 5%;
    font-weight: 600;
    padding: 8px 17px;
    background-color: transparent;
}

.ofertas-av, .cont-av{
    width: 100%;
    display: inline-block;
}

.ofertas-av p, .cont-av p{
    width: 25%;
    float: right;
    text-align: center;
    @include media-query(palm) {
        float: none;
        width: 100%;
        margin:0px;
    }
}

.cont-av p{
    width: 25%;
    float: right;
    text-align: center;
    padding: 24px 0;
    @include media-query(palm) {
        float: none;
        width: 100%;
        margin:0px;
    }
}


.ofertas-av h2{
    padding: 30px;
    width: 75%;
    float: left;
    text-shadow: none;
    @include media-query(palm) {
        float: none;
        width: 100%;
    }
}

.cont-av h2{
    margin: 4% 0;
    width: 75%;
    float: left;
    text-shadow: none;
    @include media-query(palm) {
        float: none;
        width: 100%;
    }
}

.box-content{
    min-height: 72px;
}

.content-dv{
    width: 50%;
    float: left;
    min-height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    figure > img{
        border-radius:0px;
    }
    h3{
        line-height: 1.2;
    }
    @include media-query(palm) {
        width: 100%;
    }
}
.dv-color1{
    background: #2e4173;
    padding: 31px;
}
.dv-color2{
    background: #6397c6;
    padding: 31px;
}
.dv-color3{
    background: #538790;
    padding: 31px;
}
.dv-color4{
    background: #549575;
    padding: 31px;
}
.dv-color5{
    background: #252048;
    padding: 31px;
}
.img-description{
    position: absolute;
    bottom: 4px;
    font-size: 12px;
    left: 1rem;
    font-weight: 600;
}
.figcaption-dv{
    margin-top: -20px;
    @include media-query(palm) {
        margin-top: -40px;
    }
}
.figcaption-dv_r{
    margin-top: -40px;
    @include media-query(palm) {
        margin-top: -55px;
    }
}
.full-div{
    width:100%;
}
.enumeration-div{
    float: left;
    width: 10%;
    @include media-query(palm) {
        width: 100%;
        img{
            width: 83px !important;
        }
    }
}.enumeration-div_text{
    width: 90%;
    float: left;
    padding: 27px 20px;
    @include media-query(palm) {
        width: 100%;
        padding: 27px 0px 0px;
    }
}
.enumeration-div_text .p-center{
    @include media-query(palm) {
        text-align: center;
    }
}
u{
    text-decoration: underline;
}
.section-flex{
    margin: 0 auto;
    display: flex;
    @include media-query(palm) {
        display: block;
    }
}

.content-policy-disclaimer {
    color: #565656;
    font-size: 0.65em;
    padding-bottom: 2px;
    margin-top: 35px;
    label {
        font-size: 12px;
    }
    @include media-query(palm) {
        width: 100%;
        padding-right: 10%;
        padding-left: 10%;
        float: left;
    }
}
.btn-register {
    margin-top: 32px;
}

.table-rss {
    width: 100%;
    margin: 0 0 70px;
}

.table-rss th {
    padding: 18px 0 10px;
}

.table-rss td {
    padding: 18px 10px 10px 0;
    border-bottom: 1px solid #CCC;
    width: 45%;
    @include media-query(palm) {
        width: 80%;
    }
}

.table-rss tr {
    border-bottom: 1px solid black;
}

.table-rss td img {
    margin-right: 10px;
}

.table-rss a {
    font-weight: 100;
}

.table-rss-display{
    @include media-query(palm) {
        display:none;
    }
}
.wrapper-calendar__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.wrapper-calendar__content__container {
    background: #fff;
    display: flex;
    border: 1px solid #ccc;
    flex-direction: column;
    width: 78%;
    margin: 5px 15px;
    @include media-query(palm) {
        width: 100%;
    }
}
.draggable-container {
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    cursor: grab;
    user-select: none;
    .draggable-wrap {
        white-space: nowrap;
        position: relative;
        left: 0;
    }
    .draggable-inner {
        display: inline-flex;
    }
}

.wrapper-calendar__content__container__inner {
    height: 260px;
}

.graph-month {
    border-right: 1px dashed #787777;
    display: flex;
    flex-direction: column;
    cursor:auto;
}

.wrapper-calendar__content__container__month__body {
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    flex: 1;
    position: relative;
}

.wrapper-calendar__content__container__month__body__data-column {
    width: 35px;
}

.data-best-price{
    margin-left: 4.5px;
    margin-right: 4.5px;
}

.data-best-price.bar-green{
    background-color: rgba(63, 153, 15, 0.41);
    &:hover {
        background-color: #3f990f;
    }
}
.data-best-price.bar-blue{
    background-color: rgba(0, 155, 248, 0.41);
    &:hover {
        background-color: #009bf8;
    }
}

.content-tooltip {
    position: absolute;
    background: #000;
    border-radius: 5px;
    padding: 10px 12px;
    min-width: 170px;
    font-weight: normal;
    color: #fff;
    line-height: initial;
    display: none;
}

.left{
    &:after {
        border-top: 8px solid #000;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        content: '';
        left: 4%;
        position: absolute;
        top: 100%;
        width: 0;
        height: 0;
    }
}

.right{
    &:after {
        border-top: 8px solid #000;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        content: '';
        right: 4%;
        position: absolute;
        top: 100%;
        width: 0;
        height: 0;
    }
}


.tol-active{
    display: block;
    z-index: 999;
}

/*data-best-price:hover .content-tooltip {
  opacity: .85;
  display: block;

}*/

.double-currency {
    font-size: 15px;
    font-weight: 600;
}

.ida-vuelta {
    font-size: 14px;
    padding-top: 5px;
}

.draggable-container::-webkit-scrollbar {
    height: 10px;
}

.draggable-container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.graph-row{
    height: 35px;
    background-color: #009bf8;
    color: white;
    width: 35px;
    border: 1px solid #009bf8;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 35px;
}
.wrapper-cont {
    width: 70%;
    float: left;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.padding-cont {
    padding: 0px 30px;
    @include media-query(palm) {
        padding: 0px;
    }
}
.conten-promo-insu{
    @include media-query (palm){
        min-height: auto;
    }
    @include media-query(lap){
        width: 48.555%;
        min-height: 320px;
    }
}
.column-cont {
    float: left;
    width: 30%;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.inline-full{
    display: inline-block;
    width: 100%;
}

.card {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.card.contenido h2{
    font-size: 1.3rem;
    font-weight: 600;
}

.card.contenido p{
    font-weight: 300;
    color: #000000;
    text-align: justify;
    margin-right: 20px;
}

.card.contenido .btn-check-in{
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px;
}

.card.card-aerolineas{
    cursor: pointer;
    h3{
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-top: 10px;
    }
    @include media-query(palm) {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 10px;
    }

}

.card a, .card a:hover{
    text-decoration: none
}
.row-contenido{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;    
}
.collapse{
    display: none;
}
