/*------------------------------------*\
    #LABEL
\*------------------------------------*/
label {
    display: block;
    margin-bottom: $inuit-base-spacing-unit--tiny;
}

label.error{
    color:#E41522 !important;
    position:absolute;
    top:4.0rem;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.error:hover{
    color:#E41522;
}

.label-inuit-inline {
    display: inline-block;
    margin-bottom: 0;
}
.label-inuit-inline.black_friday {
    color: white;
}

.label--error {
    color: $color-label-error !important;
    cursor: default !important;
    float: left;
}

.label-top {
    margin-bottom: 2px;
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: 400;
    min-height: 1.2em;
    @include attention() {
        cursor: default;
    }
    @include media-query(palm) {
        color: #5a5a5a;
    }
}
.search-insurance-main .label-top {
    @include media-query(palm) {
        color: var(--White, #FFF);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    @include media-query(lap) {
        color: var(--White, #FFF);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.label-top-journey {
    margin-bottom: 2px;
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: bold;
    min-height: 1.2em;
    color: #e1e1e1;
    @include attention() {
        cursor: default;
    }
    @media (max-width: 991px) {
        color: #5a5a5a;
    }
    &:hover {
        background: #e1e1e1;
    }
}

.label-top-journey-avail {
    margin-bottom: 2px;
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: bold;
    min-height: 1.2em;
    color: #1a1a1a;
    @include attention() {
        cursor: default;
    }
    &:hover {
        background: #a1a1a1;
    }
}