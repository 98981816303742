/*------------------------------------*\
    #RESUME SEARCH
\*------------------------------------*/

.resume-search {
    @extend %frame-element;
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    .hide-availengine {
        display: none;
    }
    .show-availengine {
        display: block;
    }
    .show-availengine-avail {
        display: contents;
    }
    .search__engine {
        @include media-query(palm) {
            margin-top: $inuit-base-spacing-unit--large;
        }
    }
    .search__btn-group {
        float: left;
    }
}
.resume-search__palm {
    float: left;
    margin-top: $inuit-base-spacing-unit--small;
    text-align: left;
}
.resume-search__arrow-wrapper {
    @include media-query(palm) {
        font-size: .8em;
    }
    & .resume-search__block__label {
        display: block;
        margin-top: 3px;
    }
}
.resume-search__separator {
    height: 2*$inuit-base-line-height;
    margin: $inuit-base-spacing-unit--small 0;
    border-left: 1px solid $color-frame-border;
    @include media-query(lap-and-up) {
        margin: $inuit-base-spacing-unit $inuit-base-spacing-unit--small;
    }
}
.resume-search__separator--inner {
    @extend .resume-search__separator;
    margin: 0;
}
.resume-search__block {
    margin: $inuit-base-spacing-unit--small 0;
    @include media-query(lap-and-up) {
        margin: $inuit-base-spacing-unit 0;
    }
}
.resume-search__block__content {
    padding: 0 $inuit-base-spacing-unit--small;
    .package{
        border-bottom: 1px solid;
    }
    @include media-query(lap-and-up) {
        padding: 0 $inuit-base-spacing-unit;
    }
}
.resume-search__block__change-button {
    padding: $inuit-base-spacing-unit--small;
    @include font-weight(600);
    @include media-query(lap-and-up) {
        margin-right: $inuit-base-spacing-unit--small;
    }
}
.resume-search__engine {
    padding: 0 $inuit-base-spacing-unit--small;
}
.resume-search__engine__close {
    top: 0;
    right: 0;
    padding: $inuit-base-spacing-unit--small;
    line-height: 0;
    color: $color-negative;
    cursor: pointer;
    @include attention() {
        color: darken($color-negative, 10%);
    }
}
