/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a, .link-span {
    transition: color .2s;
    text-decoration: none;
    color: $color-links;
    cursor: pointer;
    @include attention() {
        color: darken($color-links, 10%);
    }
    &.is-active {
        color: darken($color-links, 10%);
    }
    &.is-disabled {
        color: $color-links-disable;
        cursor: default;
        @include attention() {
            color: $color-links-disable;
        }
    }
}

@mixin link-quiet ($color-link: $color-links-quiet) {
    transition: color .2s;
    text-decoration: none;
    color: $color-link;
    @include attention() {
        color: darken($color-link, 10%);
    }

}

.link-quiet {
    @include link-quiet();
    cursor: pointer;
}

.link-secret {
    color: inherit;
    @include font-weight(regular);
    @include attention() {
        color: $color-links;
        text-decoration: none;
    }
}

.link-slim {
    @extend .link-span;
    font-weight: 400;
}

.btn-link {
    display: inline-block;
    color: unset;
    transition: 0s;
    @include attention() {
        color: unset;
    }
}
.boletin-ofertas{
    color: gray;
    text-decoration: underline;
}
.boletinForm-butoom{
    width: 20%;
    margin: 30px 0px 0px 15px;
    @media screen and (max-width:721px) {
      width: 92%;
      padding-top: 10px;
    }
}
.boletinForm-email{
    display: flex;
    justify-content: center;
    @media screen and (max-width:721px) {
        display: block;
    }
}
#js-newsletterRQ{
    padding-top: 50px;
    padding-bottom: 40px;
}
.form-group-nombre, .form-group-email{
    height: 60px;
    width: 30%;
    float: left;
    @media screen and (max-width:721px) {
        width: 90%;
        margin:0 auto 5px;
        float: initial;
        height: max-content;
    }
}
.form-group-email{
    margin-left: 23px;
    @media screen and (max-width:721px) {
       margin-left: auto;
    }
}
.datos-boletin{
    color:gray;
}
#newsletter_form_firstname, #newsletter_form_email{
    margin-top: 5px;
}
.conditions-boletin{
    color: gray;
}
