///*------------------------------------*\
//   #SEARCH
//\*------------------------------------*/
@font-face { font-family: "fontPromoSearchAgency";src: url("/assets/aviatur_assets/font/LEDCalculator.ttf"); }

.search__header {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(lap-and-up) {
        padding-top: $inuit-base-spacing-unit;
    }
}
.search__title {
    float: left;
    margin-bottom: 0;
    font-size: 30px;
    font-size: 2.25rem;
    color: #444;
    @include media-query(palm) {
        display: block;
        float: none;
        margin-bottom: $inuit-base-spacing-unit;
        text-align: center;
        color: $color-brand;
        @include font-size(28px);
    }
}
.search__title-principal-flight {
    float: left;
    margin-bottom: 0;
    //font-size: 30px;
    font-size: 1.75rem;
    color: #444;
    @include media-query(palm) {
        display: block;
        float: none;
        margin-bottom: $inuit-base-spacing-unit;
        text-align: left;
        color: $color-brand;
        //@include font-size(28px);
        font-size: 1.375rem !important;
    }
}
.search__title__icon {
  display: none;
  color: $color-title-icon;
  font-size: quadruple($inuit-base-font-size);
}
.search__btn-group {
    float: right;
    display: table;
    @include font-size(15px);
    @include media-query(palm) {
        float: none;
        display: table;
        margin: 0 auto;
    }
}
.search__btn-group_blog {
    margin: 0 auto;
    display: table;
    font-size: 10px;
    line-height: 1.28571;
     @include media-query(palm) {
         @include font-size(14px);
    }
}
.search__btn-group > .btn {
    font-weight: 300;
    padding:10px;
    @include media-query(small) {
        font-size: 11px;
    }
}


// - + Buttons for passengers
.search__btn-increment button {
    border-radius: 40px; //Very hight to get a circle
    margin-left: $inuit-base-spacing-unit--small;
    padding: $inuit-base-spacing-unit--small;
}
.search__btn-increment_blog button {
    border-radius: 40px;
    margin-left: 9px;
     padding: 7px 11px; }
// - + Buttons for passengers
.search__btn-increment-square button {
    margin-left: $inuit-base-spacing-unit--small;
    padding: $inuit-base-spacing-unit--small;
}
.search__btn-increment span {
    display: block;
    width: $inuit-base-spacing-unit;
}


.search__row-main {
    margin-bottom: 0;
}

// Search options
.search__option {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    cursor: pointer;
    &.is-disabled {
        display: none;
    }
    &.link-span {
        @include font-weight(bold);
    }
}
.search__option__label {
    line-height: $inuit-base-spacing-unit*2;
}


// Instance creator cta
.search__link-add-multi {
    @include media-query(lap-and-up) {
        margin-bottom: $inuit-base-spacing-unit;
    }
}

.search__picker {
    position: absolute;
    width: 100%;
    padding: (calc($inuit-base-spacing-unit--small/2)) $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        width: 200%;
    }
}

// Header search engine
.search__light {
    @include media-query(palm) {
    //         /* margin-top: 5vh; */
    // /* padding-bottom: 110px; */
    // /* padding-bottom: 4vh; */
    // /* padding-top: 110px; */
    // /* padding-top: 4vh;

    }
    @include media-query(lap) {
        padding-bottom: 92px;
        padding-bottom: 9vh;
        padding-top: 92px;
        padding-top: 9vh;
    }
    @include media-query(desk) {
      /*margin-top:5vh;*/
      /*padding-bottom: 110px;*/
      /*padding-bottom: 4vh;*/
      /*padding-top: 110px;*/
      /*padding-top: 4vh;*/
    }
    @include media-query(lap-and-up) {
        .page__body--front & {
            padding-top: 100px;
        }

    }
}
.search__light--box {
    //padding: 0 0;
    @include media-query(lap-and-up) {
        margin:30px 0;
        //padding: 0 $inuit-base-spacing-unit;
        //padding:0;
        & .btn-group > .btn--inactive {
          //color: $color-brand;
          color: #ffffff;
          font-weight: bold;
          font-family: 'Ubuntu';
          background-color:transparent;
        //padding: 12%;
        }
        .page__body--front & {
            margin-top: 0;
        }
        & .link-span {
            font-weight: 500;
        }
        & .search__option {
            color: #ffffff;
            background-color: rgba(0,0,0,0);
        }
        & .search__option__label {
            color: #444;
        }
        & .label-top {
            color: #ffffff;
        }
        & .label--error {
            color: #444 !important;
            background-color: rgba($color-label-error, .3);
            padding: 0 5px;
            border-top: 1px solid rgba($color-label-error, .7);
            border-bottom: 2px solid rgba($color-label-error, .7);
            margin-top: 2px;
        }
    }
    @include media-query (palm) {
        box-shadow: none;
    }
}
.search__light--box-flight {
    //padding: 0 0;
    font-weight: bold;
    font-family: 'Ubuntu';
    //@include media-query(lap-and-up) {
    @media (min-width: 992px) {
        min-height: 45vw;
        align-items: center;
        display: inline-flex;
        & .btn-group .btn{
            display: flex;
            font-weight: 500;
        }
        & .btn-group > .btn--inactive {
          //color: $color-brand;
          color: #ffffff;
          background-color:transparent;
          padding: 10px;
        }
        .page__body--front & {
            margin-top: 0;
        }
        & .link-span {
            font-size: 1rem;
            font-weight: 600;
            text-decoration: underline;
            font-family: 'Roboto';
        }
        & .search__option {
            //color: #444;
            color: #ffffff;
        }
        & .search__option__label {
            color: #444;
        }
        & .label-top {
            color: #ffffff;
        }
        & .label--error {
            color: #444 !important;
            background-color: rgba($color-label-error, .3);
            padding: 0 5px;
            border-top: 1px solid rgba($color-label-error, .7);
            border-bottom: 2px solid rgba($color-label-error, .7);
            margin-top: 2px;
        }
        & .label--error-div {
            color: $color-negative ;
            font-size: 12px;
            font-weight:normal;
            padding: 10px 5px;
            margin-top: 2px;
            background: white;
            // &:after {
            //     border-bottom: 8px solid white;
            //     border-right: 8px solid transparent;
            //     border-left: 8px solid transparent;
            //     content: "";
            //     left: 5%;
            //     position: absolute;
            //     top: 33%;
            //     width: 0;
            // }
        }
    }
    //@include media-query (palm) {
    @media (max-width: 991px) {
        box-shadow: none;
        & .btn-group > .btn--inactive {
            //color: $color-brand;
            color: #5a5a5a;
            background-color:transparent;
            padding: 10px;
        }
        & .search__option {
            //color: #444;
            color: #5a5a5a;
            background-color: rgba(255,255,255,.1);
        }
        & .label--error-div {
            color: #1a1a1a !important;
            font-size: 12px;
            font-weight:normal;
            background-color: #ffffff;
            padding: 0 5px;
            box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
            margin-top: 2px;
        }
    }
}


// Search datepicker
.search-shared__datepicker-container {
    @extend %picker;
    display: none;
    position: absolute;
    padding: 9px 9px 0 9px;
    /*width: 820px;*/
    width: 600px;
    @include media-query(lap) {
        width: calc(100% - 20px);
        max-width: 420px;
        padding-bottom: $inuit-base-spacing-unit--small;
    }
    @include media-query(palm) {
        /*left: 0 !important;*/
        /*max-width: 420px;*/
        left: auto !important;
        width: 100%;
        padding-top: $inuit-base-spacing-unit;
        padding-bottom: $inuit-base-spacing-unit;
    }
}
.search-shared__datepicker-hotel{
    width: 780px !important;
    @media (max-width: 45.0625em) {
        width: 100% !important;
    }
}
.search-shared__datepicker-container_blog {
    display: none;
    position: absolute;
    padding: 9px 9px 0 9px;
    width: 287px; }

.search-shared_datepicker-arrow-top {
    display: none;
    position: absolute;
    left: 30px;
    bottom: -15px;
    height: 20px;
    z-index: $z-index-datepicker-arrow;
}

.dropbtn {
    background-color: rgba(0, 155, 248, 0.01);
    font-family: 'Roboto';
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: #042A44;
    border: none;
    cursor: pointer;
    padding: 2px 10px 2px 5px;
    min-width: 5.5em;
    @include media-query(palm) {
        color: #fff;
    }
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #b5b5b5;
}

.imgpos {
    vertical-align: bottom;
    float: left;
    max-width: 15px;
    border-radius: 40px;
    margin-right: 5px;
}

.mainimgpos {
    img {
        width: 30px;
    }
}

.coinflag-text{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #042A44;
}
.mobile-dropbtn {
    .coinflag-text {
        display: none;
    }
    img {
      max-width: 30px;
      width: 30px;
      float: right;
      margin-right: 20px;
    }
}

.imgpos-big {
    vertical-align: bottom;
    float: left;
    width: 80px;
}

.coinList {
    position: relative;
    display: inline-block;
}

.coinListContent {
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 21;
    @include media-query(palm) {
        right: 10px;
        width: 90px;
        top: 25px;
    }
}

.coinListContent a {
    color: #000;
    //padding: 12px 16px;
    text-decoration: none;
    display: block;
    padding:10px 10px 10px 5px;
    @include media-query(palm) {
        padding: 6px 10px 6px 5px;
    }
}

.coinListContent a:hover {background-color: #A4A4A4}

.show {display:block;}

.search-promoSearch {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1%;
    font-family: Arial Black;
    @include media-query(palm) {
        display: block;
    }
}

.search-promo-traffic-light{
    width: 25%;
    height: 20%;
    background-color: #0359c5;
    border-radius: 50%;
    box-shadow: 5px 8px 10px 10px #0359c5;
    @include media-query(palm) {
        box-shadow: 12px 12px 7px 15px #0359c5;
    }
}
.search-contPromoSearch {
   width: 70%;
   background: #192027;
   padding: 1% 3%;
   color: rgb(2, 103, 230);
   @include media-query(palm) {
       width: 80%;
       margin-left:10%;
   }
}

.search-contIcon {
    width: 5%;
    padding: 6px;
    background: #0267e6;
    border-radius: 10px;
    margin-right: 8px;
    @include media-query(palm) {
        width: 20%;
        background: #f8f9fb00;
        border: 1px solid;
    }
}

.search-contTitle {
   width: 80%;
   display: inline-grid;
   font-size: 22px;
}

.search-contIconTitel {
   display: flex;
}

#titelpromo2 {
   font-size: 11px;
   font-family: Arial;
}

.search-contPromoBody {
   display: block;
   width: 100%;
   border: 3px solid #000;
   margin-top: 5px;
}

.search-contTitlePromoBody {
   display: flex;
   font-size: 15px;
   font-weight: bold;
   width: 100%;
}

.search-titlePromoBody {
   min-width: 19%;
   padding: 1% 4%;
   text-align: center;
}

.search-contBodyPromoBody {
   display: flex;
   width: 100%;
   background: #000;
   text-align: center;
   @include media-query(palm) {
        display: block;
   }
}

.search-contBodyPromoBody:hover {
    text-shadow: 0 0 0.2em #ffffff;
}

.search-bodyPromo {
   width: auto;
   padding: 5px;
   font-family: "fontPromoSearchAgency";
   color: #d2a212;
   display: flex;
   font-size: 100;
   @include media-query(palm) {
        min-width: 100%;
        font-size: 30px;
        text-align: center;
   }
}
.img-visa-responsive{
    float: right;
    width: 40px;
    margin-top: -13px;
}

.search-bodyPromo span {
    padding: 2px;
    background: rgba(66, 66, 66, 0.72);
    margin: 1px;
    display: block;
    width: 17px;
    @include media-query(palm) {
        width: 100%;
    }
}

.search-bntPromo a {
    color: #33cc39;
    display: flex;
    width: 100%;
}

#contDestino {
   width: 45%;
   @include media-query(palm) {
        width: auto;
        font-size: 100%;
   }
}

.search-contPromoBlockMovile {
    display: none;
    @include media-query(palm) {
        display:block;
    }
}

.search-contBockBodyPromo{
    display: block;
    width: 20%;
    @include media-query(palm) {
        width: 100%;
    }
}

.search-contPromoTitleMovile {
    @include media-query(palm) {
        width: 80%;
        margin: 3% 0 5% 10%;
    }
}

.search-contTitleMovile {
    @include media-query(palm) {
         color:rgb(2, 103, 230);
         font-size:70%;
         margin: 0;
    }
}

.col_map{
    width: 80% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.col_filter{
    width: 20% !important;
    background-color:transparent;
    @include media-query(palm) {
        width: 100% !important;
    }
}

#slider-explore{
    width: 100%;
    @include media-query(palm) {
        width: 100% !important;
    }
}

#mapModal{
    margin-right:20% !important;
    left: 350px !important;
    @include media-query(palm) {
        margin-right: 0% !important;
        left: 0px !important;
    }
}

.continent_explore_check {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 10px;
    color: #080400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.continent_explore_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.continent_explore_check:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.continent_explore_check input:checked ~ .checkmark {
    background-color: $color-brand;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.continent_explore_check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.continent_explore_check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search__col_explore_flight_city{
    width: 30% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.search_col_flight_explore{
    width: 25% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.col-flight-explore-button{
    width: 20% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.explore-btn{
    margin-bottom: 10px;
    display:none;
    @include media-query(palm) {
        display:table;
    }
}
.btn-explore{
  border: 1px solid #444 !important;
  color: #444 !important;
}
.btn-explore:hover{
  border: 1px solid #444 !important;
  background: #444 !important;
  color: white !important;
}
.btn-explore:active{
  border: 1px solid #444 !important;
  background: #444 !important;
  color: white !important;
}
.btn-explore:visited{
  border: 1px solid #444 !important;
  background: #444 !important;
  color: white !important;
}
.btn-explore:focus{
  border: 1px solid #444 !important;
  background: #444 !important;
  color: white !important;
}
.info-search-explore {
  margin-top: 10px;
  h5 {
    margin: 5px 0px;
  }
}
.date-1-insure .label-top{
    @include media-query(desk){
        display: none;
    }
}
.date-1-insure .ui-widget-content{
    @include media-query(desk){
        margin-bottom: 15px;
    }
}
.form-modal-newsletter {
    width: 70%;
    background-color: white;
    background: white;
    margin: 5em 1rem 0;
    border-radius: 12px;
    position: relative;
    .boletinForm-butoom {
        width: 70%;
        input {
            padding: 15px;
            border-radius: 16px;
        }
    }
    .form-group-email {
        width: 100%;
        height: auto;
        float: none;
        margin: 0;
    }
    .boletinForm-email,
    .content-policy-disclaimer {
        width: 80%;
        margin: 12px auto 0;
    }
    .boletinForm-email {
        margin-top: 25px;
    }
}
.js-newsletter__btn-Close {
    position: absolute;
    right: 3%;
    top: 3%;
    color: #005eb8;
    font-weight: bold;
    cursor: pointer;
}
.main__newsletter-modal  {
    #js-newsletterRS {
        background: white;
        padding: 25px;
        border-radius: 12px;
    }
}

.color-material-icons-flight {
    color: #ffffff;
    font-size: 1.563rem !important;
    @include media-query(palm) {
        font-size: 1.5rem !important;
        color: #000000;
    }
}

.color-material-icons-flight-avail {
    color: #000000;
    font-size: 1.563rem !important;
    @include media-query(palm) {
        font-size: 1.5rem !important;
        color: #000000;
    }
}

.search__row-main-flight {
    //@include media-query(lap-and-up) {
    @media (min-width: 992px) {
        backdrop-filter: blur(3.5px);
        background-color: rgba(0,0,0,0.3);
        padding: 0.875rem;
        border-radius: 23px;
        min-height: 5rem;
    }
}

.search__row-main-flight-avail {
    //@include media-query(lap-and-up) {
    @media (min-width: 992px) {
        backdrop-filter: blur(3.5px);
        background-color: #ffffff;
        padding: 0.875rem;
        border-radius: 23px;
        min-height: 5rem;
        box-shadow: 0px 0px 10px -5px;
    }
}

.search__row-main-flight_preference {
    padding: 2rem;
    @media (min-width: 992px) {
        backdrop-filter: blur(3.5px);
        background-color: rgba(0,0,0,0.3);
        border-radius: 23px;
        min-height: 5rem;
    }
}

.search__row-main-flight_preference-avail {
    padding: 2rem;
    @media (min-width: 992px) {
        backdrop-filter: blur(3.5px);
        background-color: #ffffff;
        border-radius: 23px;
        min-height: 5rem;
        box-shadow: 0px 0px 10px -5px;
    }
}

.search__row-main-journey {
    //@include media-query(desk) {
    @media (min-width: 992px) {
        padding: 0.875rem;
        border-radius: 23px;
        min-height: 5rem;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(3.5px);
        width: 75%;
    }
    /*
    @include media-query(portable) {
        padding: 0.875rem;
        border-radius: 23px;
        min-height: 5rem;
        background-color: rgba(0,0,0,0.3);
        width: 100%;
    }
    */
    //@include media-query(palm) {
    @media (max-width: 991px) {
        padding: 0.25rem;
        background-color: transparent;
        width: 100%;
    }
}

.search__row-main-journey-avail {
    //@include media-query(desk) {
    @media (min-width: 992px) {
        padding: 0.875rem;
        border-radius: 23px;
        min-height: 6rem;
        background-color: #ffffff;
        backdrop-filter: blur(3.5px);
        box-shadow: 0px 0px 10px -5px;
        width: 75%;
    }
    @media (max-width: 991px) {
        padding: 0.25rem;
        background-color: transparent;
        width: 100%;
    }
}

.padding-journey {
    padding: 12px;
}

.search-title-flight {
    color: #ffffff;
    font-family: Roboto;
    @include media-query(palm) {
        font-size: 2rem;
        float: left;
        margin-right: 12rem;
        margin-left: 1rem;
    }
}

.search-flight-justify-icon-button {
    padding-left: 0px;
    padding-right: 1px;
}

.search__light--box-opaque-palm {
    border-radius: 20px 20px 0 0;
    padding: 0px;
    background-color:transparent;
    @media (min-width: 768px) and (max-width: 991px) {
        padding: 36px;
        background-color:#ffffff;
    }
    //@include media-query (palm) {
    @media (max-width: 767px) {
        padding: 10px;
        background-color:#ffffff;
    }
}

.datepicker-recovery-original-width {
    width: 820px !important;
    @include media-query(lap) {
        width: calc(100% - 20px) !important;
    }
    @include media-query(palm) {
        width: 100%  !important;
    }
}
