/*------------------------------------*\
    #TABLES
\*------------------------------------*/
// Adapted from Bootstrap Tables

table {
  background-color: transparent;
}
th {
  text-align: left;
  font-weight: 600;
}
/*****************TABLAS PARA PAYOUT EXTRAS**********************/

//table into other table
.cell-title-into-1{
    width:80%;
    @include media-query(portable) {
    width:50%;
    }
}
.cell-title-into-1{
    width:30%;
    @include media-query(portable) {
    width:50%;
    }
}

.table-into{
    @include media-query(portable) {
    font-size:60%;
    }
}
// MCP
.font-cancellation{
    @include media-query(portable) {
    font-size:70%;
    }

}
.table-secure-cancell {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $inuit-base-spacing-unit--small;
                line-height: $inuit-base-line-height;
                vertical-align: top;
                border-top: 1px solid $color-table-border;
                @include media-query(portable) {
                    padding:0px;
                    @include span(1 of 3);
                    /*@include strong(1 of 2);*/
                }
            }
        }
    }
    // Bottom align for column headings
    > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $color-table-border;
    }
    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
    // Account for multiple tbody instances
    > tbody + tbody {
        border-top: 2px solid $color-table-border;
    }

    // Nesting
    .table {
        background-color: $color-table-bg;
    }
}
//SEGUROACC
.table-secure {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $inuit-base-spacing-unit--small;
                line-height: $inuit-base-line-height;
                vertical-align: top;
                border-top: 1px solid $color-table-border;
                @include media-query(portable) {
                    padding:0px;
                    @include span(1 of 2);
                    /*@include strong(1 of 2);*/
                }
            }
        }
    }
    // Bottom align for column headings
    > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $color-table-border;
    }
    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
    // Account for multiple tbody instances
    > tbody + tbody {
        border-top: 2px solid $color-table-border;
    }

    // Nesting
    .table {
        background-color: $color-table-bg;
    }
}
/******************************************************/
// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $inuit-base-spacing-unit--small;
        line-height: $inuit-base-line-height;
        vertical-align: top;
        border-top: 1px solid $color-table-border;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $color-table-border;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $color-table-border;
  }

  // Nesting
  .table {
    background-color: $color-table-bg;
  }
}


// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $inuit-base-spacing-unit--tiny;
      }
    }
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid $color-table-border;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $color-table-border;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}


// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}


// table for aligning 2 rows displays
.table--light {
    & .table--light__title {
        vertical-align: top;
    }
    & .table--light__content {
        padding: 0 $inuit-base-spacing-unit--small;
    }
}
