/*------------------------------------*\
  #TYPE
\*------------------------------------*/

/**
 * Typographical base selectors.
 */

/**
 * Headings 1–6.
 */
@include headings() {
    @include font-weight(regular);
    color: $color-headings;
}


/**
 * Typographical options
 */

.font-roboto{
    font-family: 'Roboto', sans-serif;
}

.font-ubuntu{
    font-family: 'Ubuntu', sans-serif;
}



/**
 * A generic heading style which can be applied to any headings.
 */
.heading {
    @include inuit-font-size(22px);
}

.heading-sub {
    @include inuit-font-size(16px);
    @include font-weight(bold);
    margin-bottom: 0;
    color: $color-heading-sub;
}





/**
 * Emboldened elements.
 */
a,
dt {
    @include font-weight(light);
}

strong, b{
    //@include font-weight(bold);
    font-weight: 600;
}
.b-main {
    color: $color-b-main;
}

.b-accent {
    color: $color-b-accent;
}
.text-grey{
  color:#666;
}




/**
 * Code-like elements.
 */
code, kbd, samp {
    color: $color-code;
    font-family: Inconsolata, Monaco, Consolas, "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}

pre {
    white-space: pre;
    word-wrap: normal;
    overflow: auto;
    padding: $inuit-base-spacing-unit--small;
    background-color: $color-pre-background;
    border-radius: $base-round;

    &,
    code, kbd, samp {
        color: $color-pre;
    }

}





/**
 * Figures.
 *
 * 1. Outdent figures by an arbitrary amount at the relevant viewport size.
 * 2. Reindent figcaptions.
 */
figure {

    @media screen and (min-width: 1380px) {
        margin-left: -128px; /* [1] */
    }

    > img {
        border-radius: $base-round;
    }

}

    figcaption {
        @include inuit-font-size(12px);

        @media screen and (min-width: 1380px) {
            margin-left: 128px; /* [2] */
        }

    }





/**
 * Horizontal rules.
 */
hr {
    border: none;
    border-bottom: 1px solid $color-hr;
    margin-bottom: $inuit-base-spacing-unit - 1px;
}





/**
 * Highlighted text.
 */
mark {

    pre & {
        background: none;
        border-bottom: 1px solid;
        color: inherit;
    }

}

.title{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
}

@media only screen and (max-width: 540px) {
    .title-movil{
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        text-align: justify;
        margin-left: 26px
    }
}
.sub-title{
    font-size: 1.2rem;
    color:#2F2F2F;
}

@media only screen and (max-width: 540px){
    .sub-title-movil{
        color: var(--Black-text, #2F2F2F);
        font-family: Ubuntu;
        font-size: 17px!important;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        text-align: justify;
        margin-left: 26px;
        margin-right:100px
    }
}

@media only screen and (max-width: 540px){
    .text-center1 {
        text-align: justify;
    }
}


.prueba-b-main2 {
    color: var(--Secondary-Color, #042A44);
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 137.5% */
}

/* Estilos para dispositivos móviles con una anchura de pantalla de 540px o menos */
@media only screen and (max-width: 540px) {
    .prueba-b-main2 {
        color: var(--Priimary-Color, #042A44);
        font-size: 19px;
        line-height: 44px;
        /* Otras propiedades si es necesario */
    }

}



.prueba-b-main3 {
    color: var(--Secondary-Color, #005CB9);
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 137.5% */
}

/* Estilos para dispositivos móviles con una anchura de pantalla de 540px o menos */
@media only screen and (max-width: 540px) {
    .prueba-b-main3 {
        color: var(--Priimary-Color, #005CB9);
        font-size: 19px;
        line-height: 44px;
        /* Otras propiedades si es necesario */
    }
}

.prueba-b-main4  {
    color: var(--Black-text, #2F2F2F);
    font-family: Ubuntu;
    font-size: 21px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 36px
}

/* Estilos para dispositivos móviles con una anchura de pantalla de 540px o menos */
@media only screen and (max-width: 540px) {
    .prueba-b-main4 {
        color: var(--Black-text, #2F2F2F);
        font-family: Roboto;
        font-size: 14px!important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}