///*------------------------------------*\
//   #DETAIL-CAR
//\*------------------------------------*/

/* VARS */
$open-sans: 'Open Sans', 'Helvetica', sans-serif;

$darkNavy: #213140;
$teal1: #66B3FB;
$teal2: #4B9DEA;
$charcoal: #555555;
$gold: #B6985A;

$activeShadow: 0 0 10px rgba($teal1, .5);

/* MIXINS */
@mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
@mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
@mixin breakpoint($point) {
    @if $point == 1100 {
        @media (max-width: 1100px) { @content ; }
    }
    @else if $point == 800 {
        @media (max-width: 800px) { @content ; }
    }
}

.detail-car__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}


// Detail sidebar //

.detail-car__sidebar {
    @include media-query(desk) {
        // @include span(2 of 6);
		width: calc((2 / 6) * 100%);
    }
}
.detail-car__frame-inner-wrapper {
    font-size: 15px;
    /*    margin-bottom: $inuit-base-spacing-unit;*/
}
.detail-car__title {
    width: 100%;
    margin-bottom: $inuit-base-spacing-unit--small;
    text-align: left;
}
.detail-car__image {
    margin-bottom: $inuit-base-spacing-unit--small;
    max-width: 30%;
    @include media-query(palm) {
        max-width: 100%;
    }
}
.detail-car__image_list {
    margin-bottom: $inuit-base-spacing-unit--small;
    max-width: 100%;
    margin-left: 20%;
    @include media-query(palm) {
        margin-left: 10%;
    }
}

.detail-car__image_list_europ {
    margin-bottom: $inuit-base-spacing-unit--small;
    max-width: 100%;
    @include media-query(palm) {
        margin-left: 10%;
    }
}

.detail-car__image-wrapper {
    text-align: left;
    max-height: 20%
}
.detail-car__characteristics-data span {
    vertical-align: middle;
}
.detail-car__characteristics-data img {
  filter: hue-rotate(30deg);
    width: 16px;
    height: 16px;
}
.detail-car__characteristics {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 3px;
    margin-bottom: 1% !important;
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}
.detail-car__description {
    padding-bottom: $inuit-base-spacing-unit;
}

.detail-car__description__requeriments {
    padding-bottom: $inuit-base-spacing-unit;
    font-size: 13px;
}

.detail-car__description-expanded {
    display: none;
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-car__price {
    color: white;
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.detail-car__price-frame {
    margin-top: -1px;
}
@include media-query(palm) {
    .detail-car__price-frame--mobile {
        border-bottom: 1px solid rgba(255,255,255,.5) !important;
        border-radius: 0 0 5px 5px !important;
    }
}
.detail-car__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.detail-car__label {
    font-size: 12px;
}
.detail-car__rooms-table {
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-car__characteristics-label {
  display: inline-block;
  width: 25%;
  float: left;
  margin-top: 4px;
  font-size: 11px;
  word-break: break-word;
  font-weight: 600;
}
.detail-car__characteristics-info {
  display: inline-block;
  width: 75%;
  font-size: 15px;
  font-weight: 100;
}
.detail-car__logo {
    text-align: left;
}
.detail-car__provider {
    width: 100%;
    text-align: left;
}
.detail-car__destination-title {
    margin-bottom: 5px;
    font-size: 16px;
    @include media-query(lap) {
        text-align: center;
    }
}
.detail-car__itinerary-info {
    margin-bottom: 4px;
    @include media-query(lap) {
        text-align: center;
    }
}
.detail-car__image-map {
    max-width: 640px;
    height: 120px;
    margin: 0 auto;
    border: 2px solid rgba(0,0,0,0);
    border-radius: $base-round;
    cursor: pointer;
    @include attention() {
        border-color: rgba(0,0,0,.1);
    }
}
.detail-car__rate-price {
    width: 50%;
    float: left;
    text-align: left;
}
.detail-car__rate-price-extra {
    width: 100%;
    float: left;
    text-align: center;
}
.detail-car__rate-detail {
    width: 100%;
}
.detail-car__more {
    display: none;
    margin-top: $inuit-base-spacing-unit--small;
}
.detail-car__more-details {
    margin-left: 0;
    margin-bottom: 0;

    li {
        display: flex;
        gap: 10px;
        margin-bottom: 5px;
        align-items: center;
    }
}
.detail-car--submit-price {
    // @include span(1 of 2);
    width: calc((1 / 2) * 100%);
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(lap-and-up) {
        // @include span(1 of 3);
		width: calc((1 / 3) * 100%);
    }
}
.detail-car--submit-btn {
    // @include span(1 of 1);
    width: calc((1 / 1) * 100%);
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(lap-and-up) {
        // @include span(1 of 3);
		width: calc((1 / 3) * 100%);
    }
}

.deleteX{
    box-shadow: 1px 1px 1px 2px;
}

.frame-view{
    padding: 5px 20px;
    background: rgba(0, 155, 248, 0.2);
    border-radius: 0 0 20px 20px;
    cursor: pointer;
    text-align: center;
    width: 60%;
    margin-left: 40%;
    @include media-query(palm) {
        width: 101%;
        margin-left: 0%;
    }
}

.frame-infoCars{
    border-radius: 10px;
}

.detail-car_information {
    margin-left: 0;
    margin-bottom: 10%;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;

    .detail-car__characteristics-data {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.detail-car_information_services{
    padding-bottom: 16px;
    margin-left: 25%;
}

.grid-price{
    width: 40%;
    padding-left: 3%;
    @include media-query(palm) {
        width: 35%;
    }
}

.grid-cars-selected{
    width: 70%;
}
.grid-cars-view{
    width: 30%;
}

.grid-price-resume{
    width: 50% !important;
    @include media-query(palm) {
        width: 100% !important;
    }
}

.hide-element{
    margin-left: 2%;
    @include media-query(palm) {
        margin-left: 5%;
    }
}

.detail-car__option {
    border-radius: $base-round;
    padding-bottom: 1px !important;
    margin-right: $inuit-base-spacing-unit--small;
    &:hover {
        background-color: lighten($color-main, 45%);
    }
    &:active,
        &.active {
        background-color: lighten($color-main, 40%);
    }
}
.detail-car__option-label {
    margin-right: $inuit-base-spacing-unit--small;
    line-height: 1.9em;
}

.detail-car_intinerary-grid {
    width:100%;
}

.selectedCar{
  box-shadow: none;
  background: rgba(68, 68, 68, 0.1);
}

.dispo-car__selected-label{
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 9px;
    border-radius: 0 0 0 5px;
    color: #fff;
    font-weight: 600;
    background-color: #2b7091;
}

.dispo-cars__submit-label{
    font-size: 15px;
}

.dispo-car_ribbon {
    position: absolute;
    right: -4px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.dispo-car_ribbon span {
    font-size: 12px;
    font-weight: 100;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#1e5799 0%, #34bac9 57%);
    position: absolute;
    top: 19px;
    right: -21px;
}
.dispo-car_ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #34bac9;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #34bac9;
}
.dispo-car_ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #34bac9;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #34bac9;
}

/* CONTAINERS */

.detail-car-container {
    max-width: 850px;
    width: 100%;
    margin: 0 3% auto;
    @include media-query(palm) {
        margin: 0 8% auto;
    }
}

.four {
    width: 30%;
    max-width: 30%;
}


/* COLUMNS */

.col-car {
  display: block;
  float:left;
  margin: 1% 0 1% 1.6%;
}

.col-car:first-of-type {
    margin-left: 0;
}

/* CLEARFIX */

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

/* FORM */

.form-car-detail .plan input, .form .payment-plan input, .form .payment-type input{
	display: none;
}

.form-car-detail label{
	position: relative;
	color: #fff;
	background-color: #aaa;
	font-size: 15px;
	text-align: center;
	height: 10px;
	line-height: 15px;
	display: block;
	cursor: pointer;
	border: 3px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
        @include media-query(palm) {
            font-size: 10px;
        }
}

.form-car-detail .plan input:checked + label, .form .payment-plan input:checked + label, .form-car-detail .payment-type input:checked + label{
	border: 1px solid #333;
	background-color: #009bf8;
}

.form-car-detail .plan input:checked + label:after, form .payment-plan input:checked + label:after, .form .payment-type input:checked + label:after{
	content: "\2713";
	width: 20px;
	height: 20px;
	line-height: 15px;
	border-radius: 100%;
	border: 2px solid #333;
	background-color: #225594;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}

.dispo-selectionMultiple{
    font-size: 15px;
    @include media-query(palm) {
        font-size: 10px;
    }
}

.body-page-aviatur-car-detail-specific-secure {
    .page__body {
        background-color: white;
    }

    div[class*="shadow-"] {
        box-shadow: none !important;
        padding: 0;
    }

    .detail-flight__content {
        .frame {
            box-shadow: none !important;
            padding: 0;

            &.js-passanger {
                > div.w-full {
                    margin-bottom: 2rem;
                }
            }
        }

        #DataPax {
            > div {
                div:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    .slidePanel-body {
        margin-top: 3rem;

        .frame {
            box-shadow: 0 4px 4px rgba(108, 128, 201, 0.12);
            border-radius: 16px;
        }
    }

    .container-check {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: max-content;
    }

    /* Hide the browser's default checkbox */
    .container-check input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container-check:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-check input:checked ~ .checkmark {
        background-color: #2196F3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container-check input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container-check .checkmark:after {
        left: 10px;
        top: 7px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    h3.type-main {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 32px;
        color: #222222;
    }
    h4.type-main {
        margin-top: 25px;
        margin-bottom: 35px;
        font-weight: 500;
        line-height: 23px;
        color: #222222;;
    }

    .form-group input[type="text"],
    .form-group input[type="password"],
    .form-group select {
        min-height: 58px;
        border-radius: .375rem;
        background-color: #fcfdff;
        border: 1px solid rgb(204, 204, 204);
        color: rgb(51, 51, 51);
        font-weight: 400;
    }
    .form-group input[type="text"]:focus, .form-group select:focus, .form-group input[type="text"]:hover, .form-group select:hover {
        outline-color: #005CB9;
        outline-style: auto;
        outline-width: 1px;
        color: #005CB9;
    }
    .frame--hero {
        border: none !important;
        background-color: transparent !important;
        display: flex;
        justify-content: center;
    }
    #detailSubmit {
        background-color: #0AC6D1;
        color: rgb(255 255 255/ 1);
        line-height: 16px;
        display: inline-flex;
        align-items: center;
        border-radius: .375rem;
        border-width: 2px;
        padding: .625rem 2.5rem;
        font-size: .875rem;
        font-weight: 500;
        box-shadow: 0 4px 4px rgba(108,128,201,.12);
    }
    #detailSubmit:hover {
        background-color: #27D5DF;
    }
    .frame {
        border-bottom: none !important;
    }
    #js-if-officeid {
        margin: 0;
    }
    label[for="conditions"] {
        margin-top: -75px;
    }
    .row p {
        padding-right: 12px;
        padding-left: 12px;
        line-height: normal;
    }
    span[for="conditions"] {
        line-height: normal;
    }

    .slidePanel-body .detail-car__price-frame {
        color: #222222;
        flex-direction: column;
    }

    .slidePanel-body .detail-car__price, .slidePanel-body .detail-car__provider span, .slidePanel-body .detail-car__destination-title, .detail-flight__content .dispo-cars__submit-label {
        color: #222222;
    }

    .slidePanel-body .detail-car__description__requeriments ul{
        margin-left: 0;
    }

    .detail-shared__dispo-back .btn {
        margin-top: 20px;
    }

    @media (min-width:650px) {
        #panel-detail {
            right: -400px;
        }
        .slidePanel.slidePanel--right {
            right: -400px;
        }
    }
    @media screen and (min-width: 64em){
        #detailForm > .wrapper-susy:first-child {
            display: flex;
            column-gap: 3%;
        }
        .detail-car__description, .detail-car__description__requeriments, .detail-car__price-frame {
            margin-bottom: 40px !important;
            border-radius: 16px;
            padding: 23px 17px;
            box-shadow: 0 4px 4px rgba(108,128,201,.12);
        }
    }
}
