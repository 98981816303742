/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17/11/2017, 01:56:31 PM
    Author     : sergio.amaya
*/

///*------------------------------------*\
//    #DETAIL-EXPERIENCE
//\*------------------------------------*/

.detail-experience__content {
    @include media-query(desk) {
        @include span(4 of 6);
    }
}
.detail-experience__sidebar {
    @include span(2 of 6);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);  
    }
}





.detail-experience__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
.detail-experience__stars {
    height: $inuit-base-spacing-unit;
    vertical-align: baseline;
}
.detail-experience__rating-disclaimer {
    min-width: 300px;
    margin-left: calc($inuit-base-spacing-unit--small/2).0;
}
.detail-experience__label {
    font-size: 12px;
}
.detail-experience__sidebar .detail-experience__price-frame {
    margin-top: -1px;
}
@include media-query(palm) {
    .detail-experience__sidebar .frame:last-of-type:not(:first-of-type) {
        border-radius: 0 !important;
    }
}
.detail-experience__price {
    color: white;
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
@include media-query(palm) {
    .detail-experience__price-frame--mobile {
        border-bottom: 1px solid rgba(255,255,255,.5) !important;
        border-radius: 0 0 5px 5px !important;
    }
}




.detail-experience__carousel {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-bottom: -1px;
        margin-left: -$inuit-base-spacing-unit--small;
        margin-right: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.detail-experience__carousel-disclaimer {
    margin-top: calc(2px - #{$inuit-base-spacing-unit});
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-top: 1px;
        margin-bottom: 3px;
    }
}
.detail-experience__carousel__image {
    background: no-repeat center center;
    height: 260px;
    background-size: cover;
    @include media-query(palm) { 
        height: 220px;
    }
}




.detail-experience__rooms-table {
    margin-bottom: $inuit-base-spacing-unit;
}




.detail-experience__map {
    border-radius: $base-round;
    border: 1px solid rgba(0,0,0,.07);
    height: 300px;
    margin-bottom: $inuit-base-spacing-unit;
    width: 100%;
    @include media-query(palm) {
        height: 200px;
    }
}
.detail-experience__description {
    padding-bottom: $inuit-base-spacing-unit; 
}
.detail-experience__description-expanded {
    display: none;
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-experience__list {
    padding-bottom: $inuit-base-spacing-unit;
}

.detail-multi__discount-policies {
    font-size: .8em;
    font-weight: bold;
}

.js-showConditions h6 {
    font-weight: bold;
    margin-top: 3px;
}





.detail-experience__date {
    text-transform: uppercase;
    padding: $inuit-base-spacing-unit;
    background-color: $color-main;
    color: #fff;
    margin-left: -$inuit-base-spacing-unit;
}

.detail-experience__inline-input {
    width: initial;
    max-width: 2.4em;
    text-align: center;
}

.detail-experience__option {
    border-radius: $base-round;
    padding-bottom: 1px !important;
    margin-right: $inuit-base-spacing-unit--small;
    &:hover {
        background-color: lighten($color-main, 45%);
    }
    &:active,
    &.active {
        background-color: lighten($color-main, 40%);
    }
}
.detail-experience__option-label {
    margin-right: $inuit-base-spacing-unit--small;
    line-height: 1.9em;
}

.detail-experience__number-control {
    user-select: none;
    &:not(.active) {
        color: darken(lighten($color-main, 50%), 10%);
        cursor: default;
    }
}

.detail-experience__passenger-selection {
    min-height: 2em;
    & .search__btn-increment > button {
        padding: calc($inuit-base-spacing-unit--small / 2);
    }
    & .search__btn-increment > button:last-of-type {
        margin-left: 0;
    }
    & .list-radio--big {
        margin-right: 2.75em;
    }
    @include media-query(palm) {
        margin-top: $inuit-base-spacing-unit--small;
    }
}


.detail-experience__hidden-date {
    position: absolute;
    width: 100%;
    right: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;
    opacity: 0;
}