/*------------------------------------*\
    #DISPO-BUS
\*------------------------------------*/

$maxBusImgWidth: 220px;

.dispo-bus__col-image {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap-and-up) {
        @include span(1 of 4);
        max-width: $maxBusImgWidth + $inuit-base-spacing-unit;
    }
}

.dispo-bus__col-image__no-img {
    display: block;
    padding: $inuit-base-spacing-unit*2 0;
    background-color: $gray-plain-light;
}

.dispo-bus__col-details {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap-and-up) {
        @include span(2 of 4);
    }
}

.dispo-bus__col-details__container {
    @include span(1 of 2);
}

.dispo-bus__col-details__schedule {
    border-bottom: 1px solid $color-frame-border;
}

.dispo-bus__col-details__info {
    font-size: 26px;
    padding: 5px;
    border-radius: 30px;
    background-color: #81c1ff;
    color: #005eb8 !important;
    font-size: 1rem;
}

.dispo-bus__col-price {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(palm) {
        margin-top: $inuit-base-spacing-unit;
        text-align: center;
    }
    @include media-query(lap-and-up) {
        @include span(1 of 4);
    }
}

.dispo-bus__col-price__price {
    font-size: 26px;
    margin: calc($inuit-base-spacing-unit--small/2) 0;
}

.dispo-bus__trip-selection {
    border-left: 5px solid $color-main;
}

.dispo-bus__trip-selection__col {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap-and-up) {
        @include span(1 of 4);
    }
}

.bus-services__img {
    width: $inuit-base-spacing-unit;
}
