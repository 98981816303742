///*------------------------------------*\
//    #PAGE-HEAD
//\*------------------------------------*/

// Internal variables.
$logo-height: 25px; // 140px
$mobile-navbar-height: $logo-height + (2*$inuit-base-spacing-unit);
$mobile-menu-img-height: 170px;

.page-head,
%page-head {
    background-color: rgba(0, 0, 0, 0.50);
    position: relative;
    margin-bottom: 0px;
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.06);
    //@include material-shadow(1);
}


%page-head-bg-container {
    position: absolute;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

.page-head__bg-container {
    @extend %page-head-bg-container;
    height: 100%;

    @include media-query(palm) {
        background-image: url('../img/header/covers/snapshots/cover-day.jpg?');
        background-size: cover;
    }
}

.page-head__bg-container--responsive {
    @extend %page-head-bg-container;
    height: $mobile-navbar-height;
    background-color: $color-main;
    @include material-shadow(2);
}

.page-head__bg__img {
    width: 100%;
    height: 100%;
    background: no-repeat center center;
    background-size: cover;

    &.bg-img_morning {
        background-image: url(../img/header/covers/snapshots/cover_morning.jpg);
    }

    &.bg-img_day {
        background-image: url(../img/header/covers/snapshots/cover_day.jpg);
    }

    &.bg-img_evening {
        background-image: url(../img/header/covers/snapshots/cover_evening.jpg);
    }

    &.bg-img_sunset {
        background-image: url(../img/header/covers/snapshots/cover_sunset.jpg);
    }

    &.bg-img_night {
        background-image: url(../img/header/covers/snapshots/cover_night.jpg);
    }

    &.bg-img_odv {
        background-image: url(../img/header/covers/snapshots/cover_odv.jpg);
    }

    &.bg-img_odv2 {
        background-image: url(../img/header/covers/snapshots/cover_odv2.jpg);
    }

    &.bg-img_outlet {
        background-image: url(../img/header/covers/snapshots/cover_outlet.jpg);
    }

    &.bg-img_islashotel {
        background-image: url(../img/header/covers/snapshots/cover_islashotel.jpg);
    }

    &.bg-img_orgullo {
        background-image: url(../img/header/covers/snapshots/cover_orgullo.jpg);
    }

    &.bg-img_black_friday {
        background-image: url(../img/header/covers/snapshots/black_friday.jpg);
    }

    &.bg-img_background_default {
        background: white;
    }

    //&.bg-img_background_default     { background-image: url(../img/header/covers/snapshots/background_default.jpg); }
    &.bg-img_background_special {
        background-image: url(../img/header/covers/snapshots/background_special.jpg);
    }

    // &.bg-img_christmas  { background-image: url(../img/header/covers/snapshots/cover_christmas.jpg); }
    &.bg-img_blog {
        background-image: url(/source/contenidos/blog/blog-de-viajes-aviatur-los-ojos-del-viajero.jpg);
    }

    .page-head__bg__video.js-hidden~&:after,
    .page-head__bg-container>&:first-child:after {
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        content: ' ';
    }
}

.page-head__bg__video {
    position: absolute;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-query(palm) {
        height: 100vw;
        top: 10%;
        min-width: 180%;
    }

    &.bg-vid_morning {
        top: 75%;
        left: 50%;
        transform: translate(-50%, -75%);
    }

    &.bg-vid_day {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.bg-vid_evening {
        top: 90%;
        left: 50%;
        transform: translate(-50%, -90%);
    }

    &.bg-vid_sunset {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.bg-vid_night {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.bg-vid_odv {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.bg-vid_odv2 {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


.page-head--responsive {
    background-color: white;
    height: 7.5rem;

    @include media-query(palm) {
        background-color: transparent;
        margin-left: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }

    @include media-query(lap) {
        background-color: transparent;
    }
}

.page-head__container-mobile {
    margin: 1.25rem 0;

    @include media-query(palm) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: $z-index-slidepanel;
    }
}

.page-head__menu-img-mobile {
    height: $mobile-menu-img-height;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/aviatur_assets/img/header/menu-img-mobile.jpg);

    @media screen and (min-width:450px) and (max-width:721px) {
        height: 270px;
    }
}

.page-head__mobile-offset {
    width: 100%;
    height: $mobile-navbar-height;
}

.page-head__menu-mobile {
    // @include span(1 of 6);
    width: calc((1 / 6) * 100%);
}

.page-head__menu-mobile img {
    max-height: $logo-height;
    margin-left: $inuit-base-spacing-unit--small;
}


.page-head__logo {
    float: left;

    @include media-query(palm) {
        width: 100%;
        position: absolute;
        z-index: -1; // seat beneath menu icon
        margin-top: 5px;
    }

    @include media-query(lap) {
        margin-left: $inuit-base-spacing-unit;
    }

    @include media-query(lap-and-up) {
        margin-top: 5px;
    }
}

.page-head__aviatur {
    width: 150px;
    // max-height: $logo-height;
    @include media-query(lap-and-up) {
        float: left;
        padding-right: 5px;
    }

    @include media-query(lap) {
        float: none;
    }
}

.page-head-edit-background {
    position: absolute;
    top: 4px;
    width: auto;
    padding: 9px 9px;
    margin: 1px 0 0 5px;
    border: 2px solid #00353e;
    border-top-width: 0;
    color: #fff;
    background-color: #003945;
    font-weight: 600;
    font-size: 90%;

    a {
        color: #fff;
    }
}

/*.page-head__aviatur-pdf {
    max-height: 75px;
    padding: 5px 0;
}
.page-head__award {
    float: right;
    height: 100px;
    margin-left: 60px;
    @media screen and (max-width: 1100px) {
        display: none;
    }
}*/

/*.page-head__contact_container {
    margin-top: 10px;
}
.page-head__contact {
    position: relative;
    margin-left: 15px;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
    @include media-query(desk) {
        float: right;
    }
}
.page-head__nbr-call {
    float: right;
    margin-left: 10px;
    color: #fff;
    &.link-span {
        @include link-quiet($color-links-quiet);
    }
}*/
/*.page-head__icon-call {
    height: halve($logo-height);
    top: 15px;
    left: -(halve($logo-height) + 10px);
    position: absolute;
}*/
// PROMO SEMANA AZUL

.page-head__bg-container>.page-head__bg__img:first-child:after,
.page-head__bg__video.js-hidden~.page-head__bg__img:after {
    background-color: rgba(0, 0, 0, .0);
}

.whatsapp-header {
    width: 15px;
    margin-right: 7px;
}

.contac-whatsapp {
    float: right;
}

.page-head__nbr-call {
    margin-right: 10px;
    padding-right: 10px;
    color: #444;
    border-right: 1px solid #CCC;
    display: initial;
    padding-bottom: 7px;
}

.page-head__contact {
    @include media-query (desk) {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        padding: 10px 15px;
        background: #EEE;
        border-radius: 0px 0px 10px 10px;
    }

    @media screen and (min-width:1024px) and (max-width:1180px) {
        margin-right: 3%;
    }

    .page-head__nbr-call {
        border: 0;
    }
}

.list-bare {
    width: 100%;
    float: left;
}

#nav-mail:hover ul li {
    display: block !important;
}

#nav-mail:hover ul {
    display: grid !important;
}

#nav-mail {
    @include media-query (palm) {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 25%;
        background-color: white;
        z-index: 100;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        transition: opacity 1s;

        .content-nav-profile {
            padding-top: 47px;

            #js-customerEdit {
                position: fixed;
                left: 28px;
                font-size: 18px;
                color: #222222;
                font-weight: bold;
            }
        }
    }
}

#nav-mail.active-profile {
    visibility: visible;
    opacity: 1;
}

#nav-sub {
    display: none;
    margin-left: 0px;
    width: 200px;
    position: absolute;
    background: #ffffff;
    z-index: 10;
    box-shadow: 0px 1px 4px 1px rgba(65, 65, 65, 0.47843137254901963);
    top: 4rem;
    height: auto;
    border-radius: 4px;

    @include media-query (palm) {
        display: inline-grid;
        margin-left: 0px;
        width: 100%;
        position: relative;
        box-shadow: none;
        padding-left: 20px;
        margin-top: 22px;
        list-style: none;

        #js-customerLogout {
            i {
                margin: -4px 0 0 5px;
                float: none !important;
            }
        }
    }
}

#nav-sub li {
    height: auto;
    padding: 12px;
}

#nav-sub li:hover {
    background-color: #eee;
}

#nav-sub li a {
    font-weight: normal;
}

@include media-query (palm) {
    .border-mobile {
        border-bottom: 1px solid #E4E4E4;
        margin-bottom: 8px;
    }

    .content-panel__profile {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
}

#nav-container-main__second {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    .btn-group {
        width: 35rem;

        .nav-main__second-img {
            color: #042A44;
            font-size: 0.8125rem;
            font-weight: 500;
        }
    }
}

.nav-main__second {

    &_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000080;
        display: none;
        z-index: 15;
    }

    &_burger-menu {
        display: none;
        flex-direction: column;
        position: fixed;
        //top: 2.188rem;
        top: 0rem;
        right: 0rem;
        //width: 50%;
        width: 83.33333%;
        min-width: 303px;
        background-color: #fff;
        height: 100vh;
        z-index: 20;

        &_title {
            color: var(--Black-text, #2F2F2F);
            font-family: Roboto;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &_menu {
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;
            flex-direction: column;
            flex-wrap: nowrap;

            &_item {
                margin: 0;
                text-align: center;
                padding: 15px;
                border-bottom: 1px solid #D1D1D1;
                ;
                color: #000;

                a {
                    text-decoration: none;
                    color: var(--Gray-Label-Active, #5A5A5A);
                    font-family: Ubuntu;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                }

                &_help {
                    color: var(--Gray-Label-Active, #5A5A5A);
                    font-family: Ubuntu;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        &_copyright {
            color: var(--Secondary-Color, #042A44);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}