///*------------------------------------*\
//    #RESUME-CAR
//\*------------------------------------*/

.resume-car__description {
    padding: 0 $inuit-base-spacing-unit--small;
}

.resume-car .detail-car__image-wrapper {
    margin-top: $inuit-base-line-height*2+10px;
}
.resume-car .detail-car__image {
    width: initial;
}
.resume-car .detail-car__destination-first {
    margin-top: $inuit-base-line-height*2+10px;
}

.body-page-aviatur-car-payment-rejected-secure {
    .page__body {
        background: #ffffff;

        .type-main {
            color: #222222;
        }

        .resume__col {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            &.resume-purchase {
                margin-bottom: 2rem;
                flex-direction: column;

                .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;

                    form {
                        margin-bottom: 0;
                    }
                }
            }

            .frame-group {
                width: calc(50% - 1rem);

                @media (max-width:650px) {
                    width: 100%;
                }

                box-shadow: 0 4px 4px rgba(108, 128, 201, 0.12);
                border-radius: 16px;

                .frame-group {
                    box-shadow: none;
                    border-radius: 0;
                    border: none;
                    width: 100%;
                    padding: 10px 20px;

                    .detail-car__frame-inner-wrapper {
                        h3 {
                            font-weight: bold;
                            color: #222222;
                            font-size: 1em;
                        }
                    }
                }

                &.car-info {
                    width: calc(100% - 1rem);
                }

                &.product-payments {
                    .resume__row {
                        margin: 20px 20px;
                    }
                }

                .frame--hero {
                    background-color: white !important;
                    border: none !important;
                    color: #222222;
                }

                .frame {
                    border-bottom: none !important;
                    box-shadow: none;

                    .wrapper-susy {
                        margin-right: 0;
                        margin-left: 0;

                        .resume__row {
                            margin: 0 0 20px;
                        }

                        .row {
                            justify-content: flex-start;

                            .resume__row-title {
                                margin-right: 1rem;
                            }
                        }
                    }
                }

                .resume__row {
                    margin: 20px 5px;

                    .resume__row-title {
                        margin-right: 1rem;
                    }

                    .resume__row-content {
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                    }
                }
            }
        }
    }
}
