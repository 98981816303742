///*------------------------------------*\
//    #SEARCH GIFT VOUCHER
//\*------------------------------------*/

.giftVoucher--header { 
    width: 100%; 
    float: left; 
}
.giftVoucher--header .logo { 
    float: left;
    width: 400px ;
}
.giftVoucher--header .nav { 
    float: left; 
    width: 624px; 
}
.giftVoucher--header .nav ul { 
    list-style: none; 
    font-size: 20px; 
    margin: 0 0 0 0;  
}
.giftVoucher--header .nav ul li { 
    float: left; 
    margin: 0; 
    padding: 0 15px;  
    border-right: solid 1px #5CBFD0; 
}
.giftVoucher--header .nav ul li.no {
    border-right: 0; 
}
.giftVoucher--header .nav ul li a {
    color: #4f4f4f; 
    text-decoration: none;
}
.giftVoucher--header .nav table {
    margin-left: 219px;
}

.giftVoucher--slider { 
    width: 100%; 
    float: left; 
}
.giftVoucher--slider .slidesjs-navigation { 
    display: none; 
}
.giftVoucher--slider .slidesjs-pagination { 
    list-style: none outside none; 
    margin: 6px auto; 
    width: 140px; 
}
.giftVoucher--slider .slidesjs-pagination li { 
    float: left; 
    margin: 0 1px; 
}
.giftVoucher--slider .slidesjs-pagination li a { 
    background-image: url(/assets/aviatur_assets/img/gift/pagination.png); 
    background-position: 0 0; 
    display: block; 
    float: left; 
    height: 0; 
    overflow: hidden; 
    padding-top: 13px;
    width: 13px;
}
.giftVoucher--slider .slidesjs-pagination li a.active, .slidesjs-pagination li a.active:hover { 
    background-position: 0 -13px; 
}
.giftVoucher--slider .slidesjs-pagination li a:hover { 
    background-position: 0 -26px; 
}
.giftVoucher--slider .slider a:link, #slides a:visited { 
    color: #333333; 
}
.giftVoucher--slider .slider a:hover, #slides a:active { 
    color: #9E2020; 
}

.giftVoucher--content { 
    width: 100%; 
    margin: 20px 0 0; 
    float: left; 
}
.giftVoucher--content .items{ 
    background: url(/assets/aviatur_assets/img/gift/bgContenido1.jpg) no-repeat; 
    width: 50%; 
    height: 205px; 
    padding: 15px 30px; 
    float: left; 
}
.giftVoucher--content .items .titulo{ 
    float: left; 
    font-size: 27px; 
    color: #fff; 
    text-transform: uppercase; 
    padding: 35px 15px; 
    text-align: right;  
    background: url(/assets/aviatur_assets/img/gift/division2.jpg) no-repeat right center;
}
.giftVoucher--content .items .titulo2{ 
    padding: 55px 15px; 
}
.giftVoucher--content .items .texto { 
    float: left; 
    font-size: 15px; 
    color: #fff; 
    padding: 20px 15px; 
    width: 260px; 
    text-align: justify;
}
.giftVoucher--content .items .texto  ol{ 
    margin: 0 0 0 10px; 
    padding: 0;
}
.giftVoucher--content .items .texto2 { 
    padding: 10px 15px; 
}
.giftVoucher--content .items2{ 
    background: url(/assets/aviatur_assets/img/gift/bgContenido.jpg) no-repeat; 
    width: 50%; 
    height: 205px; 
    padding: 15px 30px; 
    float: left; 
}
.giftVoucher--content .items2 .titulo{ 
    float: left; 
    font-size: 27px; 
    color: #fff; 
    text-transform: uppercase; 
    padding: 35px 15px; 
    text-align: right;  
    background: url(/assets/aviatur_assets/img/gift/division.jpg) no-repeat right center;
}
.giftVoucher--content .items2 .titulo2{ 
    padding: 55px 15px; 
}
.giftVoucher--content .items2 .texto { 
    float: left; 
    font-size: 17px; 
    color: #fff; 
    padding: 20px 15px; 
    width: 260px; 
    text-align: justify;
}
.giftVoucher--content .items2 .texto2 { 
    padding: 10px 15px; 
}

.giftVoucher--form .bonos{ 
    background: url(/assets/aviatur_assets/img/gift/bgBonos.jpg) no-repeat scroll right top; 
    width: 1024px; 
    height: 344px; 
    float: left; 
    position: relative; 
    margin: 20px 0 0; 
}
.giftVoucher--form .bonos input[type="text"]{  
    position: absolute; 
    left: 545px; 
    top: 90px; 
    width: 250px; 
    font-size: 19px; 
    text-transform: uppercase; 
    color: #bebebe; 
    border: 0; 
    padding: 3px; 
    text-align: center;  
}
.giftVoucher--form .bonos label{
    position: absolute; 
    left: 545px; 
    top: 70px; 
    color: white;
}
.giftVoucher--form .bonos input[type="submit"]{  
    position: absolute; 
    left: 510px; 
    top: 140px; 
    width: 170px; 
    height: 40px; 
    cursor: pointer; 
    background: transparent; 
    border: 0; 
    font-size: 0 
}
.giftVoucher--form .bonos input[type="submit"].bono300{  
    left: 45px; 
    top: 60px; 
    width: 175px; 
    height: 120px; 
    background: url(/assets/aviatur_assets/img/gift/bonos.jpg) no-repeat -45px -60px; 
}
.giftVoucher--form .bonos input[type="submit"].bono500{  
    left: 230px; 
    top: 60px; 
    width: 175px; 
    height: 120px; 
    background: url(/assets/aviatur_assets/img/gift/bonos.jpg) no-repeat -230px -60px; 
}
.giftVoucher--form .bonos input[type="submit"].bono200{  
    left: 47px; 
    top: 185px; 
    width: 175px; 
    height: 120px; 
    background: url(/assets/aviatur_assets/img/gift/bonos.jpg) no-repeat -47px -183px; 
}
.giftVoucher--form .bonos input[type="submit"].bono100{  
    left: 230px; 
    top: 185px; 
    width: 175px; 
    height: 120px; 
    background: url(/assets/aviatur_assets/img/gift/bonos.jpg) no-repeat -230px -183px; 
}
