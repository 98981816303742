///*------------------------------------*\
//    #PROMO-HEADER
//\*------------------------------------*/


// LUNA LLENA HEADER //---------------------------------------------------------
/*.luna {
    background: url(/assets/common_assets/img/promo/luna-llena/header/sky.jpg) no-repeat center center;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: $z-index-luna;

}
.luna-cloud {
    position: absolute;
    width: 200%;
}
.luna-cloudUnder {
    background-color:#A1A6B9;
    bottom: 0;
    height: 25px;
    position: absolute;
    width: 100%;
}
.luna-cloud--moon {
    animation: scaleout100pc 200s infinite linear;
    background-size: contain;
    background:url(/assets/common_assets/img/promo/luna-llena/header/moon-0.png) no-repeat;
    left: 80%;
    bottom: 135px;
    height: 150px;
    width: 100%;
    opacity: .90;
    @include media-query(lap) {
        background-size: 150px;
        width: 150px;
        bottom: 100px;
    }
    @include media-query(palm) {
        background-size: 100px;
        bottom: 0;
        height: 100px;
    }
}
.luna-cloud--1 {
    animation: scaleout300 30s infinite ease-in-out;
    background:url(/assets/common_assets/img/promo/luna-llena/header/fondofinal1.png) repeat-x;
    bottom: 25px;
    height: 170px;
    opacity: .95;
    @include media-query(lap) {
        bottom: 5px;
    }
    @include media-query(palm) {
        bottom: -80px;
    }
}
.luna-cloud--2 {
    animation: scaleout367 20s infinite ease-in-out;
    background:url(/assets/common_assets/img/promo/luna-llena/header/fondofinal2.png) repeat-x;
    bottom: 25px;
    height: 120px;
    opacity: .9;
    @include media-query(lap) {
        bottom: 10px;
    }
    @include media-query(palm) {
        bottom: -60px;
    }
}
.luna-cloud--3 {
    animation: scaleout300 15s infinite ease-in-out;
    background:url(/assets/common_assets/img/promo/luna-llena/header/fondofinal3.png) repeat-x;
    bottom: 25px;
    height: 100px;
    opacity: .9;
    @include media-query(lap) {
        bottom: 0;
    }
    @include media-query(palm) {
        bottom: -50px;
    }
}*/



/*.header-halloween{
    background-image: url(/assets/aviatur_assets/img/header/animacion_cabezote_halloween.gif);
    @include media-query(portable) {
        background: transparent;
    }
}*/
/*.nav-main__item_halloween {
    background-color: #000;
    border: 1px solid #FF8400;
    border-radius: double($brand-round);
    color: #FF8400;
    padding: 15px 5px;
    position: relative;
    margin-bottom: $inuit-base-spacing-unit;
    text-align: center;
    top: 0;
    box-shadow: (inset 0 -30px 40px -10px rgba(0, 0, 0, .15), 0 0 0 0 rgba(0,0,0,0));
    transition: all 100ms linear;
    @include media-query(portable) {
        background-color: rgba(0, 0, 0, .5);
        border: 2px solid white;
        padding: 15px 0;
    }
    @include attention() {
        position: relative;
        top: -8px;
        box-shadow: (inset 0 -30px 40px -10px rgba(0, 0, 0, .15), 0 34px 50px -34px rgba(0,0,0,.75));
    }
}*/
/*.nav-main--promo .nav-main__item {
    background: none;
    border: 1px solid $color-nav-main-item--promo;
    @include span(1 of 7);
}*/
/*.nav-main__item--luna {
    background-color: transparent;
    border: 1px solid white;
}*/



/*.page-head__aviatur_halloween {
    display: none;
    max-height: $logo-height;
    padding: ($inuit-base-spacing-unit + 5px) 0;
    @include media-query(portable) {
        display: block;
        content: url(../img/header/aviatur_logo_white.svg);
    }
}*/

/*%page-head--promo {
    @extend %page-head;
    a:not([class^=ui]), ul, span.link-span, label:not(.icon), label span, .search__title * {
        color: $color-page-head-promo !important;
    }
    .search__light--box {
        @include media-query(palm) {
            background-color: rgba(0,0,0,.3);
        }
    }
    .nav-main__item {
        @include media-query(palm) {
            .nav-main__title {
                color: $color-page-head-promo;
            }
            .nav-main__icon--flight {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-flight.svg);
            }
            .nav-main__icon--flighthotel {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-flightHotel.svg);
            }
            .nav-main__icon--hotel {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-hotel.svg);
            }
            .nav-main__icon--car {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-car.svg);
            }
            .nav-main__icon--package {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-package.svg);
            }
            .nav-main__icon--cruise {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-cruise.svg);
            }
            .nav-main__icon--corporate {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-corporate.svg);
            }
            .nav-main__icon--whatsapp {
                background-image: url(/assets/aviatur_assets/img/navbar/icon-contact.svg);
            }
        }
    }
}
.page-head--promo--luna {
    @extend %page-head--promo;
    position: relative;
    background: none;
}
.page-head--promo--black-friday {
    @extend %page-head--promo;
    & .page-head__img-container {
        background-image: url(../img/header/fondo_black.jpg);
    }
    // allumeeez le feu !
    .nav-main__item:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        opacity: .3;
        border-radius: $base-round - 1px;
        background: #000 url(/assets/aviatur_assets/img/promo/black_friday_fire.gif) repeat-x bottom center;
    }
}*/

// .page-head--promo--christmas {
//     @extend .page-head;
//     & .page-head__logo-wrapper:after {
//         content: '';
//         position: absolute;
//         top: -9px;
//         left: -4px;
//         width: 32px;
//         height: 32px;
//         background-image: url(/assets/aviatur_assets/img/header/christmas-hat.svg);
//         background-size: contain;
//         background-repeat: no-repeat;
//     }
// }

.page-head--odv,
.page-head--outlet, .page-head--blog, .page-head--view_blog {
    @extend .page-head;
    & .search__light {
        @include media-query(lap) {
            padding-bottom: 0;
            padding-top: 264px;
            padding-top: 30vh;
        }
        @include media-query(desk) {
            padding-bottom: 0;
            padding-top: 320px;
            padding-top: 44vh;
        }
    }
}
