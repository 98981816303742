///*------------------------------------*\
//    #NAV-FOOT
//\*------------------------------------*/


.nav-foot__download {
    @include span(2 of 10);
    @include media-query(palm) {
        @include span(1 of 3);
    }
}
.nav-foot__compagny {
    @include span(1 of 9);
    @include media-query(lap) {
        @include span(1 of 6);
    }
    @include media-query(palm) {
        @include span(1 of 5);
    }
}
.nav-foot-dwl {
    /*background-image: url(/assets/common_assets/img/promo/download/download.jpg);*/
    background-image: url('https://aviaturcdndev.z5.web.core.windows.net/assets/common_assets/img/custom/footer-banner.png');

    background-repeat: no-repeat;
    background-size: 300%;
    background-position: 0 0;
    padding-top: 50%;
    transition: all .3s ease;
}
.nav-foot-dwl-black-friday{
    background-image: url(/assets/aviatur_assets/img/promo/download/download_black_friday.jpg);
}
.nav-foot-dwl:hover{
    /*background-image: url(/assets/common_assets/img/promo/download/download_hover.jpg);*/
    background-image: url(/assets/aviatur_assets/img/custom/footer-banner-hover.png);
}
.nav-foot-dwl-black-friday:hover{
    background-image: url(/assets/aviatur_assets/img/promo/download/download_black_friday_hover.jpg);
}
.nav-foot-dwl-claro {
    background-image: url('https://aviaturcdndev.z5.web.core.windows.net/assets/common_assets/img/promo/download/download_claro.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 50%;
    border-radius: 5px;
    transition: all .3s ease;
}
.nav-foot-dwl-claro:hover{
    background-image: url(/assets/common_assets/img/promo/download/download_claro_hover.jpg);
}
.nav-foot-comp{
    background-image: url('https://aviaturcdndev.z5.web.core.windows.net/assets/common_assets/img/promo/compagnies/compagny.jpg');
    background-repeat: no-repeat;
    background-size: 900%;
    background-position: 0 0;
    padding-top: 71%;
    transition: all .3s ease;
}
.nav-foot-comp-black-friday{
    background-image: url(/assets/aviatur_assets/img/promo/compagnies/compagny_black_friday.jpg);
}
.nav-foot-comp:hover{
    background-image: url(/assets/aviatur_assets/img/promo/compagnies/compagny_hover.jpg);
}
