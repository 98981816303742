///*------------------------------------*\
//    #PAYOUT-EXTRAS/ADDITIONALS
//\*------------------------------------*/

.tc-saved {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 10px;
}
.tc-saved .container-tc-saved {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr 0.6fr;
    grid-template-rows: 40px;
    gap: 0px 5px;
    grid-auto-flow: row;
    grid-template-areas:
        "a1 a2 a3 a4 a5";
    border-bottom: 1px solid #E4E4E4;
}

.tc-saved .container-tc-saved:last-child {
    border-bottom: none;
}

.tc-saved .container-tc-saved:last-child span, .tc-saved .container-tc-saved:last-child .a2 label {
    color: #2477CA;
    font-size: 14px;
}

.tc-saved .container-tc-saved .cuotas {
    background: #F1F2F6;
    border: none;
    border-radius: 5px;
    color: #A1A9C3;
    padding: 6px;
    text-align: center;
    width: 6vw;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
}

.tc-saved .container-tc-saved .a1, .tc-saved .container-tc-saved .a2, .tc-saved .container-tc-saved .a3, .tc-saved .container-tc-saved .a4 {
    padding: 4px;
}

.tc-saved .container-tc-saved .a1, .tc-saved .container-tc-saved .a2, .tc-saved .container-tc-saved .a3, .tc-saved .container-tc-saved .a4, .tc-saved .container-tc-saved .a5 {
    align-self: center;
    justify-self: center;
}

.js-delete-card:hover {
    background-color: red;
    -webkit-transition: background 0.5s 0s ease-in-out;
    -moz-transition: background 0.5s 0s ease-in-out;
    -o-transition: background 0.5s 0s ease-in-out;
    transition: background 0.5s 0s ease-in-out;
}

.js-delete-card span {
    color: #DE0108;
}

.js-delete-card:hover span {
    color: #fff;
}

.tc-saved .container-tc-saved .a1 { grid-area: a1; }
.tc-saved .container-tc-saved .a2 { grid-area: a2;justify-self: flex-start; }
.tc-saved .container-tc-saved .a2 label { font-size: 14px;line-height: 18px;text-align: left;color: #323232;font-weight: bold; }
.tc-saved .container-tc-saved .a3 { grid-area: a3; }
.tc-saved .container-tc-saved .a4 { grid-area: a4; }
.tc-saved .container-tc-saved .a5 { grid-area: a5;width: -webkit-fill-available;align-self: inherit;text-align: center;cursor:pointer;}
.tc-saved .container-tc-saved .a5 span { transform: translate(0%,75%);font-size: 16px !important; }

.paymentsCustomer__container {
    display: table;
    position: relative;
    width: 100%;
    min-height: 50px;
    padding: 15px 20px;
    border: 1px solid #eee;
    /*border-bottom-color: transparent;*/
    table-layout: fixed;
    border-bottom: 1px solid #E4E4E4;
    @include media-query(palm) {
        width: calc(100% + #{$inuit-base-spacing-unit*2});
        margin-left: -$inuit-base-spacing-unit;
        margin-right: -$inuit-base-spacing-unit;
    }

    & > div {
        display: table-cell;
        vertical-align: middle;
    } 
}

.js-cardscustomer-list {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 10px;
}

.add-margin-bottom{
    padding-bottom: 15px;
}

.add-margin-top{
    padding-top: 15px;
}
.destacable-info-request{
    font-size: 17px;
}

.card-item-info{
    width: 60%;
}

.img-franchise{
    margin-left: 15px;
    margin-right: 18px;
    float: left;
}

.text-card-number{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #323232;
}

.no-option-label{
    margin-left: 15px;
    font-size: 14px;
}

.select-card-button{
    margin-left: 18px;
}