/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
[role="button"],
[type="button"],
[type="submit"] {
    outline: none;
}

.btn {
    transition: .2s;
    @include font-weight(light);
    @include btn-color($color-btn);
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: white;
    outline: none;
    padding: 5px 10px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.btn-passengers {
    transition: .2s;
    @include font-weight(light);
    @include btn-color($color-btn);
    box-shadow: 0 0 2px rgba(0,0,0,.1),0 2px 4px rgba(0,0,0,.1);
    color: white;
    border-radius: 0;
    outline: none;
    padding: 10px 20px;
    border-radius: 50px;
    border: none;
}

// Styling btn-group
.btn-group {
    border-radius: $base-round;
    min-width: 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    text-align: center;
    align-items: center;
    gap: 11px;
    & > .btn {
        float:left;
        position: relative;
        border-color: transparent;
        box-shadow: none;
        background-color: transparent !important;
        @include attention() {
            z-index: $z-index-btn-group__btn-hover;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0);
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
        &:not(.btn--inactive) {
          //color: $color-brand;
          color: #ffffff;
          font-family: 'Ubuntu';
          box-shadow:none;
          padding: 10px;
          background: transparent;
          //@include media-query(palm) {
          @media (max-width: 991px) {
            color: #000000;
          }
          //border: 1px solid $color-brand;
        }
    }
    & > .btn-avail {
        float:left;
        position: relative;
        border-color: transparent;
        box-shadow: none;
        @include attention() {
            z-index: $z-index-btn-group__btn-hover;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0);
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
        &:not(.btn--inactive) {
          color: #000000;
          font-family: 'Ubuntu';
          box-shadow:none;
          background: transparent;
        }
    }
}


.btn--secondary {
    background: none;
    color: $color-btn;
    @include attention() {
        background: none;
        color: darken($color-btn, 10%);
    }
    @include attention(active) {
        background: $color-btn;
        color: white;
    }
}
.btn--toggle {
    @include btn-color($color-btn);
}
.btn--accent {
    @include btn-color($color-btn-accent);
}
.btn--slim {
    @extend .btn;
    @extend .btn--small;
    font-size: .8em;
    font-weight: normal;
}
.btn-flight-blog {
    @extend .btn;
    @extend .btn--small;
    padding: 8px 7px;
}
.btn--positive {
    @include btn-color($color-btn-positive);
}
.btn--negative {
    @include btn-color($color-btn-negative);
}
.btn--submit {
    @include font-weight(bold);
    @include btn-color(#00c382);
    @include media-query(palm) {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 0.938rem;
        padding: 15px 40px;
        width: fit-content;
    }
    
}
.btn--inactive {
    @include btn-inactive($color-btn);
}

.btn--inactive-avail {
    border-color: transparent !important;
}

.btn--static {
    @include font-weight(regular);
    @include btn-static(#cfcfcf);
}


%btn-social {
    padding: 4px 12px;
}
.btn--facebook {
    @extend %btn-social;
    @include btn-color(#3b5998);
}
.btn--twitter {
    @extend %btn-social;
    @include btn-color(#1b95e0);
}
.btn--google {
    @extend %btn-social;
    @include btn-color(#db4437);
}


.buttonize {
    display: inline-block;
    padding: $inuit-base-spacing-unit--small;
    background-color: rgba($gray-base, .03);
    border: 1px solid rgba($gray-base, .06);
    border-bottom-width: 2px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.44);
    border: 0px;
    border-radius: 20px;
    color: white;
    background: #025daf;
    &:hover {
        background-color: rgba($gray-base, .06);
        border-bottom-color: rgba($gray-base, .09);
    }
    &:active, &.is-selected {
        background-color: lighten($color-brand, 46%);
        border-color: lighten($color-brand, 40%);
    }
    & > .icon {
        margin-top: -4px;
    }
}

.buttonize-air {
    display: inline-block;
    padding: $inuit-base-spacing-unit--small;
    background-color: rgba($gray-base, .03);
    border: 1px solid rgba($gray-base, .06);
    border-bottom-width: 2px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.44);
    border: 0px;
    border-radius: 20px;
    color: #004485;
    background: rgba(0, 0, 0, 0.06);
    &:hover {
        background-color: #004485;
        color: white;
        border-bottom-color: rgba($gray-base, .09);
    }
    &:active, &.is-selected {
        background-color: lighten($color-brand, 46%);
        border-color: lighten($color-brand, 40%);
    }
    & > .icon {
        margin-top: -4px;
    }
}

.btn--scroll-top {
    display: none;
    width: 48px;
    height: 57px;
    position: fixed;
    bottom: -26px;
    right: $inuit-base-spacing-unit;
    padding: $inuit-base-spacing-unit--small;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    background-color: $color-main;
    box-shadow: 0 0 3px 1px rgba(64, 64, 64, 0.4);
    backface-visibility: hidden; // prevent repaint overhead on scroll event
    z-index: 1;
}



/*------------------------------------*\
    #PILLS
\*------------------------------------*/
/*---
section: Buttons
title: pills
---

container
.pill

inline child elements

```example:html
<p>
    <a class="buttonize pill link-slim" title="Ir a tarifas de Latam" href="#ff_1_LA" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_LA.png"><span class="hidden-palm">&nbsp;Latam</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de VivaColombia" href="#ff_1_FC" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_FC.png"><span class="hidden-palm">&nbsp;VivaColombia</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de Avianca" href="#ff_1_AV" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_AV.png"><span class="hidden-palm">&nbsp;Avianca</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de Satena" href="#ff_1_Z0" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_Z0.png"><span class="hidden-palm">&nbsp;Satena</span>
    </a>
    <a class="buttonize pill link-slim" title="Ir a tarifas de EasyFly" href="#ff_1_VE" role="button">
        <img class="icon-badge" src="/assets/common_assets/img/airlines/logo_VE.png"><span class="hidden-palm">&nbsp;EasyFly</span>
    </a>
</p>
```
*/
.pill {
    display: inline-block;
    &:not(:nth-child(1)) {
        margin-left: $inuit-base-spacing-unit--small;
    }
    // & > .pill-height-helper {
    //     height: 100%;
    // }
    & > * {
        vertical-align: middle;
    }
}

.btn-floating {
  border: 0px;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  padding: 10px;
  line-height: 1.6;
  background: #005eb8;
  color: white;
}
.btn-floating .material-icons-round{
  font-size: 1rem;
}

.btn--secondary-passengers-add {
    background-color: #005CB9;
    color: white;
    @include attention() {
        background-color: #005CB9;
    }
    @include attention(active) {
        background: $color-btn;

    }
}

.btn--secondary-passengers-minus {
    background-color: #d1d1d1;
    color: white;
    @include attention() {
        background-color: #d1d1d1;
    }
    @include attention(active) {
        background: $color-btn;
    }
}
.btn-underline{
    color: #005CB9;
    font-weight: 500;
    border-bottom: 0.6px solid #005CB9;
}

.btn-circle{    
    display: inline-block;
    height: 3.375rem;
    width: 3.375rem;
    border-radius: 3rem;
    border: unset;
    background-color: #00c382 !important;
    color: white;
    font-size: 2.2rem !important;
    padding: 0.7rem 0.7rem;
}

.btn-modallogin__open {
    width: 135px;
}


/** CONTAINER SEARCH FLIGHTS **/

$font-family-roboto:    Roboto;
$secondary-color-search:   $color-secondary;

.container-promo {
    $small: 35.62em;
    @include media-query(lap-and-up) {
        display: flex;
        flex-direction: row;
    }
    @include media-query(lap) {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
    }
    @include media-query(palm) {
        border-bottom: 1px solid #E4E4E4;
        border-top: 1px solid #E4E4E4;
    }

    %promo-description {
        font-family: $font-family-roboto;
        font-style: normal;
        font-weight: 500;
        line-height: 145%;
        color: var(--Black-text, #2F2F2F);
    }

    .container-promo__description {
        @extend %promo-description;
        font-size: 24px;
        white-space: wrap;
        overflow: hidden; 
        max-width: 372px;
        %aviatur__highlighted {
            color: var(--Black-text, $color-main);
            font-family: $font-family-roboto;
            font-style: normal;
            font-weight: 600;
            line-height: 145%;
        }

        &__highlighted {
            @extend %aviatur__highlighted;
            font-size: 24px;

            @include media-query(lap) {
                font-size: 22px;
            }
            @include media-query(palm) {
                @extend %aviatur__highlighted;
                font-size: 18px;
            }
        }
        
        @include media-query(lap) {
            font-size: 22px;
        }
        @include media-query(palm) {
            font-size: 18px;
        }

    }

    .svg-map {
        width: 479px;
        height: 235px;

        @include media-query(palm) {
            width: 100%;
            height: 235px;
            margin: 0px;
            padding: 0px;
        }

        @include media-query(lap) {
            width: 100%;
        }
    }
  
    @include media-query(palm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column-reverse;
    }

    .flex-row {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        @include media-query(palm) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        @media screen and (max-width: $small) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.btn-search-flight {
    display: flex;
    min-width: 200px;
    height: 45px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: var(--White, #FFF);
    box-shadow: 0px 1px 12.2px 1px rgba(0, 0, 0, 0.10);
    color: var(--Secondary-Color, $secondary-color-search);
    font-family: $font-family-roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
    &:hover {
        border-radius: 30px;
        background: #F2F2F2;
        box-shadow: 0px 0px 10px 3px #005cb936;
        color: var(--Secondary-Color, $secondary-color-search);
        font-size: 17px;
        letter-spacing: -0.17px;
    }
}

.btn--search--flights--by--budget {
    $small: 35.62em;
    min-width: 277px;
    @media screen and (max-width: $small) {
        margin-top: 1rem;
    }
}

.btn-circle-avail{    
    display: inline-block;
    height: 3.375rem;
    width: 3.375rem;
    border-radius: 3rem;
    border: unset;
    background-color: #d1d1d1;
    color: white;
    font-size: 2.2rem !important;
    padding: 0.5rem 0.7rem;
}

// buttons nav Header
.btns_header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    border: 1px #042A44 solid;
    border-radius: 1.875rem;
    padding: 0.5rem;
    height: 1.9375rem;
    width: fit-content;
}