
///*------------------------------------*\
//    #DISPO-TRAIN
//\*------------------------------------*/

.train_availability_box {

    .hidden_segment {
        display: none;
    }

    .dispo-train-flex {
        display: inline-block;
        width:100%;
        margin-bottom: 5px;
    }

    .dispo-train-flex .train-dispo_column {  
        display: inline-block;
        vertical-align: top;
        min-height: 60px;
    }

    .dispo-train-flex .train-dispo_column.train-image {
        display: inline-block;
        width: 18%;
        max-height: 60px;  
        background-repeat: no-repeat !important;
    }

    .dispo-train-flex .train-dispo_column.train-segments {
        display: inline-block;
        width: 39%;
        text-align: center;  
    }

    .dispo-train-flex .train-dispo_column.train-segments .text-center{
        text-align: center;
        display: inline-block;
    }


    .dispo-train-flex .train-dispo_column.train-segments div{    
        vertical-align: top;
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-segment_option{
        border-bottom: 1px solid #e0e0e0;
        margin: 0px 0px 3px 0px;
        vertical-align: top;
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-traintrip_column{
        display: inline-block;
        margin: 0px 0px 0px 0px;
        font-size: 12px;
        color: #777;
        font-weight: bold;
        vertical-align: bottom;
        padding-bottom: 10px;
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-traindata_column{
        display: inline-block;
        margin: 0px 35px 0px 0px;
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-timedata_column{
        margin-top: 0px;
        display: inline-block;    
        vertical-align: top;
    }



    .dispo-train-flex .train-dispo_column.train-segments .train-timedata_column .train-cities{
        display: block;
        font-size: 11px;
        color: #009BF8;
        font-weight: bold;    
        overflow: hidden;

    }

    .dispo-train-flex .train-dispo_column.train-segments .train-timedata_column .train-cities .js-trainCityDeparture {
        max-width: 60px;
        display: inline-block;
        text-align: center;    
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-timedata_column .train-cities .js-trainCityArrival {
        max-width: 60px;
        display: inline-block;
        text-align: center;
    }


    .dispo-train-flex .train-dispo_column.train-segments .train-timedata_column .train-dates {
        display: inline-block;
        font-size: 1.1em;
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-timedata_column .train-icon_separator {
        display: inline-block;
    }

    .dispo-train-flex .train-dispo_column.train-segments .train-traindata_column .train-segment_label {
        height: 40%;
        font-weight: bold;
        font-size: 0.6em;  
        color: #777;
    }
    .dispo-train-flex .train-dispo_column.train-segments .train-traindata_column .train-segment_number {
        display: inline-block;
        height: 40%;
        text-align: center;
        /*margin-top: 5px;*/
        font-size: 1.5em;
    }

    .dispo-train-flex .train-dispo_column.train-fares {
        display: inline-block;
        width: 25%;
    }

    .dispo-train-flex .train-dispo_column.train-fares .js-planType {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 3px;
        color: inherit;
    }

    .dispo-train-flex .train-dispo_column.train-fares .js-classDescription {    
        font-size: 14px;
        margin-bottom: 3px;
        color: 777;
    }

    .dispo-train-flex .train-dispo_column.train-fares .js-FareAmount {
        padding-top: 5px;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 3px;
        color: inherit;
    }

    .dispo-train-flex .train-dispo_column.train-fares .js-FareAmount .train-fare_currency {
        font-size: 15px;
    }

    .dispo-train-flex .train-dispo_column.train-fares .js-FareAmount .train-fare_amount {
        padding-left: 2px;
    }


    .dispo-train-flex .train-dispo_column.train-fares input {
        display:inline-block;
    }

    .dispo-train-flex .train-dispo_column.train-fares .train-input_cheapest_fare {
        display: none;
    }

    .dispo-train-flex .train-dispo_column.actions .train-dispo_action-seemore {
        color: #009BF8;
        font-size: 14px;
        text-align: center;
        padding-top: 10px;
    }

    .dispo-train-flex .train-dispo_column.actions .train-dispo_action-seemore .js-seeMoreFares{
        cursor: pointer;
    }

    .train-dispo_hidden-box  {
        display: none;    
        text-align: right;
    }

    .train-dispo_hidden-box ul{ 
        width: 100%;
        list-style: none;
        padding: 0px;
        margin: 0px;
        text-align: left;    
        display: inline-block;
        border-left: 1px solid #efefef;
        padding-left: 8px;
    }

    .train-dispo_hidden-box ul li{
        border-bottom: 1px solid #efefef;
        text-align: left; 
        margin: 2px 2px 2px 0px;
        padding: 1px;
    }

    .train-dispo_hidden-box ul li .train-fare_option {
        text-align: left;
        margin-bottom: 5px;
    }

    .train-dispo_hidden-box .train-fare_option .text-center {
        display: inline-block;

    }

    .train-dispo_hidden-box .train-fare_option .text-center.js-planType{
        font-weight: bold;
    }

    .train-dispo_hidden-box .train-fare_option .text-center.js-classDescription{
        font-style: italic;
    }

    .train-dispo_hidden-box .train-fare_option .text-center.js-FareAmount{
        display: block;
        text-align: right;
        font-size: 18px;
        float: right;
    }

    .train-dispo_hidden-box .train-fare_option .text-center .train-fare_currency{
        font-size: 0.8em;
        font-weight: bold;
    }

    .train-dispo_hidden-box .train-fare_option .text-center .train-fare_amount{
        font-weight: bolder;
    }

    .dispo-roundtrip_shoppingcart {
        display: none;
        position: relative;
        font-size: 14px;
    }

    .dispo-roundtrip_shoppingcart div{
        /*border: 1px solid #f00;*/
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container {
        min-height: 80px;
        padding-bottom: 8px;
    }   

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item{
        display: block;
        min-height: 50px;
        padding: 2px;
        vertical-align: top;
        margin-bottom: 2px;
        position: relative;
    }


    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_column{
        display:inline-block;
        vertical-align: top;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_row{
        padding-top: 5px;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_label {
        font-weight: bold;
        margin-left: 3px;    
    }


    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_train-number {
        text-align: center;
        font-size: 17px;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_price {
        text-align: center;
        font-size: 18px;
        padding-top: 10px;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_triptype{
        font-size: 18px;
        padding-top: 10px;
        color: #969696;
        width: 50px;
        text-align: right;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_logo {
        width: 45px;
        height: 45px;
        background: url(/assets/common_assets/img/train/eurostarlogo-small.png);
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_number span{
        display: block;
        text-align: center;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_number span.js-cartTrainNumber{
        font-size: 18px
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_column:nth-child(5){
        text-align: center;
        color: #585858;
        padding: 0px 6px 0px 6px;
        margin: 0px 2px 0px 2px;

    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container{
        display: inline-block;
        vertical-align: top;
        width: 75%;    
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-actions {
        display: inline-block;
        vertical-align: top;
        width: 23%;
        text-align: center;  
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_change-box {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(255,255,255,0.9);
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
        display:none;
        z-index: 100;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-container .train-cart_item .train-cart_change-box .change-box_text{
        color: #009bf8;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-actions .train-cart_prices-label {
        color: #909090;
        font-size: 15px;
    }

    .dispo-roundtrip_shoppingcart .dispo_cart-container .train-cart_item-actions .train-cart_prices-amount {    
        font-size: 18px;
    }
    .js-trainHiddenInput{
        display: none;
    }
}