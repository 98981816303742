/*------------------------------------*\
  #CONTACT SLIDER
\*------------------------------------*/

$contact-slider-width: 400px;
$contact-slider-bck-clr: #fff;
$contact-slider-border: 2px solid $color-main;

// .contact-slider {
//     position: fixed;
//     top: $inuit-base-spacing-unit--huge;
//     right: 0;
//     z-index: $z-index-slidepanel - 1; // allow other slidepanels to appear on top
//     backface-visibility: hidden; // prevent repaint overhead on scroll event
// }
.contact-slider {
    position: fixed;
    top: 42em;
    right: 26em;
    top: 100%;
    z-index: 1000; // allow other slidepanels to appear on top
    backface-visibility: hidden; // prevent repaint overhead on scroll event
}

// .contact-slider__banner {
//     float: left;
//     position: absolute;
//     top: 60px;
//     left: -151px;
//     width: 260px;
//     padding: 14px 9px;
//     margin-top: $inuit-base-spacing-unit--huge;
//     border: $contact-slider-border;
//     border-top-width: 0;
//     border-radius: 0 0 $base-round $base-round;
//     color: $contact-slider-bck-clr;
//     background-color: $color-main;
//     @include font-weight(bold);
//     box-shadow: 0 2px 3px rgba(64, 64, 64, .4);
//     transform: rotate(90deg);
//     & .icon {
//         padding-right: $inuit-base-spacing-unit--small;
//     }
// }

.contact-slider__banner {
    float: left;
    position: absolute;
    top: -8em;
    left: 18em;
    width: 100px;
    padding: 0px 0px;
    margin-top: 0px;
    border-top-width: 0;
    border-radius: 0 0 $base-round $base-round;
    color: $contact-slider-bck-clr;

    @include font-weight(bold);
    box-shadow: none;
    & .icon {
        padding-right: $inuit-base-spacing-unit--small;
    }
}
.contact-slider__inner {
    float: left;
    width: $contact-slider-width;
    max-width: $contact-slider-width;
    margin-right: -$contact-slider-width;
    padding: $inuit-base-spacing-unit--small;
    border:none;
    border-right-width: 0;
    border-radius: $base-round;
    background-color: $contact-slider-bck-clr;
    box-shadow:2px 2px 8px rgba(64, 64, 64, 0.4);
}

.contact-slider__inner {
    padding-right:29px;
    float: left;
    width: $contact-slider-width;
    max-width: $contact-slider-width;
    margin-right: -$contact-slider-width;
    padding: $inuit-base-spacing-unit--small;
    border:none;
    border-radius: $base-round;
    background-color: $contact-slider-bck-clr;
    box-shadow:2px 2px 8px rgba(64, 64, 64, 0.4);
}

.contact-slider__col-ind {
    // @include span(1 of 3);
	width: calc((1 / 3) * 100%);
}
.contact-slider__col-num {
    @include span(2 of 3);
	width: calc((2 / 3) * 100%);
}
.contact-slider__col-name,
.contact-slider__col-submit,
.contact-slider__col-disclaimer {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
}
.contact-slider__response {
    min-height: 166px;
    display: table;
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    & * {
        display: table-cell;
        vertical-align: middle;
    }
}
