///*------------------------------------*\
//   #DETAIL-PACKAGE
//\*------------------------------------*/

.detail-package__carousel {
    float: left;
    max-width: 750px;
}
.detail-package__carousel__image {
    height:500px;
    background: no-repeat center center;
    background-size: cover;
}


.detail-package__corpbanca{
    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.detail-package__codensa{
    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.detail-package__corpbanca-background{
    background: url(/assets/aviatur_assets/img/package/banner_corpbanca_detalle.png) no-repeat center right;
    min-height: 145px;
}

.detail-package__codensa-background{
    background: url(/assets/aviatur_assets/img/package/banner_codensa.jpg) no-repeat center right;
    min-height: 145px;
}

// .detail-package__bbva-background{
//     background: url(/assets/aviatur_assets/img/package/bbva_banner.jpg) no-repeat center right;
//     min-height: 145px;
// }


.detail-package__corpbanca-table{
    display: table;
    min-height: 50px;
    margin-left: 8%;
    font-size: 19px;
}

.detail-package__corpbanca-table div{
    display: table-cell;
    vertical-align: middle;
}


/* NEW STUFF BELOW */

.detail-package__info {
    padding: $inuit-base-spacing-unit 0;
    background-color: #fff;
}

.package-detail_frame-group {
    margin-left: 20px;
    @include media-query(palm) {
        margin-left: 0px;
    }
}

.wrapper-susy div.infoHotel-pkgSubmit_column {
    position: relative;
    @include media-query(palm) {
        position: fixed;
        width: 100%;
        top: 10px;
        left: 0px;
        z-index: 99;
    }
    .frame-group{
        @include media-query(palm) {
            background: #fff;
        }
    }
}

.wrapper-susy div.infoHotel_roomTypes-column {
    position: relative;
    @include media-query(palm) {
        width: 100%;
    }
}
