/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
/* display none until init */
.owl-carousel{
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0, 0);
}
.owl-carousel .owl-wrapper-outer{
    overflow: hidden;
    position: relative;
    width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item{
    float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
    cursor: pointer;
}
.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    width: 100%;
}

/* mouse grab icon */
/*.grabbing {
    cursor:url(grabbing.png) 8 8, move;
}*/

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}

/*
* 	Owl Carousel Owl Demo Theme
*	v1.3.3
*/

.owl-theme .owl-controls{
    margin-top: 10px;
    text-align: center;
}
.owl-theme--prevnext .owl-controls {
    top: 50%;
    transform: translate3d(0, -50%, 0);
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
    color: #FFF;
    display: inline-block;
    zoom: 1;
    margin: 5px;
    padding: 3px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #869791;
    opacity: .5;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
    opacity: 1;
    text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
    display: inline-block;
    zoom: 1;
}
.owl-theme .owl-controls .owl-page span{
    display: block;
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    opacity: .5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{

    opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
    min-height: 150px;
    background: url(https://aviaturcdndev.z5.web.core.windows.net/assets/aviatur_assets/img/icon/spin.gif) no-repeat center center
}

.owl-prev, .owl-next {
    opacity: 1 !important;
    padding: $inuit-base-spacing-unit--small !important;
    background-color: $color-detail-carousel-arrow !important;
    @include attention(){
        background-color: darken($color-detail-carousel-arrow, 15%) !important;
    }
    & .icon {
        @include font-size($inuit-base-font-size);
    }
}
.owl-prev {
    float: left;
    @include media-query(lap-and-up) {
        transform: translate3d(-50%, 0, 0);
    }
}
.owl-next {
    float: right;
    @include media-query(lap-and-up) {
        transform: translate3d(50%, 0, 0);
    }
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
    margin-bottom: 10px;
    margin-left: 2px;
    height: 100%;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
    height: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-loaded {
    display: block;
    height: 100%;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

.owl-dots {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #a9d5ff;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 0.2s ease;
    border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span {
    background: #005eb8;
}

/* Ordenadores de sobremesa y portátiles */
@media only screen and (min-width : 1224px) {
    .owl-theme .owl-dots .owl-dot:hover span {
        background: #005eb8;
    } 
}


.owl-item .slideItem {
    cursor: pointer;
    overflow: hidden;
    border-radius: 12px;
    min-height: 352px;
    height: auto;
}

.owl-carousel .owl-item .item {
    height: 100%;
}
.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav {
    margin-top: 10px
}

.owl-theme .owl-nav [class*=owl-] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled {
    margin-top: 10px
}

.owl-carousel .owl-nav.disabled {
    display: none
}

.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev{
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit
}

.owl-carousel .owl-nav {
    height: 0px;
}

.owl-carousel .nav-btn {
    height: 35px;
    position: absolute;
    width: 35px;
    cursor: pointer;    
    background: #005eb8;
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
    &.nav-btn-slide{
        top: -230px !important;
    }
    &.nav-btn-slideh{
        top: -355px !important;
    }
    &.nav-btn-slideg{
        top: -130px !important;
    }
}

.owl-carousel .prev-slide {
    left: -50px;
}

.nav-btn.next-slide {
    right: -50px;
}
.nav-btn.next-slideg {
    right: 0px;
}
.owl-carousel .owl-prev.disabled, .owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.2 !important;
}