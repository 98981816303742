/*------------------------------------*\
  #PAGE
\*------------------------------------*/
//@font-face{font-family:futura;font-weight: 100;src:url('/assets/aviatur_assets/font/futura/futura.ttf?')}
//@font-face{font-family:futura;font-weight: 600;src:url('/assets/aviatur_assets/font/futura/futura-medium.ttf?')}
//@font-face{font-family:futura;font-weight: 800;src:url('/assets/aviatur_assets/font/futura/futura-bold.ttf?')}
//@font-face{font-family:poppins;font-weight: thin; src:url('/assets/aviatur_assets/font/Poppins/Poppins-Thin.ttf?')}
//@font-face{font-family:poppins;font-weight: normal ;src:url('/assets/aviatur_assets/font/Poppins/Poppins-Medium.ttf?')}
// @font-face{font-family:poppins;font-weight: lighter; src:url('/assets/aviatur_assets/font/Poppins/Poppins-Light.ttf?');font-display: swap;}
// @font-face{font-family:poppins;font-weight: bold; src:url('/assets/aviatur_assets/font/Poppins/Poppins-SemiBold.ttf?');font-display: swap;}
html {
    font-family:'Ubuntu', sans-serif;
    font-weight: 100;
    overflow-x: hidden;
}
body {
  font-size: 1em;
    font-family: 'Ubuntu',sans-serif!important;
    width: 100%;
    @include media-query(palm) {
        overflow-x: hidden;
    }
}
header {
    margin-bottom: $inuit-base-spacing-unit;
}

.page__body {
    // margin-bottom: $inuit-base-spacing-unit;
    background: #F4F7FA;
}
