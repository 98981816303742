///*------------------------------------*\
//    #BUNDLE CUSTOMER
//\*------------------------------------*/

.customer__col-booking {
    // @include span(1 of 2);
	width: calc((1 / 2) * 100%);
    @include media-query(palm) {
        // /* @include span(full); */ width: calc((1 / 1) * 100%);
		width: calc((1 / 1) * 100%);
    }
}

.holder {
	margin: 15px 0;
}

.holder a {
	margin: 0 5px;
	color: #005EB8;
	padding: 5px 10px;
}

.holder a:hover {
	background-color: #005EB8;
	color: #fff;
	padding: 5px 10px;
}

.holder a.jp-previous { margin-right: 15px; }
.holder a.jp-next { margin-left: 15px; }

.holder a.jp-current, a.jp-current:hover {
	color: #005EB8;
	font-weight: 600;
}

.holder a.jp-disabled, a.jp-disabled:hover {
	color: #838282;
}

.holder a.jp-current, a.jp-current:hover,
.holder a.jp-disabled, a.jp-disabled:hover {
	cursor: default;
	background: none;
}

.holder span { margin: 0 5px; }
