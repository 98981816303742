///*------------------------------------*\
//    #SEARCH CAR
//\*------------------------------------*/

.body-page-aviatur-search-cars,
.body-page-aviatur-car-availability-1,
.body-page-aviatur-car-availability-2 {
    $color_1: #222222;
    $color_2: white;
    $color_3: rgb(53, 53, 53);
    $color_4: #222;
    $color_5: #FFFFFF;
    $background-color_1: hsla(0,0%,100%,.8);
    $background-color_2: #0ac6d1;
    $background-color_3: transparent;
    $background-color_4: white;
    $background-color_5: #0AC6D1;
    $background-color_6: #27D5DF;

    .page__body {
        background-color: white;
    }

    .main__header {
        margin-left: 9px;
        margin-top: 0;
        padding-top: 0;
    }
    #carOptions {
        padding: 10px 0 10px 0;
        background: transparent;
        label {
            color: $color_1 !important;
            font-weight: 500;
        }
        input {
            color: $color_1 !important;
        }
        select {
            color: $color_1 !important;
        }
        i {
            color: $color_1 !important;
        }
    }
    #destination1Validate {
        &::placeholder {
            color: $color_1 !important;
        }
    }
    .search__col-flight-date-flight {
        border: none;
    }
    .wrapper-susy {
        align-items: center;
        flex-direction: column;
        margin-left: 0.5rem;
        margin-right: 2.5rem;
        gap: 8px;
        text-align: left;
    }
    .dispo__col-search {
        background-color: $background-color_1;
        width: 100%;
        border-radius: 30px;
        padding: 7px 10px;
        height: 65px;
        label {
            padding-left: 0.6em;
        }
    }
    .search__col-flight-send-button {
        background-color: $background-color_1;
        width: 100%;
        border-radius: 30px;
        padding: 7px 10px;
        height: 65px;
        text-align: center;
        visibility: visible;
        background-color: $background-color_2;
        a {
            background-color: $background-color_2;
        }
    }
    #origin1Id {
        label {
            padding-left: 0.6em;
        }
    }
    #destination1Id {
        label {
            padding-left: 0.6em;
        }
    }
    #datesdisplay1Id {
        label {
            padding-left: 0.6em;
        }
    }
    .search__header {
        .search__btn-group {
            .btn {
                padding-left: 39px;
                display: flex;
                font-size: 16px;
                color: $color_2;
            }
        }
    }
    .search-title-flight {
        font-size: 20px;
        width: 100%;
        display: none;
    }
    .search__light--box-opaque-palm {
        background: transparent;
        position: relative;
        z-index: 9999;
    }
    .page__body--search {
        .search-shared__datepicker-container {
            border-radius: 14px;
            z-index: 999999;
        }
    }
    .ui-datepicker-calendar {
        tbody {
            tr {
                td.ui-datepicker-current-day {
                    background: #0ac6d1;
                }
            }
        }
    }
    #js-createDatepicker1 {
        opacity: 1;
        background-color: $background-color_3;

        &::placeholder {
            color: $color_1;
        }
    }
    #js-createDatepicker-return1 {
        opacity: 1;
        background-color: $background-color_3;

        &::placeholder {
            color: $color_1;
        }
    }
    header.page-head {
        margin-bottom: 0;
    }

    .hero-resume-search {
        background: radial-gradient(49.95% 49.95% at 50% 50.05%,transparent 0,rgba(0,0,0,.38) 100%),url(/assets/availability-car.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        width: 100%;
        margin-top: 0px;
        .wrapper {
            width: 100%;
        }
    }
    .btn-color {
        background-color: $background-color_5;
        height: 36px;
        border-radius: .375rem;
        box-shadow: 0 4px 5px rgba(108,128,201,.12);
        font-size: .875rem;
        font-weight: 700;
        &:hover {
            background-color: $background-color_6;
        }
        &:focus {
            background-color: $background-color_6;
        }
        &:active {
            background-color: $background-color_6;
        }
    }
    .dispo-car__type-image {
        .dispo-car__logo-small {
            width: 25%;
            position: absolute;
            left: -5px;
            top: -20px;
        }
        p {
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            color: $color_4;
            text-align: left;
            margin-top: 20px;
        }
    }
    .dispo-car__features-count {
        font-size: 12px;
    }
    .dispo-car__filter {
        details {
            border-bottom: 1px solid rgb(196 196 196);
            summary {
                .icon {
                    float: right;
                }
                .icon.icon_arrow_up {
                    display: none;
                }
            }
            .js-checkboxOptions {
                padding: 0 1rem;
            }
            .js-carClassButton {
                cursor: pointer;
                padding: 0 1rem 1rem 1rem;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        summary {
            cursor: pointer;
            padding-top: 18px;
            padding-bottom: 18px;
            color: $color_3;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            border-style: none;
            padding-left: 0px;
        }
        details[open] {
            summary {
                .icon.icon_arrow_up {
                    display: block;
                }
                .icon.icon_arrow_down {
                    display: none;
                }
            }
        }
    }

    .dispo-shared__sidebar {
        margin-right: 2rem;
    }

    .dispo-shared__content {
        .dispo-shared__recommendations {
            .js-recommendationOption-recommended {
                .title-recommendation {
                    display: none;
                    visibility: hidden;
                }
            }

            .dispo-car__frame-group {
                display: flex;
                flex-direction: column;

                .dispo-car__frame-head {
                    order: 0;
                }

                .dispo-car__frame-border {
                    order: 2;
                }

                .dispo-car__frame-foot {
                    order: 3;
                }
            }

            .dispo-car__frame-group:first-child {
                .js-recommendationOption-recommended {
                    order: 1;
                    border: 10px solid #0AC6D1;

                    .title-recommendation {
                        display: block;
                        visibility: visible;

                        h3 {
                            background: #0AC6D1;
                            padding: 1rem 1rem 1.5rem;
                            font-weight: bold;
                            color: white;
                        }
                    }
                }
            }
        }

        .dispo-car__frame-border {
            box-shadow: rgba(108, 128, 201, 0.12) 0px 4px 4px;
            border-radius: 0.375rem;
            margin-bottom: 1.75rem;
            &:hover {
                outline: rgb(196, 196, 196) solid 1px;
            }
            .dispo-car__frame-content.frame {
                border: none;
                box-shadow: none;
            }
        }
        .dispo-car__title {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 23px;
        }
    }
    .dispo-car__features-wrapper {
        p {
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            color: $color_4;
            text-align: left;
            margin-top: 20px;
            display: none;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        padding-bottom: 8px;
        border-bottom: 2px dashed #c4c4c4;
    }
    .wrapper {
        >.wrapper-susy {
            display: flex;
            margin: 0 32px;
        }
    }
    .dispo-car__features-block {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 18px;
    }
    .dispo-car__features {
        margin-left: 0;
        display: flex;
        gap: 8px;
        align-items: center;
        .dispo-car__features-tooltip {
            line-height: 12px;
        }
    }
    .dispo-car__price-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        padding: 8px 0;
    }
    .dispo-car__price-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .dispo-car__price-info {
        display: flex;
        gap: 8px;
    }
    .dispo-car__price-label {
        font-size: 12px;
    }
    .dispo-car__price {
        font-size: 15px;
    }
    .price_total {
        font-size: 15px;
    }
    #origin1Validate {
        &::placeholder {
            color: $color_1;
        }
    }
    .search-shared__datepicker-container {
        border-radius: 14px;
        z-index: 999999;
    }
    #resumeSearchCar {
        background-color: $background-color_3;
        box-shadow: none !important;

        .js-resumeSearch {
            &.justify-between {
                justify-content: flex-start;
                background-color: transparent !important;
                box-shadow: none !important;

                .pointer {
                    width: 100% !important;
                }
            }

            div.w-fit {
                display: flex !important;
                gap: 1rem;
            }
        }

        p,
        small,
        .material-symbols-outlined {
            color: $color_2 !important;
        }

        .border-l-solid {
            border-left-color: $color_2 !important;
        }

        .js-changeSearch {
            background-color: $background-color_5;
            color: $color_2;
            border-radius: 0.375rem;
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            gap: .2rem;
        }
    }

    @media (min-width:650px) {
        #carOptions {
            label {
                font-size: 16px;
            }
            input {
                font-size: 12px;
            }
            select {
                option {
                    font-size: 12px;
                }
                font-size: 12px !important;
            }
        }
        .page__body--search {
            .search-shared__datepicker-container {
                left: 62px !important;
            }
        }
        .dispo-car__type-image {
            p {
                display: none;
            }
            width: 100%;
        }
        .dispo-car__features-wrapper {
            p {
                display: block;
                margin: 0;
                margin-bottom: 20px;
            }
            width: 100%;
            justify-content: flex-end;
        }
        .dispo-car__col-description {
            width: 40%;
        }
        .dispo-car__logo {
            margin: 0;
            position: absolute;
            top: -10px;
        }
        .dispo-car__frame-content {
            display: flex;
            align-items: center;
        }
        .dispo-car__price-wrapper {
            width: 60%;
        }
        .dispo-car__price-container {
            width: 100%;
            justify-content: flex-end;
            gap: 8px;
            align-items: center;
        }
        .dispo-car__submit {
            width: 128px;
        }
        .dispo-car__price-info {
            align-items: center;
        }
        .dispo-car__features-block {
            flex-direction: row;
        }
        .search-shared__datepicker-container {
            left: 62px !important;
        }
    }
    @media screen and (min-width: 64em) {
        #carOptions {
            label {
                font-size: 19px;
                padding-top: 10px;
            }
            input {
                font-size: 14px;
            }
            select {
                option {
                    font-size: 14px;
                }
                font-size: 14px !important;
            }
            display: flex;
            height: 82px;
            width: 96%;
            padding: 0;
            align-items: center;
        }
        .search__header {
            button {
                padding-left: 0;
            }
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 38px;
        }
        .search__light {
            padding-top: 196px;
            display: flex;
            width: 100%;
            justify-content: center;
        }
        .search__light--box-flight {
            align-items: flex-start;
            .btn-group {
                >.btn {
                    padding: 0;
                    margin-left: 48px;
                }
            }
        }
        .search-title-flight {
            font-size: 55px;
            font-weight: 500;
        }
        .hide-availengine {
            font-weight: 400;
            font-size: 1rem;
        }
        .search__col-flight-date-flight {
            height: 85px;
            &::before {
                content: '';
                position: absolute;
                top: calc((82px - 2.5rem) / 2);
                height: 2.5rem;
                width: 3px;
                background-color: $background-color_4;
            }
        }
        #destination1Id {
            &::before {
                content: '';
                position: absolute;
                top: calc((82px - 2.5rem) / 2);
                height: 2.5rem;
                width: 3px;
                background-color: $background-color_4;
            }
            width: 50%;
            border-radius: 0;
        }
        .wrapper-susy {
            flex-direction: row;
            gap: 0;
            border-radius: 40px 0 0 40px;
            text-align: left;
        }
        .search__btn-group {
            >.btn {
                font-weight: 500;
            }
            >.btn--slim {
                font-weight: 500;
            }
            >.btn-flight-blog {
                font-weight: 500;
            }
        }
        .dispo__col-search {
            background-color: $background-color_1;
            border-radius: inherit;
            height: 85px;
        }

        .resume-search__engine {
            position: relative;
        }

        .resume-search__engine__close {
            position: absolute;
            right: -15px;
            color: white;
        }

        .js-changeSearch {
            cursor: pointer;
        }

        .search__col-flight-send-button {
            background-color: $background-color_1;
            border-radius: inherit;
            height: 85px;
            margin-left: 1rem;
            width: 6%;
            position: relative;
            top: 0;
            bottom: 0;
            right: 16px;
            height: 85px;
            background-color: $background-color_3;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NiIgaGVpZ2h0PSI4MiIgdmlld0JveD0iMCAwIDU2IDgyIj48c2NyaXB0IHhtbG5zPSIiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01NS41MDY0IDgyQzM4LjA2MDIgNzYuMjUzMSAyNS40NjY3IDU5LjgyMzkgMjUuNDY2NyA0MC40NTMzQzI1LjQ2NjcgMjIuMTk0MyAzNi42NTY0IDYuNTQ4NjkgNTIuNTUyNiAwSDAuNVY4Mkg1NS41MDY0WiIgZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMC44Ii8+CjxzY3JpcHQgeG1sbnM9IiIvPjwvc3ZnPgo=');
                background-position: right;
                background-size: cover;
                background-repeat: no-repeat;
            }
            a {
                position: absolute;
                top: 4px;
                bottom: 0;
                right: -55px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .btn-circle {
                width: 4.73rem;
                height: 4.73rem;
                background-color: $background-color_2;
            }
        }
        #datesdisplay1Id {
            width: 50%;
            border-right: none;
        }
        .divider-right {
            width: 50%;
        }
        #origin1Id {
            width: 50%;
        }
        .page__body--search {
            .search-shared__datepicker-container {
                left: 30vw !important;
            }
        }
        .wrapper {
            >.wrapper-susy {
                align-items: flex-start;
            }
        }
        .dispo-car__submit {
            display: flex;
            width: 157px;
        }
        .dispo-car__price-container {
            flex-direction: column;
        }
        .dispo-car__price {
            text-wrap: nowrap;
        }
        .dispo-car__features-wrapper {
            border-bottom: none;
            border-right: 1px solid #c4c4c4;
            padding-right: 10px;
        }
        .dispo-car__col-description {
            display: flex;
        }
        .dispo-car__type-image {
            margin-left: 0;
            margin-right: 40px;
        }
        .dispo-car__price-info {
            flex-direction: row;
            align-items: center;
        }
        .dispo-car__price-wrapper {
            flex-direction: row;
            align-items: inherit;
        }
        #time1 {
            padding-left: 40px;
        }
        #time-return1 {
            padding-left: 40px;
        }
        .search-shared__datepicker-container {
            left: 30vw !important;
        }
    }
    @media screen and (min-width: 1200px) {
        .page__body--search {
            .search-shared__datepicker-container {
                left: 36vw !important;
            }
        }
        .search-shared__datepicker-container {
            left: 36vw !important;
        }
    }
}

.body-page-aviatur-car-availability-2 {
    .search__header {

    }

}
