///*------------------------------------*\
//    #SEARCH DOCUMENTATION
//\*------------------------------------*/

.min__col-documentation {
    @include span(2 of 5);
    @include media-query(portable) { 
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.max__col-documentation {
    @include span(3 of 5);
    @include media-query(portable) { 
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.text-input-documentation--underline {
    background-color: rgba(0,0,0,0) !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: $color-form-underline;
    padding-left: 2px;
    @include attention() {
        color: darken($color-form-underline, 10%);
    }
}