///*------------------------------------*\
//    #SEARCH PACKAGE
//\*------------------------------------*/

.search__col-package {   
    @include span(1 of 4);
    @include media-query(portable) { 
        @include span(1 of 2);
    }
}
.search__col-submit {    
    @include span(1 of 2);
    @include media-query(portable) { 
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search-package{
    option,optgroup{
        color: #000;
    }
    .text-input{
        background-color: transparent;
        color: white;
    }
}

.package-box {
    display: block;
    position: relative;
    overflow: hidden;
    @include attention() {
        & .package-box__image {            
            transform: scale(1.1);
            &:after {
                content: ' ';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.2);
            }
        }
        & .package-box__label {
            background-color: $color-main;
        }
    }
    &:active .package-box__outline, 
    &:focus .package-box__outline {
        border-width: 5px;
        border-color: $color-main;
    }
    &.active {        
        & .package-box__outline {
            border-width: 5px;
            border-color: $color-main;
        }
        & .package-box__image {
            transform: scale(1.1);
            &:after {
                content: ' ';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.2);
            }
        }
        & .package-box__label {
            background-color: $color-main;
        }
    }
}
.package-box__outline {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid $color-main;
    z-index: 10; // keep on top
}
.package-box__image {
    height: 200px;
    background: #000 no-repeat center;
    background-size: cover;
    transition: all .45s;
    @include media-query(palm) {
        height: 128px;
    }
}
.package-box__label-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    backface-visibility: hidden; // avoid text flickering
}
.package-box__label {
    background-color: rgba(0,0,0,.4);
    transition: all .2s;
}


.search__light--box {
    @include media-query(lap-and-up) {
        & .package-box__outline {
            border-color: #fff;
        }
    }
}