///*------------------------------------*\
//    #NAV-TOP
//\*------------------------------------*/


// Internal variables
$nav-top-font-size: 16px;
$nav-top-font-size--large: 20px;


.nav-top__login, .nav-top__logged  {
    font-weight: 600;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
}

.nav-top__login {
    @include inuit-font-size($nav-top-font-size);
    @include media-query(lap-and-up) {
        line-height: 1.5;
        font-size: 0.8rem;
    }
}

.nav-top ul li * {
    display: block
}

/*.nav-top__mailing a, .nav-top__login label {
    @include inuit-font-size($nav-top-font-size);
    font-weight: 600;
    vertical-align: middle;
    @include media-query(desk) {
        @include inuit-font-size($nav-top-font-size--large);
    }
}
.page-head_ocean {
    & .nav-top__mailing a,
    & .nav-top__logged a {
        @include link-quiet($color-links-quiet);
    }
    & .nav-top__login label {
        color: $color-links-quiet;
    }
}
.page-head_machupichu {
    & .nav-top__mailing a,
    & .nav-top__logged a {
        @include link-quiet($color-links);
    }
    & .nav-top__login label {
        color: $color-links;
    }
}
.page-head_beach {
    & .nav-top__mailing a,
    & .nav-top__logged a {
        @include link-quiet($color-links-quiet);
    }
    & .nav-top__login label {
        color: $color-links-quiet;
    }
}
.page-head_desert {
    & .nav-top__mailing a,
    & .nav-top__logged a {
        @include link-quiet($color-links-quiet);
    }
    & .nav-top__login label {
        color: $color-links-quiet;
    }
}
.page-head_city {
    & .nav-top__mailing a,
    & .nav-top__logged a {
        @include link-quiet($color-links-quiet);
    }
    & .nav-top__login label {
        color: $color-links-quiet;
    }
}
.page-head_bridge {
    & .nav-top__mailing a,
    & .nav-top__logged a {
        @include link-quiet($color-links-quiet);
    }
    & .nav-top__login label {
        color: $color-links-quiet;
    }
}
.nav-top__mailing--promo a,
.nav-top__logged--promo a {
    @include link-quiet($color-links-quiet);
}
.nav-top__login--promo label {
    color: $color-links-quiet;
}
.nav-top__mailing  {
    float: left;
}
    .nav-top__mailing  a {
        display: block;
    }*/

/*.nav-top__login  {
    float: right;
}
    .nav-top__login  label {
        padding: $inuit-base-spacing-unit--tiny;
        padding-left: 0;
    }
    .nav-top__login  input {
        max-width: 180px;
    }*/
/*.nav-top__logged {
    float: right;
}
    .nav-top__logged a {
        display: block;
        padding: $inuit-base-spacing-unit--tiny 0 $inuit-base-spacing-unit--tiny $inuit-base-spacing-unit;
        @include media-query(desk) {
            line-height: $inuit-base-spacing-unit--large;
        }
    }*/
/*
.nav-top__mailing, .nav-top__login {
    @include media-query(lap) {
        margin: 5px 0;
    }
}*/
