.offer__container {
    text-align: center;
}

.content__division {
    width: 490px;
    padding: 0;
    cursor: pointer;
}

.offer__content, .content__division {
    display: inline-block;
    margin: 2px;
    margin-bottom: 20px;
}

@media screen and (min-width: 387px) and (max-width: 420px){
    .offer__content, .content__division {
        width: 340px !important;
    }
    figure.promo-tickets figcaption > div {
        height: 52% !important; 
    }
}

@media screen and (min-width: 362px) and (max-width: 381px){
    .offer__content, .content__division {
        width: 320px !important;
    }
    figure.promo-tickets figcaption > div {
        height: 52% !important; 
    }
}

@media screen and (min-width: 300px) and (max-width: 361px){
    .offer__content, .content__division {
        width: 100% !important;
    }
    figure.promo-tickets figcaption > div {
        height: 52% !important; 
    }
}

.offer__title {
    padding-bottom: calc($inuit-base-spacing-unit--small/2);
    border-bottom: 1px solid $color-frame-border;
    font-size: 16px;
    color: $color-main;
}

.offer__price-label {
    padding: $inuit-base-spacing-unit--small 0;
    border-top: 1px solid #FAFAFC;
    color: rgba(0,0,0,.6);
}

.offer__price {
    @include font-weight(bold);
    color: $color-main;
}

.offer__product-count {
    padding: $inuit-base-spacing-unit--small 0;
    border-bottom: 1px solid #FAFAFC;
    color: rgba(0,0,0,.6);
}

.offer__reservation {
    border-top: 1px solid $color-frame-border;
    text-transform: uppercase;
    color: $color-main;
}

// .bono-regalo-float {
//     padding: 21px 15px;
//     background: #2063CC;
//     box-shadow: 0px 2.93976px 5px rgb(0, 0, 0 , 0);
//     border-radius: 0px 14.6988px 14.6988px 0px;
//     color: white;
//     display: flex;
//     max-width: 124px;    
//     position: fixed;
//     top: 35%;
//     a {
//         font-size: 15px;
//         line-height: 14px;
//         margin-left: 10px;    
//         &:hover, &:focus {
//             color: white;
//         }
//     }
//     span {
//         font-size: 15px;
//         line-height: 14px;
//         margin-left: 10px;
//         font-weight: bold;
//     }
//     .span-secundary {
//         font-size: 12px;
//         font-weight: initial;
//         margin-left: 0px;
//     }    


.hoteles{
    padding: 21px 15px;
    background: #2063CC;
    box-shadow: 0px 2.93976px 5px rgb(0, 0, 0 , 0);
    border-radius: 0px 14.6988px 14.6988px 0px;
    color: white;
    display: flex;
    max-width: 124px;    
    position: fixed;
    top: 35%;
    a {
        font-size: 15px;
        line-height: 14px;
        margin-left: 10px;    
        &:hover, &:focus {
            color: white;
        }
    }
    span {
        font-size: 15px;
        line-height: 14px;
        margin-left: -9px;
        font-weight: bold;
        margin-top: 6px;
    }
    .span-secundary {
        font-size: 12px;
        font-weight: initial;
        margin-left: 0px;
    }    
}

@media print {
    .ocultar-boton , .ocultar-boton{
      display: none !important;
    } 
}