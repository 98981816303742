/*------------------------------------*\
    #FORMS
\*------------------------------------*/

input[type='checkbox'] {
    height: 12px;
    margin: 0 0 1px 0;
    vertical-align: middle;
}

.list-radio {
    display: inline-block;
    position: relative;
    height: 10px;
    width: 10px;
    border: 1px solid $color-main;
    border-radius: 50%;
    & > .list-checked {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        background-color: $color-main;
        border-radius: 50%;
    }
}
.list-radio--big {
    @extend .list-radio;
    padding: $inuit-base-spacing-unit--small;
}

.list-checkbox {
    @extend .list-radio;
    border-radius: 100%;
    & > .list-checked {
        border-radius: 100%;
    }
}

.checkbox-label {
    display: inline;
}

.checkbox-flight-engine {
    display: none;
}

.checkbox-flight-engine + *::before {
    content: "";
    display: inline-block;
    vertical-align: sub;
    width: 1rem;
    height: 1rem;
    border-radius: 20%;
    border-style: solid;
    border-width: 0.063rem;
    border-color: #D9D9D9;
    margin-right: 0.2rem;
}

.checkbox-flight-engine:checked + *::before {
    content: "✓";
    @include media-query(lap) {
        background: $color-checkbox-background;
        color: $color-box-promo;
    }
    @include media-query(palm) {
        background: $color-checkbox-background;
        color: $color-box-promo;
    }
}

.checkbox-flight-engine:checked + * {
    color: $color-box-promo;
    @include media-query(lap) {
        color: #8B8B8B;
    }
    @include media-query(palm) {
        color: #8B8B8B;
    }
}

.vertical-align-options-flight {
    vertical-align: top;
}

.checkbox-flight-engine-avail {
    display: none;
}

.checkbox-flight-engine-avail + *::before {
    content: "";
    display: inline-block;
    vertical-align: sub;
    width: 1rem;
    height: 1rem;
    border-radius: 20%;
    border-style: solid;
    border-width: 0.063rem;
    border-color: #D9D9D9;
    margin-right: 0.2rem;
}

.checkbox-flight-engine-avail:checked + *::before {
    content: "✓";
    color: #5a5a5a;
}

.checkbox-flight-engine-avail:checked + * {
    color: #5a5a5a;
}
