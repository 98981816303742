///*------------------------------------*\
//    #MODALS - CUSTOM FROM BOOTSTRAP
//\*------------------------------------*/

//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit


//Internal variables
$modal-lg:                    900px;
$modal-md:                    90%;
$modal-sm:                    300px;


// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal;
    -webkit-overflow-scrolling: touch;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;

    // When fading in the modal, animate it to slide down
    &.fade .modal-dialog {
        translate: 0, -25%;
        transition: transform .3s ease-out;
    }
    &.in .modal-dialog { translate: (0, 0); }
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
    // Automatically set modal's width for larger viewports
    @include media-query(lap-and-up){
        width: $modal-md;
        margin: 30px auto;
    }
}

// Actual modal
.modal-content {
    position: relative;
    background-color: $color-modal-bg;
    border: 1px solid $color-modal-border;
    border-radius: $base-round;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: 0;
    @include media-query(lap-and-up){
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal - 1;
    background-color: $color-modal-backdrop-bg;
    // Fade for backdrop
    &.fade { opacity: 0; }
    &.in { opacity: .5; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding: $inuit-base-spacing-unit;
    border-bottom: 1px solid $color-modal-head;
    background-color: $color-modal-head;
    min-height: 60px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
// Close icon
.modal-header .close {
    margin-top: -2px;
}

// Title text within header
.modal-title {
    margin: 0;
    color: $color-modal-head-color;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    min-height: 180px;
    position: relative;
    padding: $inuit-base-spacing-unit;
    title {
        display: block;
        font-size: large;
        font-weight: bolder;
        margin-bottom: 5px;
}
    item {
        display: block;
    }
    section title {
        font-weight: bold;
        font-size: medium;
        margin-bottom: 5px;
    }
}

// Footer (for actions)
.modal-footer {
    padding: $inuit-base-spacing-unit;
    text-align: right; // right align buttons
    border-top: 1px solid $color-modal-border;
    @extend .clearfix; // clear it in case folks use .pull-* classes on buttons

    // Properly space out buttons
    .btn + .btn {
        margin-left: 5px;
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
    // but override that for button groups
    .btn-group .btn + .btn {
        margin-left: -1px;
    }
    // and override it for block buttons as well
    .btn-block + .btn-block {
        margin-left: 0;
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}


// Modal sizes
.modal-sm {
    @include media-query(lap-and-up){
        width: $modal-sm;
    }
}

.modal-lg {
    @include media-query(desk){
        width: $modal-lg;
    }
}


.super-modal {
    z-index: 120 !important; // in case of display above one another
}


button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}
.close {
    float: right;
    font-size: ($inuit-base-font-size * 2);
    @include font-weight(bold);
    line-height: 1;
    color: black;
    text-shadow: 0 1px 0 white;
    opacity: .25;

    @include attention() {
        color: black;
        text-decoration: none;
        cursor: pointer;
        opacity: .5;
    }

    // Additional properties for button version
    // iOS requires the button element instead of an anchor tag.
    // If you want the anchor version, it requires `href="#"`.
    // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

}

// Map container overlay

.modal-map__container {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-fixed;
    background-color: rgba(0, 0, 0, .5);
}
.modal-map__container .modal-map__inner-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #fff;
    overflow-y: auto;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    @include media-query(lap-and-up){
        width: 80%;
        height: 80%;
        margin: 5% auto;
    }
}
.modal-map__container h4 {
    color: $color-main;
    margin: 0 34px 0 0;
}
.modal-map__container .modal-map__map-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    margin: 0;
    overflow: hidden;
}
.modal-map__container .modal-map__simple-loading {
    position: absolute;
    top: 40%;
    @include font-weight(bold);
}
.modal-map__container .modal-map__map {
    height: 100%;
    visibility: hidden;
}

// Modal new

.modal-general {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0%;
    top: 0;
    right: 0;
    bottom: 0;
    transition: opacity 1s ease;
    z-index: 999;
    margin-bottom: 0!important;
    background-color: rgba(0,0,0,.5);
}
.modal-general__content {
    position: relative;
    border-radius: 10px;
    background-color: white;
    top: 15%;
    width: 90%;
    min-height: 250px;
    margin: auto;
    padding: 30px 37px;
    @include media-query(desk) {
        width: 65%;
    }
}
.modal-general__title {
    color: #444;
    font-weight: bold;
}
.modal-general__btnClose {
    position: absolute;
    right: 2%;
    top: 3%;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    color: #444;
    font-size: 28px;
}
.modal-general__btn {
    margin-top: 4em;
    text-align: center;
    .btn-prev {
        font-weight: bold;
        margin-right: 9em;
    }
    .btn-adviser {
        border-radius: 10px;
        padding: 14px 10px;
        background-color: #00c382;
    }
}
.modal-filter__mobile {
    @include media-query (palm) {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: white;
        z-index: 100;
        transition: opacity 1s ease;
    }
}
.modal-general__active {
    visibility: visible;
    opacity: 1;
}
.modalErrorUrl {
    .modal-general__content {
        @include media-query (desk) {
            width: 950px;
            padding: 48px 65px 37px;
        }
        @include media-query (palm) {
            top: 5%;
        }
    }
    .js-modalErrorUrlContent {
        font-size: 18px;
        margin-bottom: 35px;
        line-height: 27px;
    }
    .modal-general__btnClose {
        font-size: 40px;
    }
    .modal_error-btns {
        display: flex;
        justify-content: space-around;
        @include media-query (portable) {
            display: block;
            a {
                margin-bottom: 10px;
            }
        }
        @media screen and (min-width:1024px) and (max-width:1099px){
            display: block;
            a {
                margin-bottom: 10px;
            }
        }
    }
    .btn-adviser {
        border-radius: 13px;
        padding: 16px 35px;
        background-color: #00c382;
        @include media-query (portable) {
            width: 100%;
        }
        @media screen and (min-width:1024px) and (max-width:1099px){
            width: 100%;
        }
    }
    .btn-prev {
        border-radius: 13px;
        padding: 16px 98px;
        @include media-query (portable) {
            width: 100%;
        }
        @media screen and (min-width:1024px) and (max-width:1099px){
            width: 100%;
        }
    }
    .madal_error-footer {
        text-align: center;
        margin-top: 32px;
        .searchNewDate {
            font-size: 19px;
            color: $color-main;
            cursor: pointer;
        }
    }
}
.modalLogin {
    .modal-body {
        padding: 21px 30px 23px;
    }
    .modal-title {
        text-align: center;
        font-size: 22px;
        @include media-query(palm) {
            font-size: 18px;
        }
    }
    .modal-header {
        padding: 13px;
    }
    button {
        font-size: 25px;
        position: absolute;
        right: 3%;
        top: 3%;
    }
    .close {
        opacity: initial;
        text-shadow: none;
        font-weight: normal;
        color: $color-nav-main-item-active;
    }
    .btn-next {
        background-color: #21C284;
        border-radius: 24px;
        padding: 12px;
        font-size: 14px;
        margin-top: 12px;
        margin-bottom: 15px;
    }
    .username {
        padding-left: 17px;        
        font-size: 18px;
    }
    .btnredes {
        padding: 15px;
        text-align: center;
        .icon {
            background-repeat: no-repeat;
            width: 30px;
            height: 25px;
        }
    }
    .loginFacebook {
        border: 2px solid #2477CA;
        border-radius: 24px;
        margin-bottom: 11px;
    }
    .google_content {
        border: 2px solid #E8453C;        
    }
    .signUp {
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        margin-top: 30px;        
    }
    .text-greenLight {
        color: #21c284;
        cursor: pointer;
    }
    .img-person {
        margin-right: 20px;
    }   
    .errorLabel {
        color: red;
        text-align: initial;
    }    
    .password_login {
        input[type=password]::-ms-reveal,
        input[type=password]::-ms-clear {
        display: none;
        }
    }
    .btn-success {
        background-color: #21c284;
        padding: 18px;
        border-radius: 10px;
    }
    .content-login {
        margin-bottom: 12px;
    }
    .prevLogin {
        @include media-query(palm) {
            top: -41px;
        }
    }
}
#js-modalRegister  {
    @include media-query(palm) {
        padding: 10px;
    }
    .modal-dialog {
        @include media-query (palm) {
            margin: 0;       
        }
        @include media-query (desk) {
            width: 24%;
        }
    }
    .close {
        top: 1%;
    }
}