///*------------------------------------*\
//    #DETAIL-FLIGHT
//\*------------------------------------*/

.detail-flight__content {
    // @include span(4 of 6);
    width: calc((4 / 6) * 100%);
    margin-right: 1%;
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.detail-flight__sidebar {
    @include media-query(desk) {
        // @include span(2 of 6);
        width: calc((2 / 6) * 100%);
        margin-top: 6%;
    }
}
.reconfirmModal{
    height:320px;
    @include media-query(palm){
        height:370px;
    }
    @media screen and (max-width:320px) {
        height:420px;
    }
}
.vivaPalm{
    @include media-query(palm){
        display:none;
    }
}
.vivaPalmSubmit{
    color: white;
    padding: 8px 5%;
    margin-bottom: 13px;
    width: 40%;
    float: right;
    font-size: 80%;
    font-weight: bold;
    display:none;
    @include media-query(palm){
        display:block;
    }
}
.familyCol{
    width: 50%;
    float: left;
    text-align: center;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm){
        width: 50%;
        text-align: left;
    }
}
.textfamilyCol{
    width: 33%;
    /*float: left;*/
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm){
        width: 100%;
    }
}
.text-info{
    padding-top: 45px;
}
.farefamilyform{
    border-radius:10px;
    line-height: normal;
    border: 0px solid;
}
.farefamilyformViva{
    border-radius:10px;
    line-height: normal;
    border-width: 0px 0px 2px 0px;
    margin-bottom:10px;
}
.farefamilyformDetail{
    border-radius:10px;
    line-height: normal;
    border: 0px solid;
    float: left;
    width: 33%;
}
.farefamilyformtext{
    margin-top: 30px;
    line-height: normal;
    border: 0px solid;
}
.farefamilyform:hover{

    transition-duration: 0.2s;
    transition-delay: 0.2s;
    /*color: rgba(255, 255, 255, 1) !important;*/
    border: 2px solid;
    /*transition: all 0.2s ease;*/
}
.farefamily{
    padding-bottom: 90px;

    @include media-query(palm){
        padding-bottom: 800px;
        margin-bottom: 5px;
    }

}

.farefamily-viva{
    padding-bottom: 40px;

    @include media-query(palm){
        padding-bottom: 650px;
        margin-bottom: 5px;
    }

}
.AvFamily{
    padding-bottom: 460px;

    @include media-query(palm){
        padding-bottom: 880px;
        margin-bottom: 5px;
    }

}
.togglefarefamily{
    padding-bottom: 350px;

    @include media-query(palm){
        padding-bottom: 700px;
        margin-bottom: 5px;
    }

}
.nextFamily3{

    @include media-query(palm){
        width: 100%;  
    }
}
.nextFamily2{
    float: right;
    width: 50%;
    @include media-query(palm){
        width: 100%;  
    }
}
.nextFamily{
    float: right;
    width: 66% !important;
    @include media-query(palm){
        width: 100% !important;  
    }
}
.farefamily-btn{
    color: white;
    padding: 8px 10%;
    margin-bottom: 13px;
    width: 90%;
    font-size: 80%;
    font-weight: bold;
}
.farefamily-btn:hover{
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 1s ease;
}
.payoutExtra_modal{
    width: 97% !important;
    float: left;
    /* width: 100%; */
    height: 220px;
    padding-top: 0px;
    padding-left: 15px;

    @include media-query(palm){
        width: 95% !important;
    }
}
.detail-flight__title {
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__label {
    padding: $inuit-base-spacing-unit--small 0 0;
}
.detail-flight__airline-icon {
    height: $inuit-base-spacing-unit--large;
    margin-left: $inuit-base-spacing-unit--small;
}
.detail-flight__trip {
    margin-bottom: $inuit-base-spacing-unit--tiny;
    padding: $inuit-base-spacing-unit--tiny 0;
    border-radius: $base-round;
}
.detail-flight__trip--bg {
    background-color: lighten($color-detail-main, 47%);
}
.detail-flight__col-city {
    // @include span(2 of 5);
    width: calc((2 / 5) * 100%);
}
.detail-flight__col-icon {
    // @include span(1 of 5);
    width: calc((1 / 5) * 100%);
}
.detail-flight__stopover {
    padding-bottom: $inuit-base-spacing-unit--small;
    border-top: 0;
    border-bottom: 1px dashed $color-frame-border;
    border-radius: 0;
    background-color: lighten($color-detail-main, 47%);
    background-clip: padding-box;
}
.detail-flight__more {
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__more .delay-info {
    @include font-size(11px);
}

// Other detail sections
.detail-flight__rate-price,
.detail-flight__rate-detail {
    // @include span(1 of 2);
    width: calc((1 / 2) * 100%);
    margin-bottom: $inuit-base-spacing-unit--small;
}
.detail-flight__condition-title,
.detail-flight__condition-detail {
    // @include span(1 of 2);
    width: calc((1 / 2) * 100%);
    margin-bottom: $inuit-base-spacing-unit;
}


.detail-flight__condition-title {
    margin-top: $inuit-base-spacing-unit--small;
}

.detail-flight__passenger-title {
    // @include span(1 of 3);
    width: calc((1 / 3) * 100%);
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__passenger-detail {
    // @include span(2 of 3);
    width: calc((2 / 3) * 100%);
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__passenger-detail__number {
    margin: 0 $inuit-base-spacing-unit--tiny;
}
.detail-flight__price-change {
    background-color: rgba(0, 0, 0, 0.8);
}

// Itinerary map section
.map-container {
    width:100%;
}
.map-container h4 {
    margin-bottom: $inuit-base-spacing-unit--small;
    color: $color-main;
}
.map-container #map {
    height: 180px;
    margin-bottom: $inuit-base-spacing-unit;
}
.map-container .itinerary_flights {
    display: none;
}
.map-container #map .flight_marker {
    position: absolute;
    z-index: 999999;
}
.map-container #map .flight_marker .counter {
    position: absolute;
    top: 6.5px;
    left: 5.5px;
    width: 13px;
    line-height: 14px;
    text-align: center;
    font-weight: 900;
    font-size: 140%;
    font-family: 'Open Sans';
    color: #fbfbfb;
}
.map-container #map .flight_marker .counter img.multiCounts {
    position: absolute;
    top: 2px;
    left: 2px;
}
.map-container #map .flight_infoBox {
    padding: 5px 10px 5px 10px;
    font-family: 'Open Sans';
}
.map-container #map .flight_infoBox ~ .flight_infoBox  {
    border-top: 1px dashed #333;
}
.map-container #map .flight_infoBox .flight_stopover {
    font-style: italic;
}
.map-container #map .flight_infoBox .flight_placeName {
    font-weight: 900;
}
.container_modal{
    width: 100%;
}
.description_content_modal{
    float: left;
    width: 80%;
    height: 220px;
    padding-top: 40px;
    padding-left: 15px;
}
.image_box_modal{
    padding-bottom: 15px;
}
.b_modal_confirm{
    background-color: #3f990f !important;
    border-color: #3f990f !important;
}
.b_modal_cancel{
    background-color: #e62d13 !important;
    border-color: #e62d13 !important;
}
.image_modal{
    margin-left: 20px;
    margin-bottom: 5px;
}

.description_content_modal_mobile{
    float: left;
    width: 97%;
    height: 220px;
    padding-left: 6px;
    margin-bottom: 10px;
}
.image_box_modal_mobile{
    padding-bottom: 15px;
    padding-left: 7px;
}
.image_modal_mobile{
    margin-bottom: 5px;
    width: 70%;
    padding-left: 68px;
}

.bdashed{
    padding-top: 18px;
    border-top: 1px dashed rgba(102, 102, 102, 0.59);
}

.flight-scale-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}
#flight-scales{
    cursor: pointer;
}
.flight-scale-text {
    display: block;
    padding-bottom: 3px;
    line-height: 25px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.flight-scale {
    display: block;
    top: -5px;
    width: 50px;
    border-top: 1px solid #444444;
    position: relative;
}

.flight-route-scale {
    background-color: #3f990f;
    border: 1px solid #3f990f;
    bottom: -2px;
    height: 5px;
    position: absolute;
    width: 5px;
    border-radius: 5px;
}

/* DIRECTO */
.flight-scale.flight-scale-0 {
    border-top: 1px solid #005eb8;
}
/*UNA ESCALA*/
.flight-scale-1 .flight-route-scale-1 {
    left: 45%;
}
/* DOS ESCALAS */
.flight-scale-2 .flight-route-scale-1 {
    left: 30%;
}
.flight-scale-2 .flight-route-scale-2 {
    left: 60%;
}
/* TRES ESCALAS */
.flight-scale-3 .flight-route-scale-1 {
    left: 20%;
}
.flight-scale-3 .flight-route-scale-2 {
    left: 45%;
}
.flight-scale-3 .flight-route-scale-3 {
    left: 70.5%;
}
/* CUATRO ESCALAS */
.flight-scale-4 .flight-route-scale-1 {
    left: 15%;
}
.flight-scale-4 .flight-route-scale-2 {
    left: 35%;
}
.flight-scale-4 .flight-route-scale-3 {
    left: 55%;
}
.flight-scale-4 .flight-route-scale-4 {
    left: 75%;
}
/* CINCO ESCALAS */
.flight-scale-5 .flight-route-scale-1 {
    left: 10%;
}
.flight-scale-5 .flight-route-scale-2 {
    left: 28%;
}
.flight-scale-5 .flight-route-scale-3 {
    left: 45%;
}
.flight-scale-5 .flight-route-scale-4 {
    left: 63%;
}
.flight-scale-5 .flight-route-scale-5 {
    left: 81%;
}
/* SEIS ESCALAS */
.flight-scale-6 .flight-route-scale-1 {
    left: 20%;
}
.flight-scale-6 .flight-route-scale-2 {
    left: 38%;
}
.flight-scale-6 .flight-route-scale-3 {
    left: 54%;
}
.flight-scale-6 .flight-route-scale-4 {
    left: 70%;
}
.flight-scale-6 .flight-route-scale-5 {
    left: 85%;
}
.flight-scale-6 .flight-route-scale-6 {
    left: 4%;
}
// Detail flight hopper
.hopperDetailConditions {
    color: #444;
    margin-left: 0;
    margin-right: 0;
    b,
    h3,
    .icon {
        color: $color-main;
    }
}
.promotional-air {
    display: flex;
    justify-content: space-between;
    .frame {
        width: 49%;
    }
    .promotionalLabel {
        width: 146%;
        font-size: 12px;
        margin-top: 3px;
    }
    #promotionalAir {
        border: 1px solid rgba(196, 196, 196, 0.51);
        border-radius: 6px;
        // width: 123px;
        // margin-top: 6px;
    }
}

.baggage-item {
    display: flex;
    align-items: center;
}

.baggage-item:not(:first-child) {
    border-top: 1px dashed #C4C4C4;
}

.baggage-item {
    padding: 12px;
}

.baggage-item-content {
    padding-left: 7px !important;
}

.baggage-item-title {
    font-weight: 700;
    font-size: 14px;
}

.included-bag :is(path, div) {
    fill: #005CB9;
    color: #000000;
}

.not-included-bag :is(path, div) {
    fill: #C4C4C4;
    color: #717171;
}

.bagagge-item-description {
    font-weight: 400;
    font-size: 12px;
}

.bagagge-icons {
    cursor: pointer;
}

.white-color {
    fill : white !important;
}