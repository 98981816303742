///*------------------------------------*\
//    #RESUME-FLIGHT
//\*------------------------------------*/

.resume-flight__col-large {
    @include span(3 of 7);
}
.resume-flight__col-small {
    @include span(1 of 7);
}






.resume-flight__airline-icon {
    height: $inuit-base-spacing-unit;
    max-width: $inuit-base-spacing-unit--large;
    @include media-query(lap-and-up) {
        height: $inuit-base-spacing-unit--large;
        max-width: $inuit-base-spacing-unit--large*2;
    }
}

.resume-flight__ticket__details-toggle {
    & .icon {
        font-size: $inuit-base-spacing-unit;
        vertical-align: bottom;
        font-weight: 700;
    }
    & .link-span {
        @include attention() {
            .icon-btn {
                color: darken($color-links, 10%);
                border-color: darken($color-links, 10%);
            }
        }
    }
    @include media-query(palm) {
        padding-top: $inuit-base-spacing-unit;
        text-align: center;
    }
    @include media-query(lap-and-up) {
        position: absolute;
        right: $inuit-base-spacing-unit;
        top: 46px;
    }
}

.resume-flight__itinerary-duration {
    margin-top: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        margin-top: $inuit-base-spacing-unit;
    }
}

.resume-flight__itinerary-plane {
    margin: $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small 0 0;
}
.resume-flight__ticket-itinerary .icon_bundle_flight,
.resume-flight__ticket-itinerary .icon_dart_right{
    font-size: $inuit-base-spacing-unit+10px;
    @include media-query(lap-and-up) {
        font-size: $inuit-base-spacing-unit*2;
    }
}

.resume-flight__itinerary__label,
.resume-flight__itinerary__airport-code,
.resume-flight__itinerary__duration {
    color: rgb(150,150,150);
}
.resume-flight__itinerary__time {
    @include media-query(lap-and-up) {
        font-size: 1.6rem;
    }
}

/*.resume-flight__airline-info {
    padding: 0 $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        padding: $inuit-base-spacing-unit--small;
    }
}*/

.resume-flight__itinerary-destination {
    padding: 0 $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        padding: 0 $inuit-base-spacing-unit;
    }
}

.resume-flight__ticket-flight {
    border-top: 1px solid $color-frame-border;
}

.resume-flight__ticket {
    @include media-query(palm) {
        margin-right: -$inuit-base-spacing-unit--small;
        margin-left: -$inuit-base-spacing-unit--small;
    }
}

.resume-flight__ticket-details__title {
    background-color: $color-frame-bg;
    @include font-size($inuit-base-spacing-unit);
}

.resume-flight__info-flight {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit;
    }
}

@media print {
    .resume-flight__ticket__details-toggle {
        display: none;
    }
    .resume-flight__ticket-details {
        display: block !important;
    }
    .resume-flight__ticket a[href]:after {
        content: none !important;
    }
    .resume-flight__voucher-download {
        display: none;
    }
}