///*------------------------------------*\
//    #DISPO-MULTI
//\*------------------------------------*/

.dispo-multi__sidebar .search__col-flight-date:last-of-type .form-group {
    margin-bottom: 0;
}

// Selection Resume //

.dispo-multi__select-resume {
    width: 100%;
    min-height: $inuit-base-spacing-unit*4;
    display: none;
    margin-bottom: $inuit-base-spacing-unit;
    border-radius: $base-round;
    box-shadow: 0px 4px 4px 0px rgba(70, 67, 67, 0.25);
    border-radius: 10px;
    &.responsive-display {
        @include media-query(lap-and-up) {
            display: table;
        }
        @include media-query(palm) {
            display: block;
        }
    }
    &.fixed {
        left: auto;
        width: 64.5% !important;
        margin-top: -4px;
        padding: 0;
        border-top: none;
        border-radius: 0 0 $base-round $base-round;
        z-index: $z-index-slidepanel;
        backface-visibility: hidden; // prevent repaint overhead on scroll event
        box-shadow: 0 1px 3px rgba(0,0,0,.1), 0 3px 6px rgba(0,0,0,.17);
        @include media-query(lap) {
            width: calc(100% - #{$inuit-base-spacing-unit*2});
        }
        @include media-query(palm) {
            display: none;
        }
    }
    & .btn--submit {
        @include media-query(palm) {
            padding: $inuit-base-spacing-unit--small;
        }
    }
    @include media-query (portable) {
        box-shadow: none;

    }
}
.dispo-multi__select-option {
    width: 33.4%;
    display: table-cell;
    position: relative;
    vertical-align: middle;
    text-align: center;
    word-break: break-word;
    transition: box-shadow 800ms;
    padding: 20px;
    // &:not(:last-child) {
    //     border-right: 1px dashed $color-frame-border;
    // }
    &:first-child {
        border-radius: $base-round+5px 0 0 $base-round;
        .dispo-multi__select-resume.fixed & {
            border-radius: 0 0 0 $base-round;
        }
    }
    &:last-child {
        border-radius: 0 $base-round $base-round 0;
        .dispo-multi__select-resume.fixed & {
            border-radius: 0 0 $base-round 0;
        }
    }
    @include media-query (lap) {
        display: table-caption;
        width: 100%;
    }
    // &.is-active {
    //     background-color: $color-frame-bg;
    // }
}
.dispo-multi__resume-container {
    background: transparent no-repeat center; // transparent color as a fallback
    background-size: cover;
    & p {
        margin-bottom: 0;
    }
    // .dispo-multi__select-option.is-set & {
    //     margin: $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small $inuit-base-spacing-unit*2 $inuit-base-spacing-unit--small;
    // }
    .dispo-multi__select-option.has-image & {
        background-color: rgba(0,0,0,.4);
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0,0,0,.6);
    }
}
.resume__inner-wrapper {
    position: relative;
    .dispo-multi__select-option.is-set & {
        // padding: $inuit-base-spacing-unit--small;
        min-height: 130px;
        @include media-query (palm) {
            min-height: 140px;
        }
    }
    .dispo-multi__select-option.has-image & {
        background-color: rgba(0,0,0,.4);
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0,0,0,.6);
    }
}

// resume contents //
// resume content -> hotel
.resume-hotel__title {
    color: #0E4583;
    font-weight: bold;
    text-align: left;
    margin-top: 5px;
    font-size: 16px;
    margin-bottom: 20px !important;
}
.resume-hotel__stars {
  height: 18px;
  filter: hue-rotate(180deg);
}
// .resume-hotel__details-wrapper {
//     margin: 0 $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small;
// }
.resume-hotel__details-elements {
    text-align: left;
    font-size: 12px;
    & p {
        margin-top: calc($inuit-base-spacing-unit--small/2);
    }
    .info-hotel {
        vertical-align: super;
        margin-left: 6px;
        font-weight: 600;
    }
}
.resume-hotel__rooms {
    @include media-query (portable) {
        width: 110%;
        position: absolute;
        right: -122px;
        top: 35px;
        .resume-hotel__room {            
            width: 100%;
            div {
                width: 25%;
                float: left;
            }
        }
    }
    @include media-query (lap) {
        right: -194px;
    }
}
.resume-hotel__room {
    @include span(1 of 3);
    padding-left: 0;
    &:last-child {
        padding-right: 0;
    }
    .info-hotel {
        vertical-align: super;
        margin-left: 6px;
        font-weight: 600;
    }
    div {
        margin-top: 2px;
    }
}
// resume content -> flight
.resume-flight__container {
    position: absolute;
    top: 50%;
    width: 100%;
    display: block;
    transform: translateY(-50%);
    .resume-hotel__title {
        margin: 10px 0 10px 17px !important;
        @include media-query (palm) {            
            margin: 10px 0 12px 9px !important;
        }
    }
    @include media-query (palm) {
        top: 37%;
    }
}
.resume-flight__segment {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-bottom: $inuit-base-spacing-unit--small;
    & > span {
        display: table-cell;
        vertical-align: bottom;
        @include media-query(palm) {
            float: left;
            display: inline-block;            
        }
    }
    div {
        display: grid;
    }
    strong {            
        margin-bottom: 4px;
    }
    @include media-query(palm) {      
        strong {            
            margin-bottom: 6px;
        }
    }
}
.resume-flight-date {
    font-size: 12px;
    @include media-query (palm) {
        font-size: 10px;
    }
}
.resume-flight__segment-desc {
    width: 25%;
    vertical-align: middle;
    color: rgba(0,0,0,.4);
    text-shadow: none;
    @include media-query(palm) {
        width: 100%;
        margin-bottom: calc($inuit-base-spacing-unit--small/2);
    }
    & > * {
        display: block;
        @include media-query(palm) {
            display: inline-block;
            margin-right: calc($inuit-base-spacing-unit--small/2);
        }
    }
    & .segment-date {
        font-size: 11px;
        line-height: $inuit-base-spacing-unit;
        color: black;
    }
    .dispo-multi__select-option.has-image & {
        color: rgba(255,255,255,.7);
    }
}
.resume-flight__segment-details {
    & > * {
        display: block;
    }
    & strong {
        @include media-query(palm) {
            font-size: 15px;
        }
    }
    & > span {
        font-size: 11px;
        line-height: $inuit-base-spacing-unit;
    }
}
.resume-flight__segment-separator {
    & > .icon {
        display: block;
    }
}
.resume-flight__airline {
    &:not(:first-of-type) {
        padding-left: calc($inuit-base-spacing-unit--small/2);
    }
    & img {
        width: 16px;
        max-height: 16px;
        background: rgba(255,255,255,.3);
        border-radius: 50%;
    }
}
.resume-option__price {
    margin: 0 0 calc($inuit-base-spacing-unit--small/2) $inuit-base-spacing-unit--small;
    text-align: left;
    font-size: 12px;
    & strong {
        font-size: 14px;
    }
}

// switch options //
.dispo-multi__select-switch {
    position: relative;
    display: inline-block;
    width: calc(100% - #{$inuit-base-spacing-unit});
    min-height: $inuit-base-spacing-unit + $inuit-base-spacing-unit--small;
    border-bottom: 2px solid darken($color-main, 12%);
    background: $color-main no-repeat left;
    background-size: contain;
    border-radius: $base-round;
    color: #fff;
    &:hover {
        background-color: darken($color-main, 4%);
    }
    &:active {
        border-bottom-width: 0;
        background-color: darken($color-main, 10%);
    }
    @include attention() {
        cursor: pointer;
    }
    &.select-init {
        vertical-align: bottom;
        min-height: $inuit-base-spacing-unit*2;
    }
    .dispo-multi__select-option.is-set & {
        position: absolute;
        bottom: 5px;
        left: $inuit-base-spacing-unit--small;
    }
    .dispo-multi__select-option.is-active & {
        border-bottom-width: 0;
        cursor: default;
        background-color: darken($color-main, 8%);
        @include attention() {
            background-color: darken($color-main, 8%);
        }
    }
}
.dispo-multi__select-switch.select-init.hotel-switch {
    background-image: url(/assets/aviatur_assets/img/navbar/icon-hotel.svg);
}
.dispo-multi__select-switch.select-init.flight-switch {
    background-image: url(/assets/aviatur_assets/img/navbar/icon-flight.svg);
}
.select-switch__text {
    position: absolute;
    top: 50%;
    width: 100%;
    display: block;
    transform: perspective(1px) translateY(-50%);
}
/*.select-switch__loader {
    display: none;
    position: absolute;
    padding: 0 .1em;
}*/

// resume submit //
.dispo-multi__select-submit {
    margin: 0 $inuit-base-spacing-unit--small;
    padding: $inuit-base-spacing-unit--small 0;
    @include media-query (portable) {
        .btn--submit {
            border-radius: 10px;
            padding: 12px;
        }
    }
}
.dispo-multi__submit-info {
    font-size: 12px;
    margin-bottom: 5px;
    color: rgba(0,0,0,.4);
    .dispo-multi__select-option.is-active & {
        display: none;
    }
}
.dispo-multi__select-submit .btn.inactive {
    background-color: #e9e9e9;
    border: 1px solid #e1e1e1;
    box-shadow: none;
    cursor: default;
    @include attention() {
        background-color: #e9e9e9;
        border: 1px solid #e1e1e1;
        box-shadow: none;
        cursor: default;
    }
}

.dispo-multi__results .dispo-shared__recommendations .selected {
    border: 2px solid #999;
}

.dispo-multi__selected-label {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px $inuit-base-spacing-unit--small;
    border-radius: $base-round 0 0 0;
    color: #fff;
    font-weight: 600;
    background-color: #2b912b;
    @include media-query(palm) {
        top: unset;
        bottom: 0;
        border-radius: $base-round 0 3px 0;
    }
}

.multi-loader-message {
    padding-top: 20%;
    margin-left: 25%;
    color: #009bf8;
    @include media-query(palm) {
        padding-top: 15%;
        margin-left: 30%;
    }
}

.multi-loader-message-error {
    padding-top: 20%;
    margin-left: 25%;
    color: #FF333C;
    @include media-query(palm) {
        padding-top: 15%;
        margin-left: 30%;
    }
}

// Mobile is awesome //
@include media-query(palm) {
    .dispo-multi__select-option {
        display: block;
        width: 100%;
        padding: $inuit-base-spacing-unit--small 0;
        &:not(:last-child) {
            border-right: 0;
            border-bottom: 1px dashed $color-frame-border;
            border-radius: 0;
        }
    }
    .dispo-multi__select-option.is-set {
        position: relative;
        padding: 14px;
    }
    .resume-flight__segment-details {
        width: 40%;
    }
    .resume-flight__segment-separator {
        width: 19%;
        text-align: center;
    }
}
.selection__hotel-image {
    @include media-query (portable) {
        min-height: 58px !important;
    }
}
.selecion__active-option {
    box-shadow: -1.8px -3px 5px -2px rgba(70, 67, 67, 0.25);
    @include media-query (portable) {
        box-shadow: none;
        padding-top: 0 !important;
    }
}
.dispo-multi__select-resume  {
    .resume-search__separator {
        height: 7em;
        margin: 50px 0 18px;
    }
}
.dispo-multi__select-title {
    text-align: left;
    color: $color-main;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 0px;
    span {
        margin-right: 4px;
    }
    @include media-query (desk) {
        margin-bottom: 8px;
    }
}
.hotel-switch,
.flight-switch {
    cursor: pointer;
}
.total-multi {
    font-size: 18px;
    color: #0E4583;
    font-weight: 900;
}
.multiTotalAmount {
    margin-bottom: 70px;
    @include media-query (portable) {
        margin-bottom: 24px;
    }
}
.title-flight {
    margin-left: 6px;
    @include media-query (palm) {
        margin-left: 0;
        margin-bottom: 8px;
        color: $color-main !important;
    }
}
.numberTitle {
    padding: 1px 7px;
    border: 1px solid;
    border-radius: 20px;
    font-size: 12px;
}
.head-info_multiMobile {  
    border-bottom: 1px solid #E4E4E4;
    border-top: 1px solid #E4E4E4;
    margin-bottom: 15px;
    h6 {
        font-size: 12px;
        margin-bottom: 0;
        padding: 4px 14px 5px;
    }
    .numberTitle {
        font-size: 10px;
    }
    .resume-search__separator {
        height: 1em;
        margin: 8px 0 0px;
    }
    .select-border {
        position: absolute;
        border: 2px solid;
        border-radius: 16px;
        width: 20%;
        margin-top: 25px;
        visibility: hidden;
        opacity: 0;
    }
    .dispo-multi__select-title {
        color: #666;
        line-height: 23px;
    }
    .active {
        color: $color-main;
        .select-border {
            visibility: visible;
            opacity: 1;
        }
    }
}