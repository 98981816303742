/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 15/11/2017, 03:43:34 PM
    Author     : sergio.amaya
*/

.search__col-experience-city {
    width: 30%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search__col-experience-date {
    width: 25%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(portable) {
        @include span(1 of 2);
    }
}
.search__col-experience-passengers {
    position: relative;    
    width: 20%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search__col-experience-personalizada {
    width: 25%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

