///*------------------------------------*\
//   #DISPO-CRUISE
//\*------------------------------------*/

.dispo-cruise {
    position: relative;
}

/*
 * Ribbons! *o*
 */
.ribbon {
    position: absolute;
    z-index: 1;
    right: $inuit-base-spacing-unit;
    top: -$inuit-base-spacing-unit;
    background: #fff;
    width: 110px;
    height: 62px;
    box-shadow: rgba(0, 0, 0, .4) 0 2px 6px;
    @include media-query(palm){
        top: 80px;
    }
}
.ribbon img {
    width: 100%;
    padding: 5px 10px;
    margin: $inuit-base-spacing-unit 0;
}
.ribbon-corner-wrapper {
    position: absolute;
    left: -10px;
    bottom: -26px;
    width: calc(100% + 20px);;
    height: 26px;
    overflow: hidden;
}
.ribbon-left-corner,
.ribbon-right-corner {
    position: absolute;
    bottom: 16px;
    width: 55px;
    height: 20px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .4) 0 3px 6px;
    z-index: -1;
}
.ribbon-left-corner {
    left: 10px;
    transform: skewY(-18deg);
}
.ribbon-right-corner {
    right: 10px;
    transform: skewY(18deg);
}

.dispo-cruise .details-ticket {
    width: 54.333%;
    display: inline-block;
    margin: $inuit-base-spacing-unit--small 0 $inuit-base-spacing-unit 0;
    padding-right: $inuit-base-spacing-unit;
    @include media-query(palm){
        width: 100%;
        margin-top: $inuit-base-spacing-unit;
        padding-right: 0;
    }
}
.dispo-cruise .details-ticket p {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.dispo-cruise .details-ticket p .hidden-itinerary {
    display: none;
}

.dispo-cruise .price {
    position: relative;
    padding: 0px;
    color: #444;
    background: transparent;
    //@include blueGradient();
}
.dispo-cruise .price-label {
    @include font-size(14px);
    &.price-label-top {
        width: 63.66667%;
        float: left;
        padding-right: 9px;
        @include media-query(palm){
            margin-bottom: calc($inuit-base-spacing-unit--small/2);
        }
    }
}
.dispo-cruise .price-show-more {
    color:$color-brand;
    cursor: pointer;
}
.dispo-cruise .price-conditions {
    color:#444;
}
.dispo-cruise .price-value {
    color: #444;
    font-size: 28px;
    width: 63.66667%;
    float: left;
    padding-right: 9px;
    @include font-weight(bold);
    @include media-query(palm){
        width: 100%;
    }
}
.dispo-cruise .price p {
    margin: 0;
}
.dispo-cruise .price .btn--submit {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px 0 0 0;
    width: 35.5%;
    @include media-query(palm){
        position: relative;
        width: 100%;
        margin: 0px 0px 10px;
    }
}

.dispo-cruise .cruise-img img {
    width: 45%;
    position: absolute;
    right: 0;
    top: 0px;
    border-radius: 5px;
    @include media-query(palm){
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: $inuit-base-spacing-unit--small;
    }
}

/*
 * Modal view for full itinerary
 */
.dispo-cruise__itinerary-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-fixed;
    background-color: rgba(0, 0, 0, .5);
}
.dispo-cruise__itinerary-modal .inner-wrapper {
    position: relative;
    width: 66%;
    margin: 5% auto;
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.dispo-cruise__itinerary-modal .itinerary-wrapper {
    padding: $inuit-base-spacing-unit;
}
.dispo-cruise__left-column {
    width: 54.333%;
    float: left;
    padding-right: 9px;
    @include media-query(palm){
        float: none;
        width: 100%;
    }
}

/*
 * Badge day and night
 */

%dispo-cruise__badge-day-and-night {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: white;
    width: auto;
    height: 34px;
    border-radius: 8px;
    padding: 10px;
    font-weight: 600;
    font-size: 12px;
    transition: .3s;
    margin-right: 20px;
}

.dispo-cruise__badge-night {
    @extend %dispo-cruise__badge-day-and-night;
    background: linear-gradient(to right, #053e62 0%, 15%, rgb(18,18, 19) 85%,#010e16 100%);
}

.dispo-cruise__badge-day {
    @extend %dispo-cruise__badge-day-and-night;
    background: linear-gradient(to right, rgba(241, 159, 7, 0.883) 0%, 70%, rgb(241,163, 19) 85%,#ffa602 100%);
}
