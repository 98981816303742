///*------------------------------------*\
//    #FIXED
//\*------------------------------------*/

.fixed {
    background-color: white;
    position: fixed;
    top: 0;
    z-index: $z-index-fixed;
    width: 100%;   
    //border: 3px solid #d0d7dc;
    box-shadow: 0px -0.1px 20px 0px #5a5d5a;
    left: 0;
    padding: 9px;
    backface-visibility: hidden; // prevent repaint overhead on scroll event
}
.fixed--bottom {
    @extend .fixed;
    top: auto;
    bottom: 0;
    border-bottom-width: 0;
}
.mobile-flight-confirm {
    animation-duration: 0.8s;
    animation-name: animateSubmitConfirm;
    animation-iteration-count: 5;
    animation-direction: alternate;
}
@keyframes animateSubmitConfirm {
    from {
        background: rgba(0,0,0,0);
        color: #009bf8;
        box-shadow: inset 0 0 0 2px #007bc5;
    }
}
.mobile-flight-buy {
    animation-duration: 0.5s;
    animation-name: animateSubmitbuy;
    animation-iteration-count: 5;
    animation-direction: alternate;
}
@keyframes animateSubmitbuy {
    from {
        background: rgba(0,0,0,0);
        color: #3f990f;
        box-shadow: inset 0 0 0 2px #3f990f;
    }
}
.selectionMobileTarget{
    padding-right:0;
}
.selectionPrice{
    padding-top: 20px;
}
.btn-confirm-buy{
    margin-top:10px;
}