///*------------------------------------*\
//    #TYPES
//\*------------------------------------*/

.type-main {
    color: $color-type-main;
}
.type-secondary {
    color: $color-secondary;
}

.type-accent {
    color: $color-type-accent;
}
.type-grey {
    color: $color-type-grey; 
}
.type-positive {
    color: $color-type-positive;
}
.type-negative {
    color: $color-type-negative;
}
.type-white {
    color: white;
}
.bold {
    @include font-weight(bold);
}
.error {
    color: red;
}
.uppercase {
    text-transform: uppercase;
}

