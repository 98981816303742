/*------------------------------------*\
    #SHOW-HIDE
\*------------------------------------*/

@mixin hide() {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.hide { @include hide(); }

@include media-query(palm) {
    .hide-palm { @include hide(); }
	.promo-banner{ /*@include hide();*/ }
	}

@include media-query(lap) {
    .hide-lap { @include hide(); }
}

@include media-query(lap-and-up) {
    .hide-lap-and-up { @include hide(); }
}

@include media-query(portable) {
    .hide-portable { @include hide(); }
}

@include media-query(desk) {
    .hide-desk { @include hide(); }
}

@mixin hidden() {
    display: none;
}

.hidden-all { @include hidden(); }

@include media-query(palm) {
    .hidden-palm { @include hidden(); }
}

@include media-query(lap) {
    .hidden-lap { @include hidden(); }
}

@include media-query(lap-and-up) {
    .hidden-lap-and-up { @include hidden(); }
}

@include media-query(portable) {
    .hidden-portable { @include hidden(); }
}

@include media-query(desk) {
    .hidden-desk { @include hidden(); }
}
.invisible {
    opacity: 0;
}

.overflow-ellipsis {
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    // line-height: initial;
}

/*.whatsapp{
	display:none;
}
@media screen and (max-width: 719px){
.whatsapp{
    display: inline-block;
	padding: 1%;
    -webkit-box-shadow: inset 25px 0 25px -25px rgba(0, 0, 0, .45), inset -25px 0 25px -25px rgba(0, 0, 0, .45);
    -moz-box-shadow: inset 25px 0 25px -25px rgba(0, 0, 0, .45), inset -25px 0 25px -25px rgba(0, 0, 0, .45);
    box-shadow: inset 25px 0 25px -25px rgba(0, 0, 0, .45), inset -25px 0 25px -25px rgba(0, 0, 0, .45);
}
}*/