///*------------------------------------*\
//    #DETAIL-GIFT-VOUCHER
//\*------------------------------------*/

.voucherOption-box{
    border-spacing: 4px;
    border-collapse: separate;
}

.giftVoucher-box {
    border-radius: 20px;
    border: 1px solid #0d4f77 !important;
    height: 95px; ; 
    text-align: center;
    vertical-align: middle !important;
    padding-top: 20px !important;
}

.giftVoucher-selected { 
    border: 2px solid #0d4f77 !important;
}

.giftVoucher-box:hover { 
    border: 2px solid #0d4f77 !important;
}

.giftVoucher-input{
    text-align: center;
    margin-bottom: 20px;
}

.giftValue-input{
    width: 150px !important;
}

.tooltip-gvbox{
    left: 60% !important;
}

.tooltip-gvstyle{
    display: block !important;
}

.detail-voucher__fares-table {
    margin-bottom: $inuit-base-spacing-unit;
}

.type-label{
    color: #0d4f77;
}

.bold-label{
    font-weight: bold;
}

.voucher-fares{
    width: 45%;
    float: left;
    margin-left: 2px;
    margin-right: 28px;
}

.font-size-label{
    font-size: 14px;
}

.option-selector{
    margin-bottom: 10px;
}

.mobile-gvupseparator{
    margin-top: 15px;
}

.gv-image-mobile-container{
    margin-bottom: 10px;
}

// @media screen and (max-width:360px) {
//     width: 50% !important;
//     margin-top: 0px; 
//     padding-top:    0px !important;
//     padding-left: 0px !important;
//     padding-right: 20px;
// }
// @media screen and (max-width:320px) {
//     width: 55% !important;
//     margin-top: 0px; 
//     padding-top: 25px !important;
//     padding-left: 40px !important;
//     padding-right: 20px;
// }
// @include media-query(palm) {    
//     width: 85% ;
//     margin-top: 0px; 
//     padding-top: 20px;
//     padding-left: 20px;
// }