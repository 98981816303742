/*------------------------------------*\
    #WRAPPERS
\*------------------------------------*/

$wrapper-padding-spacing-unit: $inuit-base-spacing-unit;
$wrapper-padding-spacing-unit--small: $inuit-base-spacing-unit--small;


.wrapper,  .wrapper-content_blog {
    @include container(1200px);
    padding-right: calc($wrapper-padding-spacing-unit--small / 2);
    padding-left:  calc($wrapper-padding-spacing-unit--small / 2);
    @include media-query(lap-and-up) {
        padding-right: $wrapper-padding-spacing-unit;
        padding-left:  $wrapper-padding-spacing-unit;
    }
    @media screen and (min-width: 2048px) {
        @include container(1200px);
    }
}

.wrapper-content_blog {
    @include media-query(lap-and-up) {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.wrapper-susy {
    clear: both;
    margin-left: -$wrapper-padding-spacing-unit--small;
    margin-right: -$wrapper-padding-spacing-unit--small;
}
.wrapper-susy:before, .wrapper-susy:after {
    content: " ";
    display: table;
}
.wrapper-susy:after {
    clear: both;
}