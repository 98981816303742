/* Controls
---------------------------------------------------------------------- */
.controls {
    margin-bottom: 20px;
    @include media-query(palm) {
        text-align:center;
    }
}

.control {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 7px 20px;
    margin-right: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    color: #5e5f5e;
    font-size: 16px;
    border: 0;
    border-radius: 0px;
    background: transparent;
    &:after {
        content: "|";
        position: absolute;
        right: -10px;
        top: 10px;
    }

    &:hover {
        background: transparent;
        color: #333;
    }

    &:visited, &:focus {
        outline: none;
    }    
}

.controls.marcas {
    .control {
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding: 2px 10px;
        margin-right: 10px;
        text-decoration: none;
        border: 0;
        border-radius: 0px;
        background: transparent;
    }

    .control.black{
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding: 10px;
        margin-right: 10px;
        text-decoration: none;
        border: 0;
        border-radius: 4px;
        background: #ccc;
    }

    img {
        height: 30px;
    }
}

.mixitup-control-active {
    background: transparent;
    color: #0a5ea9;
    border-bottom: solid 2px #0a5ea9;
    outline: none;

    &[data-filter]:after {
        background: transparent;
    }
}

.control {
    &:first-of-type {
        border-radius: 0;
    }

    &:last-of-type {
        border-radius: 0;

        &:after {
            content: "";
        }
    }

    &[data-filter] + .control[data-sort] {
        margin-left: .75rem;
    }

    &[data-filter=".green"] {
        color: #91e6c7;
    }

    &[data-filter=".blue"] {
        color: #5ecdde;
    }

    &[data-filter=".pink"] {
        color: #d595aa;
    }

    &[data-filter="none"] {
        color: #2f2f2f;
    }
}

.control.black {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 7px 20px;
    margin-right: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    border: 0;
    border-radius: 0px;
    background: transparent;
    &:after {
        content: "|";
        position: absolute;
        right: -10px;
        top: 10px;
        display:none;    }

    &:hover {
        background: transparent;
        color: #0ee5fe;
    }

    &:visited, &:focus {
        outline: none;
    }    
}

.control.black.mixitup-control-active {
    background: transparent;
    color: #0ee5fe;
    border-bottom: solid 2px #0ee5fe;
    outline: none;
    &[data-filter]:after {
        background: transparent;
    }
}
/* Target Elements
---------------------------------------------------------------------- */
.mix, .gap {
    display: inline-block;
    vertical-align: top;
}

.mix {
    &.green {
        color: #91e6c7;
    }

    &.pink {
        color: #d595aa;
    }

    &.blue {
        color: #5ecdde;
    }
}

/* Grid Breakpoints
---------------------------------------------------------------------- */

/* 2 Columns */
.mix,
.gap {
    width: calc(100%/2 - (((2 - 1) * 1rem) / 2));
}

/* 3 Columns */
@media screen and (min-width: 541px) {
    .mix,
    .gap {
        width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
    }
}

/* 4 Columns */
@media screen and (min-width: 961px) {
    .mix,
    .gap {
        width: calc(100%/4 - (((4 - 1) * 1rem) / 4));
    }
}

/* 5 Columns */
@media screen and (min-width: 1281px) {
    .mix,
    .gap {
        width: calc(100%/5 - (((5 - 1) * 1rem) / 5));
    }
}