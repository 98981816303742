/*------------------------------------*\
    #FORM-GROUP
\*------------------------------------*/

.form-group {
    margin-bottom: $inuit-base-spacing-unit;
    position: relative;
}
.form-group_blog {
    margin-bottom: 5px;
    position: relative;
}
.form-group input.ui-autocomplete-input {
    cursor: text;
}
.form-group input,
.form-group select {

}

/*.text-input-group{
    margin: 0;
}*/
