/*------------------------------------*\
    #FORM-GROUP
\*------------------------------------*/

.form-grid {
    // @include span(1 of 3);
    width: calc((1 / 3) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        /* @include span(full); */ 
        width: calc((1 / 1) * 100%);
    }
}