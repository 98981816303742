/*------------------------------------*\
    #LABEL
\*------------------------------------*/

label {
    color: #444;
    min-height: 1.5em;
    @include font-size(14px);
    //@include font-weight(bold);
    font-weight: lighter;
    transition: .2s;
    @include attention() {
        color: $color-label-hover;
        cursor: pointer;
    }
}
