///*------------------------------------*\
//    #SEARCH FLIGHT
//\*------------------------------------*/

.search__col-helicopter-city {
    @include span(4 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search__col-helicopter-date {
    @include span(3 of 14);
    @include media-query(portable) {
        @include span(1 of 2);
    }
}

.search-helicopter__col-footer {
    @include span(1 of 4);
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search-helicopter__col-footer--toggle {
    height: $wrapper-padding-spacing-unit; 
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit;
    }
}