///*------------------------------------*\
//    #PAGE-FOOT
//\*------------------------------------*/

.page-foot__us {
    width: 100vw;
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #B5B5B5;
    @include media-query (portable) {
        padding-top: 0;
        padding-bottom: 0;
    }
}
.page-foot {
    a {
        @include attention () {
            color: #212020;
        }
    }
}
.page-foot__us * {
    color: $color-page-foot-text;
}
.page-foot__us a {
    //@include link-quiet();
    font-weight: 100;
    font-size: 14px;
}
// .page-foot__us li {
//     list-style-image: url(/assets/common_assets/img/footer/bullet.gif);
//     margin-bottom: 6px;
// }

// .col-333 {
//     position: relative;

//     // Inicialmente oculta el menú ajustando su altura a 0
//     .js-slide__ulFoot {
//         max-height: 0;
//         overflow: hidden;
//         transition: max-height 0.3s ease;
//     }

//     // Ajusta la altura máxima del menú cuando el contenedor es 'hover'
//     &:hover .js-slide__ulFoot {
//         max-height: 500px; // o ajusta a la altura máxima que desees
//     }
// }


    // Estilos comunes para todos los tamaños de pantalla

    // ...
    
    @media only screen and (min-width: 1016px){

        .col-333 {
            .a {
            color: var(--Black2, #353535);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            }
            // Inicialmente oculta el ícono
            .material-symbols-outlined {
                opacity: 1;
                transition: opacity 0.3s ease;
            }
        
            // Oculta el ícono cuando el contenedor es 'hover'
            &:hover .material-symbols-outlined {
                opacity: 0;
            }
        
            // Inicialmente oculta el menú ajustando su altura a 0
            .js-slide__ulFoot {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease;
            }
            
        
            // Ajusta la altura máxima del menú cuando el contenedor es 'hover'
            &:hover .js-slide__ulFoot {
                max-height: 500px; // o ajusta a la altura máxima que desees
            }
            @media (max-width: 767px) { // Puedes ajustar el valor según tus necesidades
                width: 600px // Ajusta el ancho al 100% para móviles
            }
        
        }

    }
    @media only screen and (max-width: 1015px){
.col-a {
    .a {
    color: var(--Black2, #353535);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
    // Inicialmente oculta el ícono
    .material-symbols-outlined {
        opacity: 1;
        transition: opacity 0.3s ease;
    }

    // Oculta el ícono cuando el contenedor es 'hover'
    &:hover .material-symbols-outlined {
        opacity: 0;
    }

    // Inicialmente oculta el menú ajustando su altura a 0
    .js-slide__ulFoot {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }
    

    // Ajusta la altura máxima del menú cuando el contenedor es 'hover'
    &:hover .js-slide__ulFoot {
        max-height: 500px; // o ajusta a la altura máxima que desees
    }
    @media (max-width: 767px) { // Puedes ajustar el valor según tus necesidades
        width: 600px // Ajusta el ancho al 100% para móviles
    }

}
}
.icono_transparente {
    opacity: -0.5!important;
}
@media only screen and (max-width: 1015px){
    .icono_transparente{
        color:#005CB9;
        position: absolute;
        right: 21px;
        opacity: 2.5!important;
    }
}



.wrapper3 {
    display: flex;

    @media (max-width: 1015px) {
        flex-direction: column; // Cambia la dirección a columna para móviles
    }
    
}

.titulo_1{
    color: var(--Primary, #005CB9);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}



.page-foot__us h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    @include media-query (palm) {
        font-size: 14px;
        margin-bottom: 0px;
    }
}
.page-foot__us__simple {
    // @include span(1 of 4);
    @include media-query(portable) {
        @include span(1 of 3);
        border-bottom: 1px solid #E1E1E1;
        padding: 25px 19px !important;
    }
}

.page-foot__us__social{
    width: 50%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
}
.page-foot__us__simple ul {
    margin-left: 0;
    color: var(--Black2, #353535);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include media-query (palm) {
        margin: 18px 0 0;
    }
}
.page-foot__us__simple a{
    color: var(--Black2, #353535);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.page-foot__us__double {
    @include span(2 of 4 last);
    @include media-query(portable) {
        @include span(2 of 3);
    }
}
.page-foot__us__double div:first-of-type {
    @include span(1 of 2);
}
.page-foot__us__double  div:last-of-type {
    @include span(1 of 2);
}
.page-foot__social {
    background-color:#042A44;
    text-align: left;
    border-top: 1px solid #B5B5B5;
    border-bottom: 1px solid #B5B5B5;
    padding: 39px 0 42px;
    .title-footer {
        font-size: 32px !important; 
            font-family: 'Roboto';
            font-weight: 500;
        color:white;
        font-size: 18px;
        margin-bottom: 25px;
    }
    a {
        i {
            font-size: 15px;
            margin-right: -5px;
            &::before {
                color: white;
                vertical-align: super;
            }
            &.icon_social_youtube {
                margin-right: 0;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    @include media-query (palm){
        text-align: center;
    }
}


.page-foot__social1 {
    background-color:#042A44;
    text-align: left;
    border-top: 1px solid #B5B5B5;
    border-bottom: 1px solid #B5B5B5;
    padding: 39px 0 42px;
    .title-footer {
        font-size: 32px;
            font-family: 'Roboto';
            font-weight: 500;
        color:white;
        font-size: 32px;
        margin-bottom: 25px;
    }
    a {
        border-radius: 26px;
        margin-right: 17px;
        padding: 2px 8px;
        i {
            font-size: 15px;
            margin-right: -5px;
            &::before {
                color: white;
                vertical-align: super;
            }
            &.icon_social_youtube {
                margin-right: 0;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    @include media-query (palm){
        text-align: center;
    }
}




.page-foot__social__icon {
    border: 0px solid $color-page-foot-text;
    border-radius: 30px;   
    //@include link-quiet();
    color: $color-page-foot-text;
}
.page-foot__content-legal {
    padding: 48px 18px 55px;
    @include media-query (palm) {
        padding-top: 32px;
    }
}
.page-foot__legal1 {
    color: var(--Black1, #222);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // width: 450px;
    // height: 76px;
    flex-shrink: 0;
}

.page-foot__legal2{
    font-size: 14px;
    margin-inline-start: 18px;
    margin-bottom: 38px;
    line-height: 20px;
    color: var(--Black1, #444);
    text-align: justify;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.derechos-del-pasajero{

}
.page-foot__contact {
    // margin: -210px 0px 0px 70px;
    ul {
        list-style: none;
        margin-left: 7.25rem;
        @include media-query (portable) {
            margin-left: 0;
            margin-top: auto!important;
        }
    }
}
.page-foot__legal a{
  color:#444;
}
.page-foot__p2p {
    padding: 0;
    text-align: center;
    // img {margin: 50px auto 0;}
}

.submenu{
    display:none;
}
.wrapper p.sp-inset--small{
    font-size: 1.5rem;
    line-height: 1.5;
}
.foot_images-legal {
    background-repeat: no-repeat;
    background-position-y: 10px;
    line-height: 40px;
    margin-right: -30px;
}
.foot_location {
    background-image: url(/assets/aviatur_assets/img/footer/location.svg);
}
.foot_message {
    background-image: url(/assets/aviatur_assets/img/footer/message.svg);
    background-position-y: 8px;
}
.foot_phone {
    background-image: url(/assets/aviatur_assets/img/footer/phone.svg);
}
.foot_whatsapp {
    background-image: url(/assets/aviatur_assets/img/footer/whatsapp.svg);
}
.foot_question {
    background-image: url(/assets/aviatur_assets/img/footer/question.svg);
}
.foot_book {
    background-image: url(/assets/aviatur_assets/img/footer/book.svg);
}
.foot_company {
    background-image: url(/assets/aviatur_assets/img/footer/company.svg);
}
.foot_certification {
    background-image: url(/assets/aviatur_assets/img/footer/certification.svg);
}
.foot-contact__info {
    color: var(--Black1, #222);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 30px;

}
.img-iata {
    width: 65%;
    @include media-query (portable) {
        width: 50%;
    }
}
.img-anato {
    width: 44%;
    margin-top: 12px;
    @include media-query (portable) {
        width: 34%;
        margin-top: 10px;
    }
}
.js-slide__ulFoot {
    margin-left: 0;
}
@include media-query (desk) {
    .ul-nosotros {
        width: 21% !important;
    }
    .ul-cliente {
        width: 19% !important;
    }
    .ul-productos {
        width: 17% !important;
    }
    .ul-servicios {
        width: 22% !important;
    }
    .ul-terminos {
        width: 21% !important;
    }
}
.content-indus-cam {
    display: flex;
    justify-content: space-between;
    margin-top:8.5px;
    @include media-query (palm) {
        justify-content: space-around;
    }
}
// .content-img__foot {
//     width: 270px !important;
//     margin-left: 40px;
//     margin-top: -233px;
// }
@media only screen and (max-width: 1200px){
    .content-img__foot{
    width: 22% !important;        
    margin-left: 0px;
    margin-top: -67px;
        }
    }

 @media only screen and (max-width: 1015px){
        .content-img__foot{
        width: 100% !important;
        margin: 25px auto 0;
        margin-top: 80px;
        }
}

.img-footer { // 100%
    /* @include span(full); */ 
    width: 300px;
    float:left;
    padding-left: 9px;
    padding-right: 9px;
}




// .content-img__foot {
//     @include media-query (palm) {
//         margin-top: 80px;
//     }
// }

.page-foot_content-legal {
    @include media-query (desk) {
        display: flex;
        justify-content: space-between;
    }
}