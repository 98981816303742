.containerGeneral {
    padding: 0.5rem 2.5rem;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.pt-6{
    padding-top: 22px; /* 24px */
}

.pt-14{
padding-top:  3.5rem;
}

.items-start {
    align-items: flex-start;
}

.justify-items-center {
    justify-items: center;
}

.border-b-042A44{
    border-bottom: 2px solid #042A44;
}

.border-b-D1D1D{ 
    border-bottom: #D1D1D1 solid 1px;
}

.w-10\/12{
    width: 85%;
}

.w-2{
	width: 0.625rem; /* 10px */
}

.h-2{
    height: 0.625rem; /* 10px */
}

mb-2\.5{
    margin-bottom: 10px;
}

pb-2\.5{
    padding-bottom: 10px;
}

.rounded-full {
    border-radius: 9999px;
}

.bg-FFF3F4{
    background-color: #FFF3F4;
}

.bg-E41522{
    background-color: #E41522;
}

.color-E41522{
    color: #E41522;
}

.color-005CB9{
    color: #005CB9;
}

.color-042A44{
    color: #042A44;
}

.color-D1D1D1{
    color:#D1D1D1 !important;
}

.color-5A5A5A{
    color: #5A5A5A !important;
}

.color-00A870{
    color: #00A870 !important;
}

.color-white{
    color: white;
}

.color-00A870{
    color: #00A870 !important;
}

.color-black{
    color:black !important;
}

.font-normal{
    font-weight: 400 !important;
}

.z-10000{
    z-index: 10000;
}

.text-base{
    font-size: 1rem !important;
}

.cursor-pointer{
    cursor: pointer;
}

.mt-0-5{
    margin-top: -0.5rem;
}

.h-16{
    height: 4rem;
}

.h-13{
    height: 3.25rem;
}

.w-13{
    width: 3.25rem;
}

.min-h-16{	
    min-height: 4rem;
}

.text-lg{	
    font-size: 1.125rem;
}

.pb-4{
    padding-bottom: 1rem;
}

.pt-4{
    padding-top: 1rem;
}

.justify-around{
    justify-content: space-around;
}

.align-content{ 
    align-content: center;
}

.absolute	{position: absolute;}

.top-0{
    top: 0px;
}

.top-40	{top: 10rem; /* 160px */}
.right-40	{right: 10rem; /* 160px */}

.itemsCenter {
    align-items: center;
}
.m:items-center {
    align-items: center;
}

.s:items-center {
    align-items: center;
}

.xxs:items-center {
    align-items: center;
}

.mr-2\.5
{	margin-right: 0.625rem; /* 10px */}

.top-4{
    top: 16px;
}

.top-8{
    top: 2rem;
}

.top-9{
    top: 2.25rem;
}

.top-11{
    top: 2.75rem; /* 44px */
}

.top-16{
    top: 4rem;
}

.top-64{
    top: 16rem;
}

.rotate-90{	
    transform: rotate(90deg);
}

.rotate-270{	
transform:  rotate(270deg);
}

.right-60{
    right: 15rem; /* 240px */
}

.right-32 {
    right: 8rem; /* 128px */
}

.right--2{
    right: -2rem;
}

.right-0{
    right: 0px;
}

.text-2xl {
    font-size: 1.5rem;
}

.font-bold {
    font-weight: bold;
    color: #042A44
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.text-left {
    text-align: left;
}

.m:text-center {
    text-align: center;
}

.s:text-center {
    text-align: center;
}

.xxs:text-center {
    text-align: center;
}

.grid-rows-auto {
    grid-auto-rows: auto;
}



.p-2{
    padding: 0.5rem;
}

.pl-5{
    padding-left: 1.25rem; /* 20px */
}

.pr-28{	
    padding-right: 7rem;
}

.pt-20{
padding-top: 5rem; /* 80px */
}

.s:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.s:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.xxs:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.xxs:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.gap-4 {
    gap: 1rem;
}

.row-span-1 {
    grid-row-end: span 1;
}

.col-span-4 {
    grid-column-end: span 4;
}

.relative {
    position: relative;
}

.w-full {
    width: 100%;
}

.h-fit {
    width: fit-content;
    height: fit-content;
}

.rounded-xl {
    border-radius: 0.75rem;
}

.m:rounded-none {
    border-radius: 0;
}

.s:rounded-none {
    border-radius: 0;
}

.xxs:rounded-none {
    border-radius: 0;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.bg-white {
    background-color: #ffffff !important;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.w-auto {
    width: auto;
}

.rounded-l-lg {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.text-175 {
    font-size: 1.75rem;
}

.text-lg {
    font-size: 1.125rem;
}

.font-semibold {
    font-weight: 600;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.ml-5 {
    margin-left: 1.25rem;
}

.ml-1{
    margin-left: 0.5rem;
}

.my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
}

.h-36 {
    height: 36rem;
}

.lh-27 {
    height: 36rem;
}

.grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
}

.items-end {
    align-items: flex-end;
}

.w-fit {
    width: fit-content;
}

.l:w-max {
    width: max-content;
}

.text-white {
    color: #ffffff;
}

.l:text-xl {
    font-size: 1.125rem;
}

.font-bold {
    font-weight: bold;
}

.text-xl {
    font-size: 1.25rem;
}

.l:text-base {
    font-size: 1rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mt-7 {
    margin-top: 1.75rem;
}
.mt-12{
    margin-top:3rem !important;
}

.bg-EEFFF9{
    background-color: #EEFFF9;
}

.bg-00C382 {
    background-color: rgb(0 195 130/1);
}

.font-medium {
    font-weight: 500;
}

.border-l{
    border-left: solid black 1px;
}

.border-r{
    border-right: solid black 1px;
}

.border-r-white{
    border-right: solid white 1px;
}

.border-b-white{
    border-bottom: solid white 1px;
}

.rounded-3xl {
    border-radius: 1.125rem;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.l:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.l:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.pr-16{
    padding-right: 4rem;
}

.right--30px {
    right: -30px;
}

.w-max {
    width: max-content;
}

.flex-nowrap{
    flex-wrap: nowrap;
}

.mt-1{
    margin-top: 0.5rem;
}

.mt-4{
    margin-top: 1rem;
}

.l:mt-4 {
    margin-top: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.text-005CB9 {
    --tw-text-opacity: 1;
    color: rgb(0 92 185/1);
}

.row-span-3 {
    grid-row: span 3/span 3;
}

@media (max-width: 576px) {
    .col-span-4 {
        grid-column: span 12/span 12;
    }
}

button {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
}

button a {
    color: white;
}

.items-start {
    align-items: flex-start;
}

// grid Templates 
.grid {
    display: grid;
}

.grid-cols-auto {
    grid-auto-columns: auto;
}

.auto-cols-min {
    grid-auto-columns: min-content;
}

.auto-cols-max {
    grid-auto-columns: max-content;
}

.border-solid {	
    border-style: solid;
}

.border-2	{
    border-width: 2px;
}

.border-2-white{
    border: white 2px solid;
}

.bg-042A44{
    background-color: #042A44;
}

.border-042A44{
    border-color: #042A44;
}

.rounded-lg{
    border-radius: 0.5rem; /* 8px */
}

.padding-0\.2{
padding: 0.2rem !important
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-1 {
    grid-column: span 1 / span 1;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.col-span-3 {
    grid-column: span 3 / span 3;
}

.col-span-4 {
    grid-column: span 4 / span 4;
}

.col-span-5 {
    grid-column: span 5 / span 5;
}

.col-span-6 {
    grid-column: span 6 / span 6;
}

.col-span-7 {
    grid-column: span 7 / span 7;
}

.col-span-8 {
    grid-column: span 8 / span 8;
}

.col-span-9 {
    grid-column: span 9 / span 9;
}

.col-span-10 {
    grid-column: span 10 / span 10;
}

.col-span-11 {
    grid-column: span 11 / span 11;
}

.col-span-12 {
    grid-column: span 12 / span 12;
}

.col-start-3 {
    grid-column-start: 3;
}

.col-end-7 {
    grid-column-end: 7;
}

.pb-0{
 padding-bottom: 0px !important;
}

.bg-005CB914{
    background-color: #005CB914 !important;
}

.b-005CB9{
    border: 1px #005CB9 solid;
}

.p-0{
    padding: 0px !important;
}

.block{
    display: block !important;
}

.unset{
    position: unset !important;
}

.border-l-transparent{
    border-left: solid transparent 1px !important;
}

.pt-7{
    	padding-top: 1.75rem; /* 28px */
    }

.top-64{
    	top: 16.5rem; /* 256px */
    }
    
.right-8{
    right: 2rem; /* 32px */
}

.h-fit	{
    height: fit-content;
}

.top-44 {
    top: 11rem;
}

.right-1 {
    right: 0.5rem;
}

.right-6 {
    right: 1.5rem;
}

.top-6{
    top: 1.5rem;
}

.top-18{
    top: 4.5rem;
}

.right-82{
    right: 24rem !important;
}

.right-1-5{
    right: 20%;
}

.pb-5{
    padding-bottom: 20px !important;
 }

.pt-5{
    padding-top: 20px !important;
}
 
 .mt-20{
    margin-top: 5rem;
 }

 .shadow{
    box-shadow: 0px 1px 12.2px 1px rgba(0, 0, 0, 0.10);
 }

 .pb-2{
    margin-bottom: 0.4rem;
 }

 .mt-2{
    margin-top: 10px;
 }

 .p-3{
    padding: 12px;
 }

 .pt-2{
    padding-top: 0.5rem;
 }

 .pt-3{
    padding: 12px;
 }

.pt-13{
 padding-top: 52px !important;
}
.w-70{
    width: 280px !important;
}

.w-14{
    height: 55px;
}

.mt--185px{
    margin-top: -185px;
}

.Roboto{
font-family: 'Roboto';
}

@media (min-width: 768px) {

    .xl\:hidden{
        display: none !important;
    }
    .xl\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .xl\:w-full {
        width: 100% !important;;
    }
    .xl\:mb-4{
        margin-bottom: 1rem !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    .m\:w-fit {
        width: fit-content !important;
    }

    .m\:col-span-6 {
        grid-column: span 6 / span 6;
    }

    .m\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    
    .m\:hidden{
        display: none !important;
    }

    .m\:right-1-20{
        right: 5%;
    }
    
    .m\:border-l-transparent{
        border-left: solid transparent 1px !important;
    }

    .m\:block{
        display: block !important;
    }

    .m\:pt-7{
        padding-top: 2.9rem !important; /* 28px */
    }

    .m\:shadow{
        box-shadow: 0px 1px 12.2px 1px rgba(0, 0, 0, 0.10);
     }

     .m\:pt-5{
        padding-top: 20px !important;
    }
    .m\:pb-2{
        margin-bottom: 0.4rem;
     }
    .m\:pt-6{
        padding-top: 22px; /* 24px */
    }
    .m\:pt-2{
        padding-top: 0.5rem;
     }
    .m\:pt-13{
        padding-top: 31px !important;
    }
    .m\:w-70{
        width: 280px !important;
    }
    .m\:w-14{
        height: 55px !important;
    }
    .m\:border-b-D1D1D{ 
        border-bottom: #D1D1D1 solid 1px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .s\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .s\:hidden{
        display: none !important;
    }
    .s\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .s\:w-full {
        width: 100% !important;;
    }
    .s\:mb-4{
        margin-bottom: 1rem !important;
    }
    .s\:pb-0{
        padding-bottom: 0px !important;
    }
    .s\:pt-14{
        padding-top:  3.5rem;
        }
    .s\:mt-3 {
        margin-top: 0.75rem !important;
    }
    .s\:pt-6{
        padding-top: 22px; /* 24px */
    }
    .s\:p-0{
        padding: 0px !important;
    }
    .s\:block{
        display: block !important;
    }
    .s\:flex{
        display: flex !important;
    }
    .s\:unset{
        position: unset !important;
    }
    .s\:border-l-transparent{
        border-left: solid transparent 1px !important;
    }
    .s\:top-44 {
        top: 11rem;
    }
    .s\:right-1 {
        right: 0.5rem;
    }
    .s\:right-1-20{
        right: 5%;
    }
    .s\:pb-5{
        padding-bottom: 26px !important;
     }
     .s\:mt-20{
        margin-top: 5rem;
     }
     .s\:shadow{
        box-shadow: 0px 1px 12.2px 1px rgba(0, 0, 0, 0.10);
     }
     .s\:pt-5{
        padding-top: 20px !important;
    }
    .s\:p-3{
        padding: 12px;
     }
     .s\:pt-3{
        padding: 12px;
     }
    .s\:pt-2{
        padding-top: 0.5rem;
     }
     .s\:border-b-D1D1D{ 
        border-bottom: #D1D1D1 solid 1px;
    }
}

@media (max-width: 576px) {
    .xs\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .xs\:hidden{
        display: none !important;
    }
    .xs\:w-full {
        width: 100% !important;
    }
    .xs\:mb-4{
        margin-bottom: 1rem !important;
    }
    .xs\:p-0{
        padding: 0px !important;
    }
    .xs\:block{
        display: block !important;
    }
    .xs\:unset{
        position: unset !important;
    }
    .xs\:flex{
        display: flex !important;
    }
    .xs\:border-l-transparent{
        border-left: solid transparent 1px !important;
    }
    .xs\:top-44 {
        top: 11rem;
    }
    .xs\:right-1 {
        right: 0.5rem;
    }
    .xs\:right-1-20{
        right: 5%;
    }
    .xs\:pb-5{
        padding-bottom: 20px !important;
     }
     .xs\:mt-20{
        margin-top: 5rem;
     }
     .xs\:shadow{
        box-shadow: 0px 1px 12.2px 1px rgba(0, 0, 0, 0.10);
     }
     .xs\:pt-5{
        padding-top: 20px !important;
    }
    .xs\:mt-2{
    margin-top: 10px;
    }
    .xs\:p-3{
        padding: 12px;
     }
     .xs\:border-b-D1D1D{ 
        border-bottom: #D1D1D1 solid 1px;
    }
}
