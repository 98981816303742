///*------------------------------------*\
//    #SEARCH CRUISE
//\*------------------------------------*/


.search__col-cruise {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(desk) {
        @include span(1 of 3);
    }
}
.search__col-cruise-city {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    @include media-query(desk) {
        @include span(6 of 14);
    }
}
.search__col-cruise-date {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(desk) {
        @include span(4 of 14);
    }
}
.search-cruise__col-passenger {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap) {
        @include span(4 of 12);
    }
    @include media-query(desk) {
        @include span(3 of 15);
    }
}
.search-cruise__col-passengers {
    @include span(6 of 9);
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    & label:not(.label--error) {
        margin-bottom: 0;
    }
    @include media-query(desk) {
        .search__light--box & {
            margin-top: 0px;
        }
    }
}
.search-cruise__col-submit {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap) {
        margin-top: $inuit-base-spacing-unit--small;
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    @include media-query(desk) {
        @include span(2 of 7);
    }
}
.search-cruise__col-child {
    @include span(1 of 3);
    @include media-query(lap) {
        @include span(4 of 12);
        float: right;
    }
    @include media-query(desk) {
        @include span(2 of 15);
    }
}
.search-cruise, .search-insurance{
    h2{
        font-family: 'Roboto';
        color: white;
        font-weight: 700;
    }
    .search__row-main{
        backdrop-filter: blur(3.5px);
        background-color: rgba(0, 0, 0, 0.3);
        padding: 0.875rem;
        border-radius: 23px;
        min-height: 5rem;
    }
    .search-insurance-main {
        padding-bottom: 0px;
    }
    .text-input{
        background-color: transparent;
        color: white;
    }
    .slidePicker::before{
        bottom: 0.4rem;
        color: white;
    }
    .icon__input--inline:before{
        bottom: 0.4rem;
        color: white;
        padding-bottom: 9px;
    }
    .search__option__label {
        color:white;
    }
    select{
        font-size: 1rem !important;
    }
    .text-input::placeholder {
        color: white;
    }
    .date-1-insure .ui-widget-content {
        font-size: 0.8rem;
    }
    //solo aplica para tarjeta de asitencia 
    .date-1-insure .ui-widget-content2 {
        font-size: 0.8rem;
        width: 122%;
        margin-left: -8px;
    }


}

.text-cruise-tag{color: rgb(0, 0, 0);}
.page__body--search .text-cruise-tag{color: rgb(255, 255, 255);}
