/*------------------------------------*\
    #CONTACT SLIDER
\*------------------------------------*/

.left-slider {
    @extend .contact-slider;
    right: initial;
    left: 0;
}

.left-slider__banner {
    @extend .contact-slider__banner;
    width: 151px;
    top: 0;
    left: initial;
    right: -97px;
    margin-top: 56px;
    transform: rotate(-90deg);
}

.left-slider__inner {
    @extend .contact-slider__inner;
    margin-right: initial;
    margin-left: -$contact-slider-width;
    border-left-width: 0;
    border-right-width: 2px;
    border-radius: 0 $base-round $base-round 0;
}

.left-slider__col-disclaimer {
    @extend .contact-slider__col-disclaimer;
}

