///*------------------------------------*\
//    #DISPO-INSURANCE
//\*------------------------------------*/

// Internal variable
$height-big:     220px;
$height-small:   140px;


.dispo-insurance__image {
    border-radius: $base-round;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: calc(205px - #{$inuit-base-spacing-unit});
    @include media-query(palm) {
        height: calc(30px - 3px);
    }
}

.dispo-insurance__price {
    color: white;
    /* @include span(full); */ width: calc((1 / 1) * 100%);
}

.dispo-insurance__blue {
    border-radius: $base-round;
    position: inherit;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: $inuit-base-spacing-unit;
    margin-left: 0;
    margin-right: $inuit-base-spacing-unit--small;
    @include blueGradient();
    box-shadow: inset 0 40px 60px -10px rgba(0, 0, 0, .2);
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small;
        margin-right: 0;
        padding-bottom: $inuit-base-spacing-unit--small;
        margin-left: 0;
    }
    @include media-query(lap-and-up) {
        padding: $inuit-base-spacing-unit--small;
    }
}

.dispo-insurance__submit {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    @include media-query(lap-and-up) {
        margin: $inuit-base-spacing-unit--small 0;
    }
}

.dispo-insurance__price-value {
    font-size: 22px;
    color: white;
    @include font-weight(bold);
    @include media-query(lap-and-up) {
        font-size: 26px;
        margin-bottom: $inuit-base-spacing-unit--tiny;
    }
}

.dispo-insurance__price-detailedAmount {
    margin-top: 5px;
    font-style: oblique;
    font-size: .8125rem;
    line-height: 1.38462;
}

.dispo-insurance__price-detailedAmount p{
    margin: 0;
}

.row {
    display: table-row; /* Define el bloque como una linea de una tabla */
    margin:0px;
    padding:0px;
}
.Contenido {
    display: table-cell; /* Esto convierte al div en una celda de una tabla...*/
    width: 250px;
    padding: 10px 0 0 0;
}
.derecho {
  width: 75%;
  display: table-cell;
  vertical-align: top;
  border-left: 5px;
  border-right: 5px;
  padding: 5px 5px 5px 10px;
  @include media-query (palm){
      width: 100%;
  }
}
.derecho .contenedor{
    @include media-query (palm){
        margin-right: -7px;
    }
}

.derecho_info {
    display: table-cell;
    padding: 0 10px 0 0;
    width: 140px;
}

.title-insure{
    @include media-query(palm){
        padding: 0 5px;
    }
}

.container-price-detail {
    width: 100%;
    height: 110px;
    background-color: #005EB8;
    border-radius: 20px;
    position: relative;
}

.container-price-detail .container-child-price-detail {
    width: 70%;
    top: -10px;
    left: 15%;
    background:white;
    border-radius: 40px;
    border: 1px solid #005EB8;
    position: absolute;
    padding: 10px;
}

.container-price-detail .price-total { 
    color:white;
    bottom:0pt;
    position:absolute;
    width:100%
}