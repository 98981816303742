/*------------------------------------*\
    #FLOAT
\*------------------------------------*/
.clearfix {
    clear: both;
    &:before, &:after {
        clear: both;
        content: " ";
        display: table;
    }
}

.pull-left {
    float: left !important;
}
.pull-right {
    float: right !important;
}
.pull-right-more-options {
    float: none;
    //@include media-query(lap-and-up) {
    @media (max-width: 991px) {
        float: inline-start !important;
    }
}
.pull-a-bit-upwards {
    margin-top: -$inuit-base-spacing-unit--small;
}


.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// .inline-block {
//     // display: inline-block;
// }

.block {
    display: block;
}


.relative {
    position: relative;
}

/**
 * Text-alignment.
 */
.text-banner,
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-justify {
    text-align: justify;
}
.js-scroll-down {
    cursor: pointer; 
}