/*------------------------------------*\
    #PICKERS
\*------------------------------------*/

.picker,
%picker {
    border: 1px solid $color-frame-border;
    color: #000;
    background-color: #fff;
    z-index: $z-index-80;
    box-shadow: 0 8px 10px 1px rgba(0,0,0,.04), 0 3px 14px 2px rgba(0,0,0,.04), 0 5px 5px -3px rgba(0,0,0,.1);
}

.picker-flight,
%picker-flight {
    border: 1px solid $color-frame-border;
    color: #fff;
    background-color: #2d2d2d !important;
    //@include media-query(palm) {
    @media (max-width: 991px) {
        color: #2d2d2d;
        background-color: #fff !important;
    }

    z-index: $z-index-80;
    box-shadow: 0 8px 10px 1px rgba(0,0,0,.04), 0 3px 14px 2px rgba(0,0,0,.04), 0 5px 5px -3px rgba(0,0,0,.1);
    font-family: 'Ubuntu';
    font-weight: 400;
}

.picker-hotel,
%picker-hotel {
    border: 1px solid $color-frame-border;
    color: #fff !important;
    background-color: #2d2d2d !important;
    //@include media-query(palm) {
    @media (max-width: 991px) {
        color: #2d2d2d !important;
        background-color: #fff !important;
    }

    z-index: $z-index-80;
    box-shadow: 0 8px 10px 1px rgba(0,0,0,.04), 0 3px 14px 2px rgba(0,0,0,.04), 0 5px 5px -3px rgba(0,0,0,.1);
    font-family: 'Ubuntu';
    font-weight: 400;
}