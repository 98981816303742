///*------------------------------------*\
//    #AVAILABILITY
//\*------------------------------------*/

.dispo-flight__content {
    // @include span(7.5 of 10);
    width: calc((7.5 / 10) * 100%);
    margin-bottom: 0;
    @include media-query(palm) {
        // @include span(full);
        width: calc((1 / 1) * 100%);
    }
    @include media-query(lap-and-up) {
        padding-right: 36px;
    }
}


.dispo-flight__sidebar {
    // @include span(2.5 of 10);
    width: calc((2.5 / 10) * 100%);
    border-radius:  $base-round;
    color: black;
    @include media-query(palm) {
        // @include span(full);
        width: calc((1 / 1) * 100%);
    }
    @include media-query(lap-and-up) {
        position: absolute;
        top:$inuit-base-spacing-unit;
        right: $inuit-base-spacing-unit;
        bottom: 0;
        padding: $inuit-base-spacing-unit;
    }
}

.dispo-shared__sidebar {
    & .search-flight__options .first-option {
        padding-top: $inuit-base-spacing-unit--small;
    }
    & .search-flight__options .last-option .form-group {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
    & .search__row-main .section--first {
        margin-bottom: $inuit-base-spacing-unit;
    }
}

.dispo-flight__sidebar_special {
    // @include span(2 of 7);
    width: calc((2 / 7) * 100%);
    border-radius: $base-round;

    @include media-query(palm) {
        width: calc((1 / 1) * 100%);
        // @include span(full);
    }
    @include media-query(lap-and-up) {
        position: absolute;
        top: 18px;
        right: 18px;
        bottom: 0;
        padding: 18px;
    }
}

.dispo-flight__sidebar.dispo-flight__sidebar_special_aviatur,
.dispo-flight__sidebar_special.dispo-flight__sidebar_special_aviatur {
    @include blackGradient();
}

.dispo-flight__sidebar_special_aviatur .btn--submit{
    background-color: rgba(255,0,0,.4);
    background-image: url(/assets/aviatur_assets/img/promo/black_friday_fire.gif);
}

.dispo-flight__title-wrapper {
    clear: left;
    margin-top: $inuit-base-spacing-unit--small;
}  





#owl-dispo-flight {
    width: 100%;
    margin-bottom: 24px;
}
#owl-dispo-flight .owl-controls {
    position: absolute;
    margin-top: 0;
    width: 100%;
}
.dispo-flight__best {
    width: calc(100% - #{$inuit-base-spacing-unit});
    margin: 0 $inuit-base-spacing-unit--small;
    padding-top: 5px;
}
.dispo-flight__best img {
    max-width: $inuit-base-spacing-unit--large;
}
.dispo-flight__best__title {
    @include font-size(11px);
    margin-bottom: 2px;
}
.dispo-flight__best__price {
    color: $color-dispo;
    font-weight: bold;
    word-break: break-all;
    @media screen and (min-width: 769px) and (max-width: 1600px) {
        font-size: 1vw;
    }
    @media screen and (max-width: 768px) {
        font-size: 2vw;
    }
    @media screen and (max-width: 479px) {
        font-size: 3.5vw;
    }
}


.dispo-flight__frame {
    @include media-query(palm) {
      padding: 10px;
    }
    @include media-query(lap-and-up) {
      min-height: 250px;
      padding-bottom: 30px;
      padding: 15px !important;
    }
}

.btn-recommendation{
    cursor: none;
}

.dispo-flight__frame__recommendation {
    margin-left: 0%;
    margin-right: 0%;
    box-shadow: 1px 1px 14px 5px #ccc;
    border: solid 2px #009bf8;
    background: #e3f4ff;
    @include media-query(palm) {
        margin-left: 2%;
        margin-right: 2%;
    }
    @include media-query(lap-and-up) {
        min-height: 250px !important;
    }
}

.dispo-flight__pick {
    margin-bottom: $inuit-base-spacing-unit;
    text-align: center;
}

// .dispo-flight__pick::after {
//     content: "";
//     display: block;
//     position: relative;
//     width: 100%;
//     height: 100%;
//     box-shadow: 0px 0px 50px 50px black;
//     pointer-events: none;
//     z-index: 3;
//   }

  

.dispo-flight__row-title {
    border-bottom: 1px solid $color-dispo-border;
    margin-bottom: $inuit-base-spacing-unit--small;
    padding-bottom: $inuit-base-spacing-unit--tiny;
}
.dispo-flight__row__icon {
    vertical-align: baseline;
    color: $color-dispo-icons;
    font-size: 22px;
    line-height: $inuit-base-line-height;
    padding-left: 0 !important;
    padding-right: 0 !important;
    @include media-query(palm) {
        font-size: 20px;
        &.icon_dart_right {
            font-size: .7em;
        }
    }
}
.dispo-flight__row {
    border: solid transparent 1px;
    border-radius: 30px;
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    transition: background-color .3s ease;
}
.dispo-flight__row:hover {
    border: solid $color-brand 1px;
}
.dispo-flight__row *, .dispo-flight__row-title * {
    @include media-query(palm) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.dispo-flight__row__check {
    // @include span(1 of 20);
    width: calc((1 / 20) * 100%);
}
.dispo-flight__row__origin {
    // @include span(14 of 100);
    width: calc((14 / 100) * 100%);
    @include media-query(palm) {
        // @include span(20 of 100);
        width: calc((20 / 100) * 100%);
    }
}
.dispo-flight__row-title .dispo-flight__row__origin {
    color: $color-dispo;
    @include font-weight(600);
}
.dispo-flight__row__arrow {
    // @include span(3 of 100);
    width: calc((3 / 100) * 100%);
}
.dispo-flight__row__destination {
    // @include span(14 of 100);
    width: calc((14 / 100) * 100%);
    @media only screen and (max-width:1144px) {
        // @include span(20 of 100);
        width: calc((20 / 100) * 100%);
    }
}
.dispo-flight__row-title .dispo-flight__row__destination {
    color: $color-dispo;
    @include font-weight(600);
}
.dispo-flight__row__destination .delay-container {
    position: relative;
}
.dispo-flight__row__destination .day-delay{
    position: absolute;
    top: 0;
    font-size: 11px;
    color: $color-dispo;
    @include media-query(lap-and-up) {
        left: 3px;
    }
}
.dispo-flight__row__date {
    // @include span(13 of 100);
    width: calc((13 / 100) * 100%);
    @include media-query(palm) {
        // @include span(3 of 20);
        width: calc((3 / 20) * 100%);
    }
}
.dispo-flight__row-title .dispo-flight__row__date {
    color: $color-dispo;
    @include font-size(16px);
}
.dispo-flight__row .dispo-flight__row__date {
    @include font-size(16px);
}
.dispo-flight__row__stops {
    // @include span(14 of 100);
    width: calc((14 / 100) * 100%);
    @media only screen and (max-width:1144px) {
        // @include span(10 of 100);
        width: calc((10 / 100) * 100%);
    }
}
.dispo-flight__row-title .dispo-flight__row__stops {
    color: $color-dispo;
    @include font-size(12px);
}
.dispo-flight__row .dispo-flight__row__stops {
    @include font-size(15px);
    @media only screen and (max-width:1144px) {
        @include font-size(10px);
    }
}
.dispo-flight__row__airline {
    // @include span(20 of 100);
    width: calc((20 / 100) * 100%);
    @include media-query(palm) {
        // @include span(15 of 100);
        width: calc((15 / 100) * 100%);
    }
}

.dispo-flight__row-title {
    @include media-query(palm) {
        & .dispo-flight__row__check {
            display: none;
        }
        & .dispo-flight__row__airline {
            width: initial;
        }
        & .dispo-flight__row__origin {
            margin-left: 10%;
        }
        & .dispo-flight__row__stops {
            display: none;
        }
    }
}
.dispo-flight__row {
    @include media-query(palm) {
        & .dispo-flight__row__date {
          display: none;
            width: 18%;
        }
        & .dispo-flight__row__conditions {
            display: none;
        }
        & .dispo-flight__row__stops {
            width: 12%;
        }
    }
}

.dispo-flight__row__airline .icon_magnifying-glass{
    margin-left: 5px;
    @include media-query(palm) {
        display: none;
    }
}
.dispo-flight__seats {
    margin-top:-$inuit-base-spacing-unit--small;
}
.dispo-flight__sidebar_special .dispo-flight__seats {
    margin-top: -$inuit-base-spacing-unit--small;
}

.dispo-flight__sidebar_special_aviatur{
    color:#fff;
}

.dispo-flight__row__seats {
    // @include span(17 of 100);
    width: calc((12 / 100) * 100%);
    @media only screen and (max-width:1144px) {
        // @include span(8 of 100);
        width: calc((8 / 100) * 100%);
    }
    @include media-query(palm) {
        width: 100%;
    }
}
.dispo-flight__row__seats p{
    font-size: 11px;
    color: red;
    margin: 0;
    @include media-query(portable) {
        font-size: 10px;
    }
}
.dispo-flight__row .dispo-flight__row__airline {
    @include font-size(16px);
    line-height: 1em;
}
.dispo-flight__row .dispo-flight__row__airline .inline-block{
    vertical-align: middle;
}
.dispo-flight__row__airline-logo {
    display: block;
    height: 20px;
    //height: round($inuit-base-spacing-unit--large * 1/2);
    margin: auto;
    @include media-query(palm) {
      height: 15px;
    }
}
.dispo-flight__row__seats-logo {
    display: block;
    margin: auto;
    max-height: round($inuit-base-spacing-unit * 2);
}
.dispo-flight__row__conditions {
    // @include span(12 of 100);
    width: calc((12 / 100) * 100%);
    padding: 0;
    @include media-query(palm) {
      width: 20%;
      text-align: center;
      float: inherit;
    }
    @media screen  and (max-width: 1113px) and (min-width:722px){
        float: none;
    }
}



.dispo-flight__summary {
    @include media-query(lap-and-up) {
        position: absolute;
        width: 100%;
        width: calc(100% - #{$inuit-base-spacing-unit});
    }
    .btn--submit{
        padding: 8px 10px;
    }
}
.dispo-flight__summary_special {
    padding: 0;
    @include media-query(lap-and-up) {
        position: relative;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.dispo-flight__summary__price {
    // @include span(2 of 2);
    width: calc((2 / 2) * 100%);
    @include media-query(lap-and-up) {
        // @include span(full);
        width: calc((1 / 1) * 100%);
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.dispo-flight__summary__special {
    background: url(/assets/common_assets/img/promo/supertarifa-tag.png) no-repeat center top;
    background-size: cover;
    margin-bottom:10px;
    float:left;
    width:100%;
    color: rgba(28,87,138,1);
    font-size: 22px;
    @include font-weight(bold);
    @include media-query(lap-and-up) {
        font-size: 22px;
        line-height: 32px;
        text-align: center;
    }
}
.dispo-flight__summary__label {
    text-align: center;
    font-size: .8em;
}

// go styling
.__GO {
    &.dispo-flight__summary__label2 {
        @extend .dispo-flight__summary__label;
        margin-bottom: .5em;
    }
    &.dispo-flight__summary__label3 {
        @extend .dispo-flight__summary__label;
        margin-bottom: .5em;
    }
    &.dispo-flight__summary__total2 {
        @extend .dispo-flight__summary__total;
        margin-bottom: 0 !important;
    }
    &.dispo-flight__summary__total3 {
        @extend .dispo-flight__summary__total;
        margin-bottom: 0 !important;
    }
}


.dispo-flight__summary__total {
    text-align: right;
    font-size: 1.6em;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: .5em;
    // @include media-query(lap-and-up) {
    //     font-size: 26px;
    //     margin-bottom: $inuit-base-spacing-unit--tiny;
    // }
    @include media-query(palm) {
        font-size: 1.4em;
        margin-bottom: 0;
    }
}
.dispo-flight__summary__detail {
    // font-style: oblique;
    // @include font-size(13px);
    line-height: 1em;
}
.dispo-flight__summary__footer {
    border-top: 1px solid $color-frame-border;
    line-height: 2em;
    margin-top: .5em;
}
.dispo-flight__summary__seats {
    @include font-size(13px);
    font-weight: bold;
    line-height: round($inuit-base-spacing-unit * 2);
    width: 75%;
    float: left;
}
.dispo-flight__summary__submit {
    // @include span(2 of 2);
    width: calc((2 / 2) * 100%);
    @include media-query(lap-and-up) {
        // @include span(full);
        width: calc((1 / 1) * 100%);
    }
    @include media-query(palm) {
        & .btn--submit {
            margin-top: 4px;
        }
    }
}
.dispo-flight__summary__submit_combination {
    @include media-query(lap-and-up) {
        margin-top: -10px;
    }
}
.dispo-flight__summary__combination {
    display: block;
    margin-top: -5px;
    padding: $inuit-base-spacing-unit--tiny 0;
    text-align: center;
    @include font-weight(bold);
}


.dispo-flight__filter h2, .dispo-flight__filter h3 {
    color: #444;
}
dispo-flight__filter__frame {
    padding-bottom: $inuit-base-spacing-unit;
}
// .dispo-flight__filter__title {
//     margin-bottom: $inuit-base-spacing-unit--tiny;
// }
.dispo-flight__filter__input {
    background-color: transparent;
    color: $color-dispo-filter;
    margin-bottom: $inuit-base-spacing-unit--small;
    border: 0;
    font-weight: bold;
    width: 100%;
}
.dispo-flight__filter__slider {
    margin: 0 $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small;
}

.dispo-flight__submit-label {
    font-size: 1rem;
}
.dispo-flight__submit-total {
    font-size: 22px;
    @include font-weight(bold);
    @include media-query(lap-and-up) {
        font-size: 26px;
    }
}

.dispo-flight__airport-change {
    background:$color-main;
    color:#fff;
    border-radius:4px;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.dispo-flight__selection-resume {
    padding: 0;
}

.flight-selection__trip {
    background-color: $gray-plain-light;
    padding: $inuit-base-spacing-unit--small;
    border-bottom: 1px solid $color-frame-border;
    @include media-query(palm) {
        padding: 0;
    }
    @include media-query(portable) {
        &:not(:last-child) {
            margin-bottom: $inuit-base-spacing-unit--small;
        }
    }
    &:active, &.active {
        background-color: lighten($color-brand, 46%);
        border-color: lighten($color-brand, 40%);
    }
}
.flight-selection__trip-date {
    margin-top: .2rem;
}
.flight-selection__airline-img {
    max-height: 1em;
    vertical-align: top;
    margin-top: 3px;
    padding-left: calc($inuit-base-spacing-unit--small/2);
}
.flight-selection__dart .icon {
    margin-top: -$inuit-base-spacing-unit--small;
    font-size: $inuit-base-spacing-unit--small;
}
.flight-selection__half-container {
    width: 50%;
    float: left;
    & * {
        @extend .overflow-ellipsis;
    }
}


//--------------------------/
//   #FARE FAMILIES
//--------------------------/

.dispo-flight__ff-container {
    width: 100%;
    @include media-query(desk) {
        // @include span(6 of 8);
        width: calc((6 / 8) * 100%);
    }
    @include media-query(palm) {
        &.wrapper-susy {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.ff__table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border: 0;
    text-align: center;
    background-color: #fff;
    @include media-query(palm) {
        &, & thead, & tbody, & th, & td, & tr {
            display: block;
        }
    }
    /* here be kind of the worst cross browser hack ever... but still works in 2017 ¯\_(ツ)_/¯ */
    @include media-query(lap-and-up) {
        height: 100%;
        & tr {
            height: 100%;
            & th,
            & td {
                height: 1px;
                & .ff__fare-row {
                    /*position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;*/
                    height:100%;
                }
            }
        }
    }
}

.ff__table th,
.ff__table td {
    line-height: 1.42857;
    vertical-align: middle;
}

.ff__th-fare {
    .ff__table & {
        @include media-query(palm) {
            display: none;
        }
    }
}

.ff__th-flight {
    width: 42%;
    .ff__table tr > & {
        height: 4.2rem;
    }
    @include media-query(palm) {
        width: 100%;
        & .ff__fare-row {
            height: 100%;
        }
    }
}

.ff__fare-block {
    display: table;
    width: 100%;
    height: 100%;
    float: left;
    border-bottom: 2px solid #ddd;
    overflow: auto;
    @include media-query(lap-and-up) {
        width: 30%;
    }
}
.ff__fare-block--condensed {
    @extend .ff__fare-block;
    @include media-query(lap-and-up) {
        width: 20%;
    }
}
.ff__fare-block--condensed--max {
    @extend .ff__fare-block;
    @include media-query(lap-and-up) {
        width: 16%;
    }
}
.ff__fare-content {
    text-align: center;
    padding: $inuit-base-spacing-unit--small;
    width: 100%;
    .ff__fare-first-cell & {
        @include media-query(palm) {
            display: table-cell;
            vertical-align: middle;
        }
    }
    @include media-query(lap-and-up) {
        display: table-cell;
        vertical-align: middle;
        padding: calc($inuit-base-spacing-unit--small/2);
    }
}

.ff__fare-cell {
    @include media-query(palm) {
        min-height: 4em;
    }
    .ff__flight-option.highlight .ff__flight-info &,
    .ff__flight-option.highlight-selected .ff__flight-info & {
        background-color: rgba(0,0,0,.03);
    }
    @include media-query(lap-and-up) {
        .ff__flight-option.highlight .ff__fare-row &,
        .ff__flight-option.highlight-selected .ff__fare-row & {
            background-color: rgba(0,0,0,.03);
        }
    }
}

.ff__flight-option {
    @include media-query(palm) {
        clear: both;
        &:before, &:after {
            clear: both;
            content: " ";
            display: table;
        }
        transition: box-shadow .3s;
        &.ff__flight-option.highlight {
            box-shadow: 0 3px 9px 1px rgba(0,0,0,.3);
        }
    }
}

.ff__flight-info {
    width: 42%;
    @include media-query(palm) {
        width: 100%;
    }
}
.ff__flight-info-content {
    padding: $inuit-base-spacing-unit--small;
    border-left: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    @include media-query(palm) {
        border-right: 1px solid #ddd;
        border-bottom: 1px dashed #ddd;
    }
    &.highlight {
        background-color: rgba(0,0,0,.03);
    }
}

.ff__fare-airline {
    width: 14%;
    float: left;
    padding-right: $inuit-base-spacing-unit--small;
    @include media-query(lap) {
        display: block;
        margin-bottom: $inuit-base-spacing-unit--small;
        text-align: left;
    }
    @include media-query(palm) {
        width: 100%;
        padding: 0;
        text-align: left;
    }
}
.ff__fare-airline img {
    max-height: $inuit-base-spacing-unit;
    max-width: 60px;
}

.ff__schedule {
    width: 86%;
    float: left;
    @include media-query(palm) {
        width: 100%;
    }
}
.ff__schedule-destination {
    float: left;
    width: 50%;
    line-height: 1.1;
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    @include media-query(lap) {
        width: 46%;
    }
    @include media-query(palm) {
        text-align: center;
    }
    &:first-child {
        padding-right: $inuit-base-spacing-unit--small;
        @include media-query(lap) {
            padding-right: 0;
            margin-right: 8%;
        }
    }
}
.ff__time {
    font-size: $inuit-base-spacing-unit;
    font-weight: bold;
}
.ff__airport {
    @include media-query(lap) {
        word-break: break-word;
    }
}
.ff__more-info {
    padding-top: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        padding-top: 4px;
    }
}

.ff__price-info {
    @include media-query(palm) {
        width: 75%;
        margin: 0 0 0 auto;
        .ff__fare-option--disabled & {
            margin-top: $inuit-base-spacing-unit--small;
        }
    }
}
.ff__currency {
    display: block;
}
.ff__info {
    position: absolute;
    top: 0;
    right: 0;
    padding: calc($inuit-base-spacing-unit--small/2);
    line-height: 1em;
}
.ff__price-na {
    padding: calc($inuit-base-spacing-unit--small/2);
}

.ff__palm-header {
    @include media-query(palm) {
        float: left;
        width: 25%;
        margin-top: $inuit-base-spacing-unit--small;
    }
}

.ff__fare-first-cell {
    width: 100%;
    background-color: transparent;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    @include media-query(palm) {
        border-right: 1px solid #ddd;
    }
}

.ff__fare-header {
    color: #fff;
    border-top: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    border-bottom: 2px solid rgba(0,0,0,.1);
    &:last-child {
        border-right: 1px solid rgba(0,0,0,.1);
    }
}

%ff__fare-option {
    font-size: 14px;
    font-weight: bold;
    @include media-query(palm) {
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        &:not(:last-child) {
            border-bottom: 1px dashed #ddd;
        }
    }
    @include media-query(lap-and-up) {
        &:first-child,
            .ff__fare-option--prime:first-child + & {
            border-left: 1px dashed #ddd;
        }
        &:last-child {
            border-right: 1px solid #ddd;
        }
        &:not(:last-child) {
            border-right: 1px dashed #ddd;
        }
    }
    /*    &.ff__best-price-all:not(.active):not(.selected) {
            color: #fff;
            & .ff__fare-content {
                background-color: #00cc5f;
            }
        }*/
}
%ff__fare-option-clickable {
    @include media-query(lap-and-up) {
        &.active,
        &.selected {
            color: #FCFCFC;
            text-shadow: 2px 2px rgba(0,0,0,0.2);
        }
    }
}
.ff__fare-option {
    @extend %ff__fare-option;
    @extend %ff__fare-option-clickable;
}
.ff__fare-option--disabled {
    @extend %ff__fare-option;
}
.ff__fare-option--prime {
    @extend %ff__fare-option;
    @include media-query(palm) {
        min-height: auto;
        font-weight: normal;
        margin-top: -1px;
        &:not(:last-child) {
            border-bottom: 2px solid #ddd;
        }
    }
}

.ff__point-bestFare {
    position: absolute;
    bottom: calc($inuit-base-spacing-unit--small/2);
    right: calc($inuit-base-spacing-unit--small/2);
    color: #00cc5f;
    .ff__fare-cell.active &,
    .ff__fare-cell.selected & {
        color: inherit;
    }
}

.ff__fare-info-tooltip {
    position: absolute;
    max-width: 300px;
    border: 1px solid transparent;
}
.ff__tooltip-box {
    padding: $inuit-base-spacing-unit--small;
    background-color: #fbfbfb;
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 1px 4px 0px rgba(0,0,0,.4);
}
.ff__tooltip-box-title {
    margin-bottom: calc($inuit-base-spacing-unit--small/2);
}
.ff__tooltip-list {
    list-style: none;
    margin: 0;
    & .ffc--0 {
        color: red;
    }
    & .ffc--1 {
        color: orange;
    }
    & .ffc--2 {
        color: green;
    }
}
.ff__tooltip-pointer {
    position: absolute;
    top: 0;
    width: 12px;
    height: 13px;
    overflow: hidden;
    z-index: 10;
    &.left-side {
        left: calc(50% - 12px);
    }
    &.left-side > * {
        transform: skewX(45deg);
        transform-origin: 0 0 0;
        border-left: 1px solid rgba(0,0,0,.2);
    }
    &.right-side {
        left: 50%;
    }
    &.right-side > * {
        transform: skewX(-45deg);
        transform-origin: 5px 0 0;
        border-right: 1px solid rgba(0,0,0,.2);
    }
    &.left-side > *,
    &.right-side > * {
        width: inherit;
        height: inherit;
        background-color: #fbfbfb;
        box-shadow: 0 1px 3px rgba(0,0,0,.6);
    }
}

.btn-recomendacion{
    background-color: rgba(189, 31, 31, 0.78);
    float: left;
    display: none;
    cursor: pointer;
    padding-left: 11px;
    border-color: rgb(211, 34, 34);
    -webkit-animation: flash-recomendacion linear 1s infinite;
    animation: flash-recomendacion linear 1s infinite;
}
.btn-recomendacion:hover{
    background-color: rgb(228, 61, 61);
    border-color: rgb(228, 61, 61);
}
.flight-detail-text{
    width:100%;
    float:left;
    margin-bottom:10px;
    >h5{
        color:#009bf8;
        width:80%;
        float:left;
    }
}
#flight-detail-info{
    width:70%;
    float:left;
    @include media-query(palm) {
        width:100%;
    }
}
#flight-price-info{
    width:30%;
    float:left;
    height:70px;
    padding-left:5px;
    @include media-query(palm) {
        width:100%;
        padding:3px;
        border-radius:3px;
        box-shadow:0px 5px 10px 0px rgb(90, 93, 90);

    }
}
@-webkit-keyframes flash-recomendacion {
    0% { opacity: 1; }
    50% { opacity: 0.9; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.32), 0 1px 5px 0 rgba(0, 0, 0, 0.42), 0 3px 1px -2px rgba(0, 0, 0, 0.32); background-color: rgb(236, 58, 58);border-color: rgb(255, 0, 0);}
    100% { opacity: 1; }
}
@keyframes flash-recomendacion {
    0% { opacity: 1; }
    50% { opacity: 0.9; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.32), 0 1px 5px 0 rgba(0, 0, 0, 0.42), 0 3px 1px -2px rgba(0, 0, 0, 0.32); background-color: rgb(236, 58, 58); border-color: rgb(255, 0, 0); }
    100% { opacity: 1; }
}

.ticket {
  width: 350px;
  background: #eaeaea;
  height: 100%;
  margin: 0 auto;
  &:after {
    content: '';
    display: block;
    position: relative;
    clear: both;
  }
}
.ticket>.first {
  &:before, &:after {
    background-color: white;
    position: absolute;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    bottom: 0;
    margin-bottom: -10px;
  }
  &:before {
    left: 0;
    margin-left: -10px;
  }
  &:after {
    right: 0;
    margin-right: -10px;
  }
}

.logo-ticket{
  width: 100px;
}
.row-ticket {
  height: 100% !important;
  position: relative;
  padding: 20px;
  &.perforated {
    border-bottom: 1px dashed #666;
  }
  &.destinations:after, &.normRow:after {
    content: '';
    clear: both;
    display: block;
  }
}
.label {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
    line-height: 1.2!important;
}
.airport {
  font-size: 32px;
}
.city {
  font-size: 12px;
  text-transform: uppercase;
}
.right-align {
  float: right;
  text-align: right;
}
.left-align {
  float: left;
  text-align: left;
}
svg#plane {
  width: 30px;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  margin-top: 15px;
  opacity: 0.5;
  display: inline-block;
}
.smalltext{
  padding: 20px;
  font-size: 10px;
  opacity: 0.6;
}
.col-6 {
  float: left;
  width: 50%;
  font-size: 21px;
  line-height: 1.5;
}
.col-13 {
    float: left;
    width: 60%;
    font-size: 21px;
    line-height: 1.5;
  }
.col {
  font-size: 21px;
}
.normRow {
  padding-bottom: 0;
}
#qr {
  float: right;
  width: 80px;
  position: relative;
  right: -5px;
  bottom: 5px;
  margin-left: 10px;
}
.sparkler {
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 20px;
  .square {
    width: 12.5%;
    height: 12.5%;
    background-color: grey;
    float: left;
    transition: background-color 0.1s;
  }
}
.dispo-flight__hopper {
    margin: 7px 0;
    .dispo-flight__hopper-question {
        font-size: 13px;
        font-weight: 600;
    }
    i {
        font-size: 13px;
        vertical-align: baseline;
    }
}
.js-openModalAvailHopper span.material-icons-round {
    transform: translate(0px, 2px);
}
.btn-avail__hopper{
    margin-top: 1rem;
    background-color: transparent;
    color: #FA6866;
    border: 2px solid #FA6866;
    width: 100%;
    @include attention() {
      background-color: #FA6866;
      border-color:#FA6866;
    }
}
.btn-avail__hopper:hover svg { filter: brightness(3); }

.tooltip-hopper {
    width: 234px;
    padding: 10px;
}
.modalBodyHopper {
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.modalAvailHopper {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    left: 0%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0,0,0,.4);
    transition: opacity 1s ease;
}
.content-modalAvailHopper {
    top: 5%;
    width: 95%;
    height: 90%;
    margin: auto;
    padding: 10px;
    @include media-query(lap-and-up) {
      top: 5%;
      width: 57%;
      height: 550px;
      margin: auto;
      padding: 20px 30px;
    }
    .btn-close {
        position: absolute;
        right: 1vw;
        top: 1vh;
        i {
            color: #aaaaaa !important;
        }
        @include attention() {
            cursor: pointer;
        }
    }
    .modal--hopper__title {
        color: #FA6866;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
    }
    strong {
        color: #005EB8;
    }
    ul {
        margin-left: 0px;
    }
    ul li {
        font-size: 14px;
        text-align: left;
        line-height: 18px;
        color: #7B7B7B;
        list-style: none;
        padding-left: 20px;
        span.material-icons-round {
            color: #2477CA;
            margin: -3px 10px 0 10px;
            float: left;
        }
    }
    .hopper__info-question {
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
    }
    i {
        color: #005eb8;
        font-weight: bold;
    }
    .info-hopper {
        color: #9b9999;
        margin-bottom: 16px;
    }
    .btn-freeze {
        background-color:#FA6866;
        width: 45%;
        margin: 10px 0;
    }
    .js-modalFreeze {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .centerFlex {
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .info-hopper__foot {
        font-size: 12px;
    }
}
.activeModalHopper {
  visibility: visible;
  opacity: 1;
  z-index: 80;
}
.faildFreezeHopper {
    width: 40%;
    height: 150px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding-top: 60px;
    color: #444;
}

.discountAdd{
    color:#21C284;
    font-weight: bold;
    text-align: right;
}
.discountAdd .icon icon-tabler icon-tabler-discount{
    vertical-align: middle;
    font-size: 1rem;
    color:#21C284
}

.currencyDiscount {
    color:red
}

.originalPrice {
    color:black;
    font-size: 12px;
}
.dispo-flight__row__airportDiferent{
    border: #005eb7 solid 1px;
    color: #015eb7;
    padding: 0 1rem;
    border-radius: 15px;
}