///*------------------------------------*\
//    #DETAIL-HOTEL
//\*------------------------------------*/

.detail-train__content {
    @include media-query(desk) {
        @include span(4 of 6);
    }
}
.detail-train__sidebar {
    @include span(2 of 6);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);  
    }
}





.detail-train__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
.detail-train__stars {
    height: $inuit-base-spacing-unit;
    vertical-align: baseline;
}
.detail-train__rating-disclaimer {
    min-width: 300px;
    margin-left: calc($inuit-base-spacing-unit--small/2).0;
}
.detail-train__label {
    font-size: 12px;
}
.detail-train__sidebar .detail-train__price-frame {
    margin-top: -1px;
}
@include media-query(palm) {
    .detail-train__sidebar .frame:last-of-type:not(:first-of-type) {
        border-radius: 0 !important;
    }
}
.detail-train__price {
    color: white;
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
@include media-query(palm) {
    .detail-train__price-frame--mobile {
        border-bottom: 1px solid rgba(255,255,255,.5) !important;
        border-radius: 0 0 5px 5px !important;
    }
}




.detail-train__carousel {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-bottom: -1px;
        margin-left: -$inuit-base-spacing-unit--small;
        margin-right: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.detail-train__carousel-disclaimer {
    margin-top: calc(2px - #{$inuit-base-spacing-unit});
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-top: 1px;
        margin-bottom: 3px;
    }
}
.detail-train__carousel__image {
    background: no-repeat center center;
    height: 260px;
    background-size: cover;
    @include media-query(palm) { 
        height: 220px;
    }
}





.detail-train__rooms-table {
    margin-bottom: $inuit-base-spacing-unit;
}




.detail-train__map {
    border-radius: $base-round;
    border: 1px solid rgba(0,0,0,.07);
    height: 300px;
    margin-bottom: $inuit-base-spacing-unit;
    width: 100%;
    @include media-query(palm) {
        height: 200px;
    }
}
.detail-train__description {
    padding-bottom: $inuit-base-spacing-unit; 
}
.detail-train__description-expanded {
    display: none;
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-train__list {
    padding-bottom: $inuit-base-spacing-unit;
}

.detail-multi__discount-policies {
    font-size: .8em;
    font-weight: bold;
}

.js-showConditions h6 {
    font-weight: bold;
    margin-top: 3px;
}
