///*------------------------------------*\
//    #SEARCH
//\*------------------------------------*/

.search__col-hotel-city {
    @include span(6 of 14);
    @include media-query(lap) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search__col-hotel-date {
    @include span(4 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}


.search-hotel__col-room {
    @include span(5 of 7);
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    & label:not(.label--error) {
        margin-bottom: 0;
    }
    // @include media-query(desk) {
    //     .search__light--box & .search-hotel__row-room:last-of-type .form-group {
    //         margin-bottom: 0;
    //     }
    // }
}
.search-hotel__col-submit {
    font-size: 18px;
    @include span(2 of 7);
    @include media-query(lap) {
        margin-top: $inuit-base-spacing-unit--small;
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    & .search__footer__submit:not(:last-of-type) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.search-hotel__conditions {
    margin-bottom: 5px;
    & .label-conditions {
        margin-bottom: 0;
        .search__light--box & {
            padding-left: .5rem;
            border-radius: 2px;
            background-color: rgba(255, 255, 255, .1);
        }
    }
}


.search-hotel__instance-creator {
    // @include media-query(desk) {
        margin: 0;
        width: 100%;
        padding: 15px 12px;
    // }    
}


.search-hotel__col-passenger {
    @include span(3 of 15);
    @include media-query(lap) {
        @include span(4 of 12);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search-hotel__col-child {
    @include span(2 of 15);
    @include media-query(lap) {
        @include span(4 of 12);
        float: right;
    }
    @include media-query(palm) {
        @include span(1 of 3);
    }
}
.search__hotel-passengers-block {
    position: absolute;
    margin-top: 4.2em;
    right: 4%;
    min-width: 260px;
    background-color: white;    
    width: 27%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 15px;
    box-shadow: 0 4px 8px rgba(68,68,68,.2);
    border: 1px solid lighten(#ccc, 10);
    border-radius: 8px;
    @include media-query (lap) {
        width: 300px;
    }
    @include media-query (palm) {                  
        position: absolute;
        
        margin-top: 4.2em;
        width: 90%;
    }
  
    .search-hotel__col-room {
        width: 100%;
        color: $color-gree-text;
        .search__btn-increment {
            text-align: end;
            font-size: 16px;
            line-height: 18px;
        }
    }
    .search-hotel__col-actions {       
        clear: both;        
        border-top: 1px solid darken(#ccc, 5);               
        margin: 0 -10px;
        span:nth-child(1)  {
            width: 73%;
        }
        span:nth-child(2)  {
            width: 25%;
            text-align: end;
        }
    }
    .search-hotel__title-passanger {
        padding-top: 8px;
        font-size: 20px;
    }
    .search-hotel__col-passenger {
        width: 100%;       
        padding: 0;        
    }
    .search__option__label {
        border-bottom: thin solid #eee;             
        color: #888;
    }
    .search__option {
        color: $color-main;
        font-weight: 600;
    }
    .title-children {
        font-size: 17px;
    }
    .children-age {
        padding: 8px;
        height: 40px;
        border-radius: 6px;
        border: thin solid #bbbbbb;
        background-color: white;
        margin-bottom: 5px;
    }
    &::before {
        content:"";
        width: 0;
        height: 0;
        border-right: 15px solid transparent;
        border-top: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 15px solid white;
        position: absolute;
        top: -30px;
        left: 27px;
    }   
    .content--child__age {
        padding: 12px 0;
    }
}
.number-input[type="number"] {
    vertical-align: inherit;
    max-width: 2.5rem;
    background-color: white;
    font-size: 1.5em;
    height: 3rem;
    font-weight: bold;
    text-align: center!important;
    padding: 0.5rem;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
.number-input[type="number"] {
    -webkit-appearance: textfield;
}
.number-input[type=number]::-webkit-inner-spin-button,
.number-input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.search-hotel__room {
    width: 100%;
    .btn  {
        margin-left: 0;
    }
}
.search-hotel__btn-search {
    padding-top: 9px;
}
input[name="paymentDestination"],
input[name="paymentOnline"] {
    cursor: pointer;
}
.passangerTotalHotel {
    padding-top:20px;
    span {
        font-size: 18px;
        color: $color-gree-text;
    }
    i {
        font-size: 21px;   
        padding-right: 8px;
        &::before {
            vertical-align: text-top;
        }  
        @media screen and (min-width:722px) and (max-width:1090px){                    
            padding-right: 0;
        }      
    }
    .icon_bundle_hotel {
        font-size: 23px;
        &::before {
            vertical-align: super;
        }
    }
}
.passangerTotalHotel-multi {
    padding-top:10px;
    span {
        font-size: 18px;
        color: $color-gree-text;
    }
    i {
        font-size: 21px;   
        padding-right: 8px;
        &::before {
            vertical-align: text-top;
        }  
        @media screen and (min-width:722px) and (max-width:1090px){                    
            padding-right: 0;
        }      
    }
    .icon_bundle_hotel {
        font-size: 23px;
        &::before {
            vertical-align: super;
        }
    }
}
.slidePicker::before {
    padding: inherit;
}
.palmPrevModal {
    text-align: end;
    font-weight: 600;
    font-size: 18px;
    i::before {
        vertical-align: text-top;
    }
}
.search-hotel {
    @include media-query (palm) {
        padding-bottom: 20px;
    }
}

.search__cruise-passengers-block {
    position: absolute;
    margin-top: 4.2em;
    right: 4%;
    min-width: 260px;
    background-color: white;    
    width: 27%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 15px;
    box-shadow: 0 4px 8px rgba(68,68,68,.2);
    border: 1px solid lighten(#ccc, 10);
    border-radius: 8px;
    height: fit-content;
    @include media-query (lap) {
        width: 300px;
    }
    @include media-query (palm) {                  
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
    }
  
    .search-cruise__col-room {
        width: 100%;
        color: $color-gree-text;
        .search__btn-increment {
            text-align: end;
            font-size: 16px;
            line-height: 18px;
        }
    }
    .search-cruise__col-actions {       
        clear: both;        
        border-top: 1px solid darken(#ccc, 5);               
        margin: 0 -10px;
        span:nth-child(1)  {
            width: 73%;
        }
        span:nth-child(2)  {
            width: 25%;
            text-align: end;
        }
    }
    .search-cruise__title-passanger {
        padding-top: 8px;
        font-size: 20px;
    }
    .search-cruise__col-passenger {
        width: 100%;       
        padding: 0;        
    }
    .search__option__label {
        border-bottom: thin solid #eee;             
        color: #888;
    }
    .search__option {
        color: $color-main;
        font-weight: 600;
    }
    .title-children {
        font-size: 17px;
    }
    .children-age {
        padding: 8px;
        height: 40px;
        border-radius: 6px;
        border: thin solid #bbbbbb;
        background-color: white;
        margin-bottom: 5px;
    }
    &::before {
        content:"";
        width: 0;
        height: 0;
        border-right: 15px solid transparent;
        border-top: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 15px solid white;
        position: absolute;
        top: -30px;
        left: 27px;
    }   
    .content--child__age {
        padding: 12px 0;
    }
}