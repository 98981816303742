///*------------------------------------*\
//  #DISPO-SHARED
//\*------------------------------------*/

.dispo-shared__topbar {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
}
.dispo-shared__sidebar {
    @include span(2 of 8);
    width: 20%;
}
.dispo-shared__sidebar-full-height {
    position: absolute;
    right: 0;
    width: 25%;
    height: 100%;
    padding-right: $inuit-base-spacing-unit--small;
}

.dispo-shared__sidebar_content_socialNetwork{
    width: 5%;
    position: fixed;
    left: 0;
}
.dispo-shared__socialNetwork--left {
    position: absolute;
}

.dispo-shared__content {
    @include span(6 of 8);
    width: 80%;
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
    min-height: 400px;
}
.dispo-shared__content-full-width {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
    min-height: 400px;
}

/*.dispo-shared__results {
    /* @include span(full);
}*/
/*.dispo-shared__results-full-width {
    /* @include span(full);
}*/

// .dispo-shared__recommendations .frame-single {
//     margin-bottom: $inuit-base-spacing-unit;
//     padding-bottom: 2%;
// }

.dispo-shared__filter-loader {
    margin: $inuit-base-spacing-unit--small;
}

.dispo-shared__loading {
    float: left;
    width: 100%;
    margin: 0 auto 300px;
    padding-top: $inuit-base-spacing-unit;
    padding-left: $inuit-base-spacing-unit;
    padding-right: $inuit-base-spacing-unit;
    position:relative;
    @include media-query(palm) {
        padding-top: $inuit-base-spacing-unit--small;
    }
}
.dispo-shared__no-match {
    text-align: center;
    margin-top: $inuit-base-spacing-unit--huge;
}

.dispo-shared__ff-options {
    display: none !important; // YARED COMMENT return this wehn fare families is ok
    margin-bottom: $inuit-base-spacing-unit;
    padding-right: $inuit-base-spacing-unit;
}

.dispo-shared__filter-container {
    display: inline-block;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    & > * {
        padding: $inuit-base-spacing-unit--small;
        cursor: pointer;
    }
}
.dispo-shared__filter-container--full {
    @extend .dispo-shared__filter-container;
    width: 100%;
}
.dispo-shared__filter-order,
.dispo-shared__filter-options,
.dispo-shared__filter-more {
    border-bottom: 1px solid $color-frame-border;
    @include attention() {
        background-color: lighten($color-main, 47%);
        color: darken($color-main, 10%);
    }
    &.is-active {
        background-color: lighten($color-main, 47%);
        color: darken($color-main, 10%);
    }
}
.dispo-shared__filter-order {
    & .icon {
        display: none;
    }
    &.is-active {
        font-weight: bold;
        & .icon {
            display: inline-block;
        }
    }
}
.dispo-shared__filter-options {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fbfbfb;
}
.dispo-shared__filter-options__picker {
    position: absolute;
    width: calc(100% - #{$inuit-base-spacing-unit});
    padding: $inuit-base-spacing-unit $inuit-base-spacing-unit $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        width: 100%;
    }
}

.hidden-block {
    display: none;
}
.hidden-option {
    display: none;
}
.restrictions_covid {
    font-size: 13px;
    line-height: 15px;
    color: $color-main;
    padding: 14px 0;
    text-align: center;
    background: rgba(29, 122, 207, 0.33);
    text-decoration: underline;
    @include media-query (portable) {
        text-align: initial;
        padding-left: 10px;
        text-align: center;
    }
}