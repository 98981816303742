///*------------------------------------*\
//   #LOADING
//\*------------------------------------*/

/********** GLOBAL OVERLAY **********/
.overlay {
    background-color: rgba(255,255,255,.65);
    display: table;
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: $z-index-loading-overlay;
}
.overlay--inDiv {
    position: relative;
}
.overlay-center {
    color: $color-loading-main;
    display: table-cell;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
    vertical-align: middle;
}


/********** Spin Big **********/
.spinOverlay {
    filter: hue-rotate(25deg);
    height: 128px;
    background: url(https://aviaturcdndev.z5.web.core.windows.net/assets/aviatur_assets/img/icon/spin.gif) no-repeat center;
    margin-bottom: $inuit-base-spacing-unit;
}
.spinOverlayLoading {
    filter: hue-rotate(25deg);
    height: 300px;
    width: 50%;
    min-width: 300px;
    background-image: url(/assets/aviatur_assets/img/icon/spinLoading.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
}
.spinOverlayLoading_playa{
  height: 300px;
  width: 50%;
  min-width: 300px;
  background-image: url(/assets/aviatur_assets/img/icon/spinLoading_playa.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto; }
.spin-text {
    height: 30px;
    text-align: center;
    color: $color-main;
}


/********** Spin Gif Small **********/
.circularG {
    filter: hue-rotate(25deg);
    position: relative;
    width: 17px;
    height: 17px;
    background: url(/assets/aviatur_assets/img/icon/ajax-loader.gif) no-repeat;
}


/****** Ellipsis points loader ******/
/*.points-loader {
    min-height: 1em; // ensure same height on empty element
    margin-bottom: $inuit-base-spacing-unit;
    line-height: 1em;
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #666;
}*/


/****** Progress bar loader ******/
.progress-bar {
    width: 40%;
    margin: 0 auto;
    position: relative;
}
.progress-bar__container {
    position: absolute;
    top: $inuit-base-spacing-unit;
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: $base-round;
    background-color: #f1f1f1;
    overflow: hidden;
}
.progress-bar__bar {
    width: 0;
    height: $inuit-base-spacing-unit;
    background-color: $color-loading-main;
}
.progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color:#66c8e2;
    border-radius: 2px;
    margin: .5rem 0 1rem 0;
    overflow: hidden;
}
    .progress .indeterminate {
    background-color: $color-main;
}
.progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}

    @-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}

/*---
section: Loaders
title: material-loader
---

@include material-loader(100, 1.5s, $color-main);
@include material-loader(200, 1.5s, $color-main);
@include material-loader(400, 1.5s, $color-main);

Generates **.mat-loader--[size]** / **.mat-loader--[size]__path**

```example:html
<svg class="mat-loader--32" height="32" width="32">
    <circle class="mat-loader--32__path" cx="16" cy="16" r="12.8" fill="none" stroke-width="3" stroke-miterlimit="10" />
</svg>
<svg class="mat-loader--100" height="100" width="100">
    <circle class="mat-loader--100__path" cx="50" cy="50" r="40" fill="none" stroke-width="6" stroke-miterlimit="10" />
</svg>
<svg class="mat-loader--200" height="200" width="200">
    <circle class="mat-loader--200__path" cx="100" cy="100" r="80" fill="none" stroke-width="6" stroke-miterlimit="10" />
</svg>
<svg class="mat-loader--400" height="400" width="400">
    <circle class="mat-loader--400__path" cx="200" cy="200" r="160" fill="none" stroke-width="6" stroke-miterlimit="10" />
</svg>
```
*/
@include material-loader(32, 1.5s, $color-main);
@include material-loader(100, 1.5s, $color-main);
@include material-loader(200, 1.5s, $color-main);
@include material-loader(400, 1.5s, $color-main);
