/*------------------------------------*\
    #ICONS GENERATE
\*------------------------------------*/

@font-face {
    font-family:"icons";
    src:url(/assets/common_assets/font/icons.eot?522cad3ab746d1234332d4fcf9b03268);
    src:url(/assets/common_assets/font/icons.eot?#iefix) format("embedded-opentype"),
        url(/assets/common_assets/font/icons.woff?522cad3ab746d1234332d4fcf9b03268) format("woff"),
        url(/assets/common_assets/font/icons.ttf?522cad3ab746d1234332d4fcf9b03268) format("truetype");
    font-weight:normal;
	font-style:normal;
	font-display: swap;
}

.icon {
    font-family:"icons";
    display:inline-block;
    vertical-align:middle;
    line-height:1;
    font-weight:normal;
    font-style:normal;
    speak:none;
    text-decoration:inherit;
    text-transform:none;
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

.icon-white-flight {
    color:#ffffff;
	@media (max-width: 991px) {
		color: #000000;
	}
}

.icon-avail-flight {
    color: #000000;
}

/* Icons */

.icon_airconditioner:before {
	content:"\f101";
}

.icon_alert-circle:before {
	content:"\f102";
}

.icon_alert:before {
	content:"\f103";
}

.icon_arrow_down:before {
	content:"\f104";
}

.icon_arrow_left:before {
	content:"\f105";
}

.icon_arrow_right:before {
	content:"\f106";
}

.icon_arrow_up:before {
	content:"\f107";
}

.icon_banknote:before {
	content:"\f108";
}

.icon_bathroom-linear:before {
	content:"\f109";
}

.icon_bathroom:before {
	content:"\f10a";
}

.icon_budget:before {
	content:"\f10b";
}

.icon_bundle_-experiences:before {
	content:"\f10c";
}

.icon_bundle_bus:before {
	content:"\f10d";
}

.icon_bundle_car:before {
	content:"\f10e";
}

.icon_bundle_corporate:before {
	content:"\f10f";
}

.icon_bundle_cruise:before {
	content:"\f110";
}

.icon_bundle_flight-hotel:before {
	content:"\f111";
}

.icon_bundle_flight:before {
	content:"\f112";
}

.icon_bundle_holiday:before {
	content:"\f113";
}

.icon_bundle_hotel:before {
	content:"\f114";
}

.icon_bundle_insurance:before {
	content:"\f115";
}

.icon_bundle_package:before {
	content:"\f116";
}

.icon_bundle_train:before {
	content:"\f117";
}

.icon_bus:before {
	content:"\f118";
}

.icon_cards_circle:before {
	content:"\f119";
}

.icon_cards:before {
	content:"\f11a";
}

.icon_chatboxes:before {
	content:"\f11b";
}

.icon_checkmark:before {
	content:"\f11c";
}

.icon_chevron_down:before {
	content:"\f11d";
}

.icon_chevron_left:before {
	content:"\f11e";
}

.icon_chevron_right:before {
	content:"\f11f";
}

.icon_chevron_up:before {
	content:"\f120";
}

.icon_communications:before {
	content:"\f121";
}

.icon_contact_address:before {
	content:"\f122";
}

.icon_contact_phone:before {
	content:"\f123";
}

.icon_cruise:before {
	content:"\f124";
}

.icon_cursor:before {
	content:"\f125";
}

.icon_dart_left:before {
	content:"\f126";
}

.icon_dart_right:before {
	content:"\f127";
}

.icon_dart-open_double:before {
	content:"\f128";
}

.icon_dart-open_left:before {
	content:"\f129";
}

.icon_dart-open_right:before {
	content:"\f12a";
}

.icon_discount:before {
	content:"\f12b";
}

.icon_disney:before {
	content:"\f12c";
}

.icon_door:before {
	content:"\f12d";
}

.icon_edit-circle:before {
	content:"\f12e";
}

.icon_edit:before {
	content:"\f12f";
}

.icon_education:before {
	content:"\f130";
}

.icon_flight_multidest:before {
	content:"\f131";
}

.icon_flight_oneway:before {
	content:"\f132";
}

.icon_flight_roundtrip:before {
	content:"\f133";
}

.icon_foodmenu:before {
	content:"\f134";
}

.icon_helicopter:before {
	content:"\f135";
}

.icon_individualscreen:before {
	content:"\f136";
}

.icon_info:before {
	content:"\f137";
}

.icon_inicio-sesion:before {
	content:"\f138";
}

.icon_load:before {
	content:"\f139";
}

.icon_login:before {
	content:"\f13a";
}

.icon_luggage:before {
	content:"\f13b";
}

.icon_magnifying-glass:before {
	content:"\f13c";
}

.icon_manage_reservation:before {
	content:"\f13d";
}

.icon_map:before {
	content:"\f13e";
}

.icon_megaphone:before {
	content:"\f13f";
}

.icon_minus-outline:before {
	content:"\f140";
}

.icon_minus:before {
	content:"\f141";
}

.icon_moon:before {
	content:"\f142";
}

.icon_multipledrivers:before {
	content:"\f143";
}

.icon_newsletter_circle:before {
	content:"\f144";
}

.icon_newsletter:before {
	content:"\f145";
}

.icon_newsletters_circle:before {
	content:"\f146";
}

.icon_newsletters:before {
	content:"\f147";
}

.icon_no-luggage:before {
	content:"\f148";
}

.icon_options:before {
	content:"\f149";
}

.icon_order-asc:before {
	content:"\f14a";
}

.icon_order-desc:before {
	content:"\f14b";
}

.icon_passenger_baby:before {
	content:"\f14c";
}

.icon_passenger_child:before {
	content:"\f14d";
}

.icon_passenger_man:before {
	content:"\f14e";
}

.icon_plus-outline:before {
	content:"\f14f";
}

.icon_plus:before {
	content:"\f150";
}

.icon_printer:before {
	content:"\f151";
}

.icon_question_mark:before {
	content:"\f152";
}

.icon_recliningchairs:before {
	content:"\f153";
}

.icon_rental_door:before {
	content:"\f154";
}

.icon_rental_flake:before {
	content:"\f155";
}

.icon_rental_gear:before {
	content:"\f156";
}

.icon_search_calendar-in:before {
	content:"\f157";
}

.icon_search_calendar-out:before {
	content:"\f158";
}

.icon_search_calendar:before {
	content:"\f159";
}

.icon_search_clock:before {
	content:"\f15a";
}

.icon_search_close:before {
	content:"\f15b";
}

.icon_seat:before {
	content:"\f15c";
}

.icon_social_facebook:before {
	content:"\f15d";
}

.icon_social_google-plus:before {
	content:"\f15e";
}

.icon_social_instagram:before {
	content:"\f15f";
}

.icon_social_linkedin:before {
	content:"\f160";
}

.icon_social_pinterest:before {
	content:"\f161";
}

.icon_social_rss:before {
	content:"\f162";
}

.icon_social_twitter:before {
	content:"\f163";
}

.icon_social_youtube:before {
	content:"\f164";
}

.icon_socketenergy:before {
	content:"\f165";
}

.icon_stairs:before {
	content:"\f166";
}

.icon_star_off:before {
	content:"\f167";
}

.icon_star_on:before {
	content:"\f168";
}

.icon_sun:before {
	content:"\f169";
}

.icon_table:before {
	content:"\f16a";
}

.icon_train:before {
	content:"\f16b";
}

.icon_tv:before {
	content:"\f16c";
}

.icon_warning:before {
	content:"\f16d";
}

.icon_whatsapp:before {
	content:"\f16e";
}

.icon_wifi:before {
	content:"\f16f";
}

.icon_world:before {
	content:"\f170";
}

// .icon_arrow_down-flight:before {
// 	content:"\f104";
// 	color:#ffffff;
//     @include media-query(palm) {
//         color:#000000;
//     }
// }

// Material icons

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/common_assets/font/material-icons/Material-icons-round.woff2) format('woff2');
}

.material-icons-round {
	font-family: 'Material Icons Round';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
  }
/* fallback */
@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 100 700;
	src: url(/assets/aviatur_assets/font/material-icons/MaterialIconsRound.woff2) format('woff2');
}
  
.material-symbols-outlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

@font-face {
	font-family: 'Material Symbols Rounded';
	font-style: normal;
	font-weight: 100 700;
	src: url(/assets/aviatur_assets/font/material-icons/MaterialIconsRound.woff2) format('woff2');
}

.material-symbols-rounded {
	font-family: 'Material Symbols Rounded';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}