///*------------------------------------*\
//    #DETAIL-MULTI
//\*------------------------------------*/

.detail-multi__detail-nav {
    margin: 0;
    @include media-query(desk){
        margin-top: $inuit-base-spacing-unit + $inuit-base-spacing-unit--tiny;
    }
}
.payoutExtra_modal{
    width: 97% !important;
    float: left;
    /* width: 100%; */
    height: 220px;
    padding-top: 0px;
    padding-left: 15px;

    @include media-query(palm){
        width: 95% !important;
    }
}
.reconfirmModal{
    height:320px;
    @include media-query(palm){
        height:370px;
    }
    @media screen and (max-width:320px) {
        height:420px;
    }
}
.detail-multi__detail-nav-li {
    @include span(1 of 2);
    padding:0px;
}
