///*------------------------------------*\
//    #RESUME-SHARED
//\*------------------------------------*/

.resume__col {
    @include span(1 of 2);
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.resume__col-full {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
}

.resume__col_cash {
    @extend .resume__col-full;
    color: #666;
    font-weight: lighter;
    text-align: justify;
}

.resume__col-pdf {
    @include span(1 of 2);
}

.resume__row {
    border-bottom: 1px solid $color-resume-border;
    margin-bottom: $inuit-base-spacing-unit--small;
}
.resume__row-pdf {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.resume__row:last-child {
    border-bottom: none;
}

.resume__row-small-title {
    @include font-weight(bold);
    @include span(1 of 9);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.resume__row-big-title {
    @include font-weight(bold);
    @include span(2 of 9);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.resume__row-small-content {
    @include span(1 of 9);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.resume__row-big-content {
    @include span(2 of 9);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.resume__row-small-title-pdf {
    @include font-weight(bold);
    @include span(1 of 9);
}

.resume__row-big-title-pdf {
    @include font-weight(bold);
    @include span(2 of 9);
}

.resume__row-doublebig-title-pdf {
    @include font-weight(bold);
    @include span(3 of 10);
}
.resume__row-small-content-pdf {
    @include span(1 of 9);
}

.resume__row-big-content-pdf {
    @include span(2 of 9);
}

.resume__row-doublebig-content-pdf {
    @include span(3 of 10);
}
.resume__row-title {
    color: grey;
    @include font-weight(bold);
    @include span(1 of 3);
}
.resume__row-content {
    @include span(2 of 3);
}

.resume__row-passenger {
    @include span(3 of 5);
}
.resume__row-ticket {
    @include span(2 of 5);
    @include media-query(desk) {
        margin-top: $inuit-base-spacing-unit;
    }
}

.resume__div-cash{
    margin-left: 20px;
    margin-right: 20px;
}
