/*------------------------------------*\
  #FORMS
\*------------------------------------*/


.text-input {
    &.is-hightlight {
        border-color: $color-form-hightlight;
        box-shadow: inset 0 0 2px 2px rgba(0,0,0,.06);
    }
    &.is-readonly {
        background-color: $color-form-readonly;
    }
    &:not(.no-focus):active,
    &:not(.no-focus):focus,
    &.is-active {
            box-shadow: none;
    }
}
input.text-input {
  font-size:1rem;
  line-height: normal; //xbrowser placeholder position
  background-color: #eee;
  border: 0 solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 2.5rem;
  border-radius: 2px;
  color: #5a5a5a ;
  font-family: Ubuntu;
  font-weight: 400;
  @include media-query(palm) {
    color: #5a5a5a !important;
  }
}
/*.text-input.is-active {
    color: darken($color-form-underline, 10%);
}*/

.text-input--underline {
    width: 100%;
    padding-left: 2px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom: 2px solid rgb(238, 238, 238);
    color: $color-form-underline;
    background-color: rgba(0,0,0,0);
    transition: color .2s, border-bottom .2s;
    outline: 0;
    @include font-size( 20px);
    @include font-weight(bold);
    &:active,
    &:focus {
        border-bottom-color: darken($color-form-underline, 10%);
    }
    @include attention() {
        color: darken($color-form-underline, 10%);
    }
    @media screen and (min-width:1023px) and (max-width:1070px) {
        font-size: 1rem;
    }
}

.text-input--inline {
    @extend .text-input--underline;
    display: inline;
    max-width: 4em;
    padding: 0;
    text-align: center;
    line-height: 1;
    font-size: 1rem;
    margin: -2px calc($inuit-base-spacing-unit--small/2) 0;
    border-color: rgba($gray-base, .09);
}

.text-input--no-upper-border{
    margin-top: -1px;
}

.text-input--no-border {
    background-color: rgba(0,0,0,0) !important;
    border: 0;
    padding-left: 0;
}

.divider{
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  padding-bottom: 20px;
  margin-bottom:20px;
}

.form-horizontal-line-palm {
    color: #d1d1d1;
    visibility:hidden;
    @media (max-width: 991px) {
        visibility:visible;
    }
}

.form-horizontal-line-palm-more-options {
    border-bottom-width: 0.5px; 
    border-bottom-style: solid;
    border-bottom-color: #8B8B8B !important;
    @media (max-width: 991px) {
        border-bottom-color: #042A44 !important;
    }
}

.form-special-border-more-options {
    border: revert !important;
    border-style: double !important;
    @media (max-width: 991px) {
        border: 1px #d1d1d1 !important;
        border-style: solid !important;
    }
}

.form-horizontal-line-journey {
    color: #d1d1d1;
    visibility:visible;
    border: revert;
    @include media-query(desk) {
        width: 75%;
    }
    @include media-query(lap) {
        width: 100%;
    }
    @include media-query(palm) {
        visibility:hidden;
    }
}

.text-input-multi {
    font-size:1rem;
    &.is-hightlight {
        border-color: $color-form-hightlight;
        box-shadow: inset 0 0 2px 2px rgba(0,0,0,.06);
    }
    &.is-readonly {
        background-color: $color-form-readonly;
    }
    &:not(.no-focus):active,
        &:not(.no-focus):focus,
        &.is-active {
        box-shadow: 0 0 4px $color-form-underline;
    }
}
input.text-input-multi {
  font-size:1rem;
  line-height: normal; //xbrowser placeholder position
  background-color: #eee;
  border: 0px solid #eee;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 40px;
  border-radius: 2px;
  color: #444;
  padding-right: 40px;
}

.padding-advanced-options{
    padding-top: 15px;
}