///*------------------------------------*\
//  #BUNDLE BLOG
//\*------------------------------------*/
.wrapper_blog {
    width: 1263px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    border-bottom: 1px solid white;
    position: fixed;
    top: 161px;
    z-index: 1;
    width: 100%;
    background-color: rgba(50, 51, 51, 0.6);
    @include media-query(palm) {
        height: auto;
        position: relative;
        background-color: transparent;
        padding-left: 0px;
        top:0 !important;
    }
    @include media-query(lap) {
        padding-left: 0px;
    }

}
.subscribeicon{
    color:$color-btn;
    line-height: 2;
    font-size: 50px;
    @include media-query(palm) {
        margin-left: auto;
        margin-right: auto;
        width: 1em;
        font-size: 65px;
    }
}
.wrapper_blog-av{
    width: 1263px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: rgba(50, 51, 51, 0.69);
}
.wrapper_blog-content{
    width: 1263px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 58px;
    border-bottom: 1px solid white;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: rgba(50, 51, 51, 0.6);
    @include media-query(palm) {
        height: auto;
        position: relative;
        background-color: transparent;
        padding-left: 0px;
        top:0 !important;
    }
    @include media-query(lap) {
        padding-left: 0px;
    }
}

.main_blog {
    position: relative;
    text-align: center;
    @include media-query(palm) {
        padding: 23px 0;
    }
    @include media-query(lap-and-up) {
        position: relative;
        width: auto;
        padding: 17px 25px;
        padding-bottom: 27px;
        text-align: center;
    }
}

.nav-main-blog{
    float: left;
    margin: 18px 0px 0px 150px;
    @include media-query(palm) {
        margin: 9px 0;
        padding: 0 9px;
    }
}

.nav-main-av{
    float: left;
    margin: 18px 0px 0px 50px;

}

.page-head--blog {
    height: 590px;
    position: relative;
    margin-bottom: 18px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    @include media-query(palm) {
        height: auto;
    }
}

.page-head--view_blog {
    height: 690px;
    position: relative;
    margin-bottom: 18px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    @include media-query(palm) {
        height: auto;
    }
}

.page-head__aviatur-blog {
    max-height: 64px;
    margin-right: 10px;
    @include media-query(palm) {
        margin-left: 6%;
        display: block !important;
    }
}

.sp-vinset--av .page-head__aviatur{
    padding-right: 0px;
}

.nav-main__grid-blog {
    float: left;
    @include media-query(palm) {
        width: 100%;
        border-top: 2px solid $color-frame-border;
        //border-right: 2px solid $color-frame-border;
        &:nth-child(1) {
            border-top-width: 0;
        }
    }
}

.content-blog {
    width: 24.5%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.content-blog_destinos {
    width: 32%;
    margin-right: 15px !important;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.img-article-blog {
    min-height: 270px;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    text-align: right;
    margin-bottom:10px;
}

.wrapper1-blog {
    width: 77%;
    float: left;
    padding-top: 30px;
    margin-right: 30px;
}

.wrapper2-blog {
    width: 21%;
    text-align: center;
    position: absolute;
    right: 0;
    height: 100%;
}

.dispo-destinos {
    padding: 0;
}

.blog__carousel__image {
    width: 100%;
    height: 590px;
    object-fit: cover;
    opacity: 0.8;
}

.destinos-blog {
    height: 490px;
    background-image: url(/source/contenidos/blog/destinos-de-viajes.jpg);
    background-size: cover;
}

.view-article-blog {
    height: 690px;
    background-size: cover;
    background-position: center;
}

.title-view-article {
    position: relative;
    //top: 70%;
    padding: 33px;
    //height: auto;
    text-align: center;
    background-color: rgba(0, 123, 191, 0.6);
    @include media-query(palm) {
        padding: 23px;
    }
}

.title-view-article_cont {
    position: relative;
    padding: 33.3px;
    text-align: center;
    background-color: rgba(0, 123, 191, 0.6);
}
.title-blog_cont1 {
    top: 80%;
    height: 20%;
}
.title-blog_cont2 {
    top: 68%;
    height: 32%;
}
.title-blog_cont3 {
    top: 63%;
    height: 37%;
}

.info_destinos-blog {
    max-width: 285px;
    width: auto;
    margin: 10px 10px;
    display: inline-block;
}

.img-destinos {
    margin-right: 5px;
    height: 400px;
    background-size: cover;
    margin-bottom: 15px;
    /* filter: contrast(85%); */
    &:hover {
        z-index: 19;
        transform: scale(1.03);
        box-shadow: 0px 28px 81px -7px rgba(0, 0, 0, 0.44);
    }
}
.img-destinos:hover{
    z-index: 19;
    transform: scale(1.03);
    box-shadow: 0px 28px 81px -7px rgba(0,0,0, 0.44);
}
.tips-blog {
    height: 490px;
    background-image: url(/source/contenidos/blog/tips-de-viajes.jpg);
    background-size: cover;
}

.gastronomia-blog {
    height: 490px;
    background-image: url(/source/contenidos/blog/gastronomia-al-viajar.jpg);
    background-size: cover;
    filter: contrast(85%);
}

.experiencias-blog {
    height: 490px;
    background-image: url(/source/contenidos/blog/experiencias-de-viaje.jpg);
    background-size: cover;
}

.nos-fuimos-blog{
    height: 350px;
    background-image: url(/source/contenidos/blog/nos-fuimos.jpg);
    background-size: cover;
    background-position: center;
}

.text-promo_blog {
    color: white;
    font-weight: 600;
    font-size: 50px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.60);
}

.subtext-promo_blog {
    color: white;
    font-weight: 600;
    text-shadow: 2px 2px 5px #000;
}

#breadcumb-blog{
    padding-left: 60px;
    @include media-query(palm) {
        padding-left: 0px;
    }
}

@media screen and (max-width: 44.9375em) {
    .destinos-blog, .tips-blog, .gastronomia-blog, .experiencias-blog {
        height: 300px;
    }
    .nos-fuimos-blog{
        height: 210px;
    }
    .tips-blog, .experiencias-blog {
        background-position: center;
    }
    .text-promo_blog {
        font-size: 25px;
        margin: 0px;
    }
    .view-article-blog {
        height: 290px;
        margin-bottom: 18px;
        margin-top: -15px;
    }
    /* .title-view-article {
         top: 40%;
         height: 60%;
     }*/
    .title-view-article_cont {
        padding: 25px 5px; }
    .title-blog_cont1, .title-blog_cont2{
        top: 36%;
        height: 65%;
    }
    .title-blog_cont3{
        top: 33%;
        height: 67%;
    }
    .subtext-promo_blog{
        font-size: 21px;
    }
    .info_destinos-blog{
        width: 100%;
    }
}

p.text-letter::first-letter {
    font-size: 220%;
    font-weight: 600;
    color: #009bf8;
}

.btn-blog {
    padding: 25px 5px;
    padding-bottom: 10px;
    float: left;
    @include media-query(palm) {
        display:none;
    }
}

.btn-sub {
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.45);
    border-color: white;
    font-size: 18px;
    padding: 10px 15px;
    box-shadow: none;
}

.slider-blog {
    position: relative;
    transform: translate3d(0, -300%, 0);
    top: 50%;
}

.slider-blog_btn {
    position: relative;
    transform: translate3d(0, -330%, 0);
    top: 50%;
    padding: 10px;
}

.title-slider-blog {
    color: white;
    font-weight: 600;
    font-size: 50px;
    text-shadow: 2px 2px 5px #000;
    padding: 10px;
}
.text-destinos{
    background-color: rgba(0, 123, 191, 0.6);
    padding: 8px;
}

.sp-vinset--blog{
    padding:0;
    margin-top:10px;
    float: right;
    @include media-query(palm) {
        margin-top:5px !important;
    }
}

.sp-vinset--av{
    padding: 10px 10px 18px 0px;
}

/* ----------- iPad----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .main_blog {
        padding: 18px;
    }
    .nav-main-blog {
        margin: 10px 0px 0px;
    }
    .btn-blog {
        padding-bottom: 0px;
    }
    .wrapper_blog-av{
        padding-left:0px;
        padding-bottom: 10px;
    }
    .content-blog {
        width: 49%;
        height: 500px;
    }
    .slider-blog_btn {
        transform: translate3d(0, -250%, 0);
    }
    .title-view-article_cont.title-blog_cont2 {
        padding: 17px 5px; }
    .sp-vinset--av{
        padding-bottom: 0px;
    }
    .wrapper_blog{
        top: 147px;
    }
    .wrapper_blog-content{
        padding-bottom: 5px;
    }

}

@media only screen and (min-device-width: 720px) and (max-device-width:721px){
    .main_blog {
        padding: 18px;
    }
    .nav-main-blog {
        margin: 10px 0px 0px;
    }
    .btn-blog {
        padding-bottom: 0px;
    }
    .wrapper_blog-av{
        padding-left:0px;
        padding-bottom: 10px;
    }
    .content-blog {
        width: 49%;
        height: 500px;
    }
    .slider-blog_btn {
        transform: translate3d(0, -250%, 0);
    }
    .title-view-article_cont.title-blog_cont2 {
        padding: 17px 5px; }
    .sp-vinset--av{
        padding-bottom: 0px;
    }
    .wrapper_blog{
        top: 196px;
    }
    .wrapper_blog-content{
        padding-bottom: 5px;
    }
    .view-article-blog{
        height: 290px;
        margin-bottom: 18px;
        margin-top: -15px;
    }
    #breadcumb-blog {
        padding-left: 40px;
    }
    .wrapper1-blog {
        width: 100%;
    }
}
@media only screen and (min-device-width: 721px) and (max-device-width: 1023px){
    .main_blog {
        padding: 18px;
    }
    .nav-main-blog {
        margin: 10px 0px 0px;
    }
    .btn-blog {
        padding-bottom: 0px;
    }
    .wrapper_blog-av{
        padding-left:0px;
        padding-bottom: 10px;
    }
    .content-blog {
        width: 49%;
        height: 500px;
    }
    .slider-blog_btn {
        transform: translate3d(0, -250%, 0);
    }
    .title-view-article_cont.title-blog_cont2 {
        padding: 17px 5px; }
    .sp-vinset--av{
        padding-bottom: 0px;
    }
    .wrapper_blog{
        top: 226px;
    }
    .wrapper_blog-content{
        padding-bottom: 5px;
    }

}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .content-blog {
        width: 49%;
        height: 600px;
    }
    .slider-blog_btn {
        transform: translate3d(0, -240%, 0);
        padding: 20px;
    }

    .wrapper_blog-av{
        padding-left:19px;
        padding-bottom: 10px;
    }
    .wrapper_blog-content{
        padding-bottom: 5px;
    }
}

.destinos-promo-blog {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    float: left;
    padding: 0; }

.enlace-destinos_blog {
    width: 205px;
    position: relative;
    float: left;
    margin: 2px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}
.ofer__destinos_blog {
    max-width: 205px;
    width: 100%;
    margin: 10px 10px;
    display: inline-block; }

.ofer__destinos_blog h5 {
    left: 0;
    top: 48%;
    width: 100%;
    font-size: 18px;
    line-height: 20px;
    text-align:center;
    color: #fff;
    position: absolute;
    text-shadow: 2px 2px 2px #000; }
.r3{
    min-height: auto !important;
}

#google_translate_element{
    padding: 10px;
}

.fecha-blog{
    font-size:14px;
}
.box-blog_content{
    line-height: inherit;
    font-weight: 600;
    min-height: 44px;
}

.withoutSlider{
    height: 258px;
    background-image: url(https://www.aviatur.com/source/contenidos/hotel-las-islas-baru-cartagena.jpeg);
}
.withoutSlider2{
    height: 290px;
    background-image: url(https://www.aviatur.com/source/contenidos/hotel-las-islas-baru-cartagena.jpeg);
}

@media screen and (min-width: 328px) and (max-width: 370px) {
    .modal-blog{
        padding-right: 0px !important;
    }
    marco {padding: 0px !important;}
}
