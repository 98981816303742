///*------------------------------------*\
//    #PROMO-BANNER
//\*------------------------------------*/

.promo-crossFading {
    position:relative;
    margin:0 auto;
}
.promo-crossFading img:first-child {
    position: absolute;
    opacity: 0;
}
.main_FAQ-wrapper{
    background-color: #f4f4f4;
    h2{
        font-family:'Roboto';
    }
} 

.main__newsletter-block {
    min-height: $inuit-base-spacing-unit--huge+2px;
    @include media-query(palm) {
        padding-bottom: $inuit-base-spacing-unit;
    }
}

/*.banner-app {
    display: none;
    background-color: rgba(46, 0, 145, .8);
    position: relative;
    padding: 10px 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

.banner-app .title {
    margin:0 85px 0 40px;
    padding-left:50px;
    font-size:1.2EM;
    line-height:1.1EM;
    position:relative;
    color:#fff;
    font-size:14px;
    font-weight:bold;
    font-family:Arial, Helvetica, sans-serif;
    max-width:185px;
    padding-bottom:12px;
}
.banner-app .title:before {
    content:'';
    background-image: url(/assets/aviatur_assets/img/header/aviatur-download-app.png);
    background-position:center center;
    background-repeat:no-repeat;
    border-radius:4px;
    width:42px;
    height:42px;
    display:block;
    position:absolute;
    left:0;
    top:50%;
    margin-top: -21px;
}
.banner-app .title:after {
    content:'';
    background-repeat:no-repeat;
    background-size:60px 10px;
    display:block;
    width:60px;
    height:10px;
    position:absolute;
    bottom:0;
    left:50px;
}
.banner-app .download-link {
    position:absolute;
    right:10px;
    top:50%;
    margin-top:-15px;
    color:#fff;
    margin-right:10px;
    border:1px solid #fff;
    border-radius:4px;
    font-size:15px;
    font-weight:bold;
    padding:5px 10px;
    font-family:Arial, Helvetica, sans-serif;
}
.banner-app .close {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    padding: 15px;
}
@media screen and (  max-width: 320px  ) {
    .banner-app .download-link {
        font-size:12px;
    }
}
@media screen and (max-width: 370px) {
    .banner-app .title {
        font-size:12px;
        max-width:150px;
    }
}  */

.promo-banner__carousel {
    position: relative;
    width: 100%;
    text-align: center;
}
.promo-banner__carousel-items {
    position: relative;
    height: 200px;
}
.promo-banner__carousel-item {    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    img {
        width: inherit;     
    }
}
.active-slide {
    opacity: 1;
    visibility: visible;
}
.container {
    position: relative;
    text-align: center;
    color: white;
    border-left: 0rem;
}

.container2 {
    position: relative;
    text-align: left;
    border-left: 0rem;
    padding: 0;
    list-style: none;
}


// .texto-bottom-left {
//     font-weight: 500;
//     font-family: 'Roboto';
//     position: absolute;
//     bottom: 19%;
//     color: white;
//     font-size: 270%;
//     text-align: justify;
//     width: 33rem;
//     line-height: 4.125rem;
//     left: 5rem;
// }

// .texto-top-left {
//     position: absolute;
//     top: 22%;
//     color: white;
//     font-size: 215%;
//     width: 28%;
//     line-height: 2.125rem;
//     letter-spacing: -0.124rem;
//     left: 70px
// }

// .texto-top-right {
//     // position: absolute;
//     // top: 1.563rem;
//     // right: -14.688rem;
//     // color: white;
//     // font-size: 2.117rem;
//     // text-align: justify;
//     // width: 33rem;
//     // line-height: 2.125rem;
//     // padding: 15px 95px;
//     position: absolute;
//     top: 1.563rem;
//     right: 2rem;
//     color: white;
//     font-size: 2.117rem;
//     text-align: center;
//     width: 12rem;
//     line-height: 2.125rem;
//     /* padding: 15px 95px; */
//     border: solid 1px;

//     @media-query(lap) {
//         color: #FFF;
//         font-family: Ubuntu;
//         font-size: 25px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: normal;
//     }
// }

.not-bg-img {
    background-image: url(none) !important;
}

@media screen and (min-width: 45.125em) and (max-width: 63.9375em) {
    // .texto-top-left {
    //     /*@include span(1 of 2);*/
    //     width: 26%;
    //     top: 17%;
    //     float: left;
    //     font-size: 170%;
    //     left: 2rem;
    //     padding-right: 9px;
    //     color: white;
    //     min-width: 39%;
    // }

    // .texto-bottom-left {
    //     position: absolute;
    //     bottom: 19%;
    //     color: white;
    //     font-size: 200%;
    //     text-align: justify;
    //     width: auto;
    //     line-height: 0.125rem;
    //     left: 3rem;
    // }
}
@media screen and (max-width: 45.0625em) {
    // .texto-top-left {
        /*@include span(full);*/
        /*
        position: absolute;
        top: 37%;
        font-size: 2.4rem;
        width: 86%;
        line-height: 29PX;
        letter-spacing: -0.124rem;
        padding-left: 19px;
        color: white;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        text-align: left;
        */
        
        // font-family: Ubuntu;
        // font-size: 17px;
        // font-weight: 500;
        // line-height: 20px;
        // letter-spacing: 0em;
        // text-align: left;
        
        // color: #FFF;
        // font-family: Ubuntu;
        // font-size: 18px;
        // font-style: normal;
        // font-weight: 500;
        // line-height: normal;

    //     font-family: Ubuntu;
    //     font-size: 18px;
    //     font-style: normal;
    //     font-weight: 500;
    //     line-height: normal;
    //     width: 86%;
    //     text-align: left;
    //     left: 5%;
    //  }

    .adjust-img-mobile {
        height: 17rem;
        background-size: cover;
        object-fit: cover;
        object-position: 6%;
        font-family: Roboto;
        border-radius: 15px;
    }

    // .texto-bottom-left {
    //     // position: absolute;
    //     // bottom: 19%;
    //     // color: white;
    //     // font-size: 225%;
    //     // text-align: justify;
    //     // width: auto;
    //     // line-height: 1.125rem;
    //     // padding-left: 7%;
    //     // top: 37%
        
    //     font-family: Ubuntu;
    //     font-size: 25px;
    //     font-weight: 500;
    //     line-height: 29px;
    //     letter-spacing: 0em;
    //     text-align: left;
    //     width: 252px;
    //     left: 5%;
    //     bottom: 33%;

    // }
}



$text-color: #fff;
$font-family-ubuntu: 'Ubuntu';
$font-family-roboto: 'Roboto';

.texto-top-right {
    // position: absolute;
    // top: 1.563rem;
    // right: -14.688rem;
    // color: white;
    // font-size: 2.117rem;
    // text-align: justify;
    // width: 33rem;
    // line-height: 2.125rem;
    // padding: 15px 95px;
    position: absolute;
    top: 1.563rem;
    right: 2rem;
    color: white;
    font-size: 2.117rem;
    text-align: center;
    width: 12rem;
    line-height: 2.125rem;
    /* padding: 15px 95px; */
    // border: solid 1px;

    @include media-query(palm) {
        color: #FFF;
        font-family: Ubuntu;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: right;
    }
}



.adjust-img-mobile {
    @include media-query(palm) {
        min-width: 328px;
        height: 185px;
        // min-width: auto;
        // height: 185px;
        // width: 328px;
    }
}

@media only screen and (max-width: 321px) {
    .adjust-img-mobile {
        min-width: 295px;
        height: 185px;
    }
}

.texto-top-left {
    position: absolute;
    top: 22%;
    color:  $text-color;
    font-family: $font-family-roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    width: 20rem;
    left: 5rem;
    text-align: left;

    @include media-query(lap) {
        color: $text-color;
        font-family: $font-family-ubuntu;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        line-height: normal;
        width: 270px;
        left: 5%;
    }
    @include media-query(palm) {
        // color: $text-color;
        // font-family: $font-family-ubuntu;
        // font-size: 18px;
        // font-style: normal;
        // font-weight: 500;
        // line-height: normal;
        // width: 195px;
        // text-align: left;
        // left: 5%;
        // top: 30%;

        color: $text-color;
        font-family: $font-family-ubuntu;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        /* line-height: normal; */
        width: 195px;
        text-align: left;
        left: 11%;
        top: 17%;
    }
}
.texto-bottom-left {
    font-family: $font-family-roboto;
    position: absolute;
    bottom: 19%;
    color: $text-color;
    text-align: justify;
    width: 33rem;
    left: 5rem;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 49.305px;

    @include media-query(lap) {
        font-family: $font-family-ubuntu;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        left: 5%;
        bottom: 25%;
    }
    @include media-query(palm) {
        // font-family: $font-family-ubuntu;
        // font-size: 25px;
        // font-style: normal;
        // font-weight: 500;
        // line-height: normal;
        // width: 252px;
        // left: 5%;
        // bottom: 33%;

        font-family: "Ubuntu";
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 252px;
        left: 11%;
        bottom: 25%;
    }
}