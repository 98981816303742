
/* Customize the label (the container) */
.container-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: max-content;
}

/* Hide the browser's default checkbox */
.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

///*------------------------------------*\
//  #DETAIL-INFO-HOTEL
//\*------------------------------------*/

.detail-info-hotel {
    h3 {
        /*color: $color-gree-text;
        font-weight: 600;*/
        font-size: 14px;
        line-height: 21px;
        color: #1860B6;
        font-weight: bold;
        border-bottom: 3px solid;
        width: max-content;
    }
    .slideItem {
        .hotel__slide-imgM {
            object-fit: cover;
        }
    }
    .owl-dots {
        display: none;
    }
    @include media-query (palm) {
        .detail-shared__dispo-back {
            background-color: transparent;
            box-shadow: none;
            text-align: inherit;
            padding: 0;
        }
    }
    .detail-hotel-image,
    .owl-controls {
        cursor: pointer;
    }
}

.detail__info-images {
    margin-top: 10px;
    div {
        padding: 0 4px;
    }
    .detail__info-image {
        background-repeat: no-repeat !important;
        background-position-x: 50% !important;
        background-size: cover !important;
        border-radius: 10px;
        cursor: pointer;
        &:nth-child(1) {
            min-height: 400px;
        }
    }
    .detail__info-images-two {
        .detail__info-image {
            &:nth-child(1) {
                min-height: 195px;
                margin-bottom: 9px;
            }
            &:nth-child(2) {
                min-height: 195px;
            }
        }
    }
    .condition__images {
        padding-top: 8px;
    }
}
.modal__info-image {
    background: no-repeat center center;
    width: 100%;
    height: 65vh;
    background-size: contain;
}
.modal-panel {
    position: fixed;
    z-index: 998;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-bottom: 0;
    background-color: white;
    transition: transform 0.5s ease;
    transform: translate(0, -120%);
    .close__modal {
        position: absolute;
        right: 4%;
        top: 6%;
        background-color: lighten($color-gree-text, 65);
        color: black;
        border: 0;
        border-radius: 6px;
        padding: 7px 12px;
        z-index: 999;
        @include attention () {
            background-color: lighten($color-gree-text, 55);
        }
    }
    #number-photos {
        position: absolute;
        left: 48%;
        top: 6%;
        color: black;
        font-weight: 600;
    }
    .modal-images__hotel {
        width: 7%;
    }
}

.modal-panel__title {
    margin: 2% 8%;
}

.modal-panel__carrousel {
    height: 100%;
    margin: 6% 8%;
    .owl-buttons .owl-prev,
    .owl-next {
        background-color: white !important;
        border: 1px solid #9fc2e4;
        padding: 14px 15px !important;
        .icon {
            color: $color-main;
        }
        @include attention () {
            background-color: #9fc2e4 !important;
        }
    }
    .detail-hotel__title {
        text-align: center;
        margin-top: 10px;
    }
    .modal-listImages {
        margin-top: 10px;
    }

}
.panel-form {
    top: -10px;
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translate(120%, 0);
    .is-withHeader {
        margin: 0 0 3%;
        @include media-query (palm) {
            margin: 10% 0;
            .fixed--bottom {
                padding-top: 19px;
            }
        }
    }
}
.modal__panel-form {
    @include media-query (desk) {
        width: 83%;
        margin: 0 auto;
    }
    padding-left: 9px;
    padding-right: 9px;
}
.modal-panel.is__active-Modal {
    transform: translate(0,0);
    animation: fadeinout 0.5s linear forwards;
}
.detail__info--hotel-head {
    .detail-info__title {
       color: $color-gree-text;
    }
    .detail-info__address {
        color: lighten($color-gree-text, 35);
        margin-right: 20px;
    }
    .detail__info-viewMap {
        .icon_contact_address {
            font-size: 14px;
            padding-top: 2px;
            margin-right: 3px;
            &::before {
                vertical-align: super;
            }
        }
    }
}
.detail__hotel--body {
    margin-bottom: 3rem;
}
.detail__info-icons .icon-s {
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 30px;
    width: 9%;
    margin-right: 40px;
    @include media-query (palm) {
        margin-right: 30px;
    }
}
.detail__info-icons {
    margin-top: 10px;
    .icon_wifi {
        font-size: 40px;
        margin-top: -45px;
    }
    .icon_parking {
        background-image: url(/assets/common_assets/img/icon/parking.svg);
    }
    .icon_gym {
        background-image: url(/assets/common_assets/img/icon/dumbbell.svg);
    }
    .icon_r-service {
        background-image: url(/assets/common_assets/img/icon/roomService.svg);
    }
    .icon_restaurant {
        background-image: url(/assets/common_assets/img/icon/restaurant.svg);
    }
}
.detail__info--description {
    /*padding: 15px 0;*/
    /*border-bottom: 1px solid lighten($color-gree-text, 55);*/
    margin-bottom:10px;
    div {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #7D7D7D;
    }
}

.detail__info--roomtypes {
    .detail-hotel__label,
    p {
        font-size: 15px;
    }
    .detail-hotel__rooms-table {
        margin-top: 22px;
        margin-bottom: 7px;
        .detail-hotel__th {
            padding-bottom: 15px;
        }
        tbody td {
            padding: 10px 5px;
        }
    }
}

.detail__info--services {
    padding-top: 15px;
    .detail__info-icons {
        padding: 0;
        min-height: 70px;
        padding-bottom: 15px;
        .icon {
            width: 100%;
            background-position-x: center;
        }
        .icon_wifi {
            margin-top: -10px;
        }
        p {
            margin-bottom: 0;
            text-align: center;
        }
        .service-wifi {
            padding-top: 13px;
            text-align: initial;
        }

    }
    .detail__info-servicesIcons {
        margin-bottom: 15px;
    }
    ul {
        margin-left: 19px;
    }
    .js-toogleInfoDisplay  {
        width: 100%;
    }
}

.container-img-hotel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
        "image-principal image-principal image-2"
        "image-principal image-principal image-3"
        "image-principal image-principal image-all";
    gap: 15px;
    /*height: calc(24rem + 2rem);*/
    height: calc(24rem + 9.1rem);
}

.container-img-hotel .image-principal, .container-img-hotel .image-2, .container-img-hotel .image-3 {
    cursor: pointer;
}

.container-img-hotel .image-principal { grid-area: image-principal; }
.container-img-hotel .image-principal img { object-fit: cover; }

.container-img-hotel .image-2 { grid-area: image-2; }

.container-img-hotel .image-3 { grid-area: image-3; }

.container-img-hotel .image-all { grid-area: image-all; display: grid; place-content: center; color: #fff;cursor: pointer;}

.container-img-hotel .image-all a {color:#000;z-index: 2;font-weight: bold;}

.detail__info--condition {
    border-bottom: 0;
    .detail-hotel__label {
        color: $color-gree-text;
        font-size: 15px;
        span {
            margin-left: 4rem;
        }
        .data-end {
            margin-left: 4.2rem;
        }
    }
}
.detail__info-total {
    /* margin-top: 12rem; */
    @include media-query(palm) {
        // position: sticky;
        padding: 15px !important;
        position: fixed;
        height: 34vw;
        bottom: 0%;
        right: -2px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease;
        margin:  30px 0 0%;
        tbody td {
            line-height: 2.125;
        }
        .info-total {
            h4 {
                margin-bottom: 0;
            }
            line-height: 2.125;
            .detail-hotel__price {
                color: black;
            }
        }
        .price-rooms {
            display: none;
        }
        span.detail-hotel__label {
            color: $color-main;
            @include attention() {
                cursor: pointer;
            }
        }
        .detail-shared__dispo-back {
            background-color: #eee;
            a {
                color: #444;
            }
        }
    }
}

.detail__info-total h4 {
    @include media-query(palm) {
        line-height: 1.3 !important;
    }
}

.active-total {
    opacity: 1;
    visibility: visible;
}
.total {
    transition: position 0.5s ease;
    position: inherit;
}
.detail__info-sm {
    background-repeat: no-repeat !important;
    height: 300px;
    width: 100%;
    background-size: cover !important;
}

.detail__hotel-dispo-back {
    position: sticky;
    top: 38px;
    margin-left: -5%;
    @include media-query (lap) {
        position: relative;
        left: 7%;
        margin-bottom: 68px;
    }
    @include media-query (palm) {
        top: 10px;
        z-index: 100;
        background-color: white;
        padding: 10px;
        left: 0;
        margin: -5px;
        box-shadow: 0 -0.1px 20px 0 #5a5d5a;
    }
    .btn-closeForm {
        padding-bottom: 6px;
        .icon_chevron_left:before {
            vertical-align: super;
        }
    }
}
.tooltips-service {
    width: 100%;
    left: 20%;
    margin-top: -10px;
}
@-webkit-keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
